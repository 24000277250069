import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const login = async (credentials: Credentials): Promise<Token> => {
  try {
    const { data } = await request.post("/auth/login", credentials);
    return data.token;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetchUser = async (credentials: Credentials): Promise<User> => {
  try {
    const { data } = await request.get("/auth/profile", { params: credentials });
    return {
      id: data.user.id,
      name: data.user.name,
      email: data.user.email,
      rememberMeToken: data.user.rememberMeToken,
      finishedAdmissionAccess: data.user.finishedAdmissionAccess,
      configurations: {
        webNotifications: false,
        appNotifications: false,
        emailNotifications: false,
      },
    };
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const validateToken = async (): Promise<boolean> => {
  try {
    const { data } = await request.get(`/auth/validate`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const forgotPassword = async (email: string): Promise<{ success?: boolean; message?: string }> => {
  return await request
    .get(`/auth/forgot-password/${email}`)
    .then((response) => {
      return { success: !!response.data.success };
    })
    .catch((error) => {
      if (error.response) {
        return { message: error.response.data.message };
      }
      return { message: "generic" };
    });
};

export const resetPassword = async (id: string, newPassword: string) => {
  return await request
    .put(`/auth/reset-password/${id}`, { newPassword })
    .then((response) => {
      return { success: response.data.success || false };
    })
    .catch((_) => {
      return { success: false };
    });
};
