import { ColumnConfig, G2, PieConfig, Plot } from "@ant-design/charts";
import {
  AuditOutlined,
  BarChartOutlined,
  BellOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  HourglassOutlined,
  PieChartOutlined,
  PlusOutlined,
  SendOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Column as ColumnChart, Pie as PieChart, measureTextWidth } from "@ant-design/plots";
import { PieOptions } from "@antv/g2plot";
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Menu,
  MenuProps,
  Modal,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LocalStorage } from "lowdb/browser";
import { DateTime } from "luxon";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import Notifier from "react-desktop-notification";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { addNotification, loadingNotification, removeNotification } from "src/actions/session.action";
import { AdmissionApi, EmployeeVacationApi, FinanceApi, ReminderApi, UserSendingConfigurations } from "src/api";
import { AmountDateStatus, AmountDateStatusChart, AmountMonth, ChartTable } from "src/api/auth/admission.api";
import CustomContentInfoMessage from "src/components/Modal/CustomContentInfoMessage";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import GoodInfoMessage from "src/components/Modal/GoodInfoMessage";
import InfoMessage from "src/components/Modal/InfoMessage";
import { Data, LowSyncWithLodash } from "src/components/NotificationsList";
import { REACT_APP_BASE_PATH } from "src/constants";
import { SocketContext } from "src/contexts";
import { useSessionContext } from "src/contexts/session.context";
import { EmployeeVacationRecord } from "src/pages/types/EmployeeVacationRecord.type";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import { light } from "src/styles/themes";
import { ExpiredSessionError, capitalizeFirstLetter, context, dictionary, i18n } from "src/utils";
import { v4 } from "uuid";
import FirstAccessFlowModal from "./FirstAccessFlowModal";

const { Text, Link } = Typography;
const { RangePicker } = DatePicker;

enum PendingAdmissionStatus {
  Waiting = 1,
  Conference,
  Completed,
}

enum DateStatus {
  UpToDate = 1,
  Late,
  Completed,
}

enum EmployeeVacationStatus {
  Upcoming = 1,
  Expired,
  Approved,
  ForApproval,
}

dayjs.extend(customParseFormat);

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const primaryColor = light.colors.brand.primary.light;

const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  console.info(e, "Alerta fechado.");
};

function renderStatistic(containerWidth: number, text: string, style: any) {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${0.8}em;line-height:${scale < 1 ? 1 : "inherit"};">${text}</div>`;
}

const areEqual = (prevProps: PieConfig, nextProps: PieConfig) => {
  return (
    prevProps.data.length == nextProps.data.length &&
    prevProps.data.every(function (element, index) {
      return element.value === nextProps.data[index].value;
    })
  );
};

const PieChartPureComponent = (config: PieConfig) => {
  return <PieChart {...config}></PieChart>;
};

const getInitialDateRange = (): any => {
  const endDate = dayjs();
  const startDate = dayjs().subtract(6, "months");
  return [startDate, endDate];
};

function Dashboard() {
  const [loadingPendingAdmission, setLoadingPendingAdmission] = useState(false);
  const [loadingReminder, setLoadingReminder] = useState(false);
  const [loadingEmployeeVacation, setLoadingEmployeeVacation] = useState(false);
  const [pendingAdmissionsCards, setPendingAdmissionsCards] = useState<JSX.Element[]>([]);
  const [reminderCards, setReminderCards] = useState<JSX.Element[]>([]);
  const [employeeVacationData, setEmployeeVacationData] = useState<EmployeeVacationRecord[]>([]);
  const [employeesVacationCards, setEmployeesVacationCards] = useState<JSX.Element[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userFinishedAdmissionAccess, setUserFinishedAdmissionAccess] = useState<boolean>(false);
  const [columnChartData, setColumnChartData] = useState<AmountMonth>();
  const [pieChartData, setPieChartData] = useState<AmountDateStatus>();
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [dateRecords, setDateRecords] = useState<string[]>(["2023-09-05"]);
  const [hasDateChanged, setHasDateChanged] = useState<boolean>(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [dateRange, setDateRange] = useState<any>(getInitialDateRange());
  const [financeData, setFinanceData] = useState({
    limitReached: false,
    admissionsLimit: 0,
    isCustomer: false,
    isAccountingCustomer: false,
  });
  const [loadingFinanceData, setLoadingFinanceData] = useState(false);
  const [certPass, setCertPass] = useState("");
  const socket = useContext(SocketContext);
  const { sessionState, sessionDispatch } = useSessionContext();
  const [userId, setUserId] = useState<number>(0);

  const adapter = new LocalStorage<Data>("qrpoint-notifications");
  const defaultData: Data = {
    notifications: [],
  };
  const db = new LowSyncWithLodash(adapter, defaultData);
  db.read();

  function sendESocialEvent(employeeId: number) {
    sessionDispatch(loadingNotification(true));
    const socketId = v4();
    socket.emit("send-esocial-admission-event-batch", {
      id: socketId,
      params: {
        certPass: certPass,
        employeeId: employeeId,
      },
    });

    socket.on(socketId, (data: any) => {
      const notificationCollection = db.data.notifications;
      let title = "Evento de admissão enviado para o eSocial com sucesso!";
      let content =
        "O recibo de processamento do evento estará disponível pelos próximos 20 " +
        "minutos. Clique aqui para fazer o download do recibo!";
      let notifier = "Verifique as suas notificações no sistema ou clique aqui para baixar.";
      let link = REACT_APP_BASE_PATH + decodeURIComponent(data.url);

      if (data.error) {
        title = "Não foi possível enviar o evento de admissão para o eSocial!";
        content = "Ocorreu um erro ao enviar o evento de admissão para o eSocial. Por favor, tente novamente.";
        notifier = "Verifique as suas notificações no sistema.";
        link = "";

        if (data.errorCode) {
          content = i18n(dictionary.error.start + data.errorCode + dictionary.error.message);
        }

        Notifier.focus(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      } else {
        Notifier.start(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      }

      notificationCollection.push({
        userId,
        id: socketId,
        expires: dayjs().add(20, "minutes").valueOf(),
        title,
        content,
        link,
      });
      db.data.notifications = notificationCollection;
      db.write();

      sessionDispatch(
        addNotification({
          userId,
          id: socketId,
          title,
          content,
          link,
        }),
      );

      sessionDispatch(loadingNotification(false));
      socket.removeListener(socketId);
      setTimeout(
        () => {
          sessionDispatch(removeNotification(socketId));
        },
        1000 * 60 * 19,
      ); //20 minutos para deletar a notificação
    });
  }

  function getInitialMonthRange(): any {
    const today = DateTime.now();
    const previousMonth = today.day < 15 ? 1 : 0;

    const adjustDate = (year: number, month: number) => {
      while (month <= 0) {
        month += 12;
        year -= 1;
      }
      return DateTime.fromObject({ year, month, day: today.day });
    };

    const initialStartDateRange = adjustDate(today.year, today.month - 6 - previousMonth + 1);
    const initialEndDateRange = adjustDate(today.year, today.month - previousMonth + 1);

    return [
      dayjs(
        initialStartDateRange.toFormat(i18n(dictionary.monthYearDateLuxonFormat)),
        i18n(dictionary.monthYearDateFormat),
      ),
      dayjs(
        initialEndDateRange.toFormat(i18n(dictionary.monthYearDateLuxonFormat)),
        i18n(dictionary.monthYearDateFormat),
      ),
    ];
  }

  const [monthRange, setMonthRange] = useState<any>(getInitialMonthRange());

  const onMonthRangeChange = async (value: RangePickerProps["value"], dateString: [string, string] | string) => {
    if (value && !!value[0] && !!value[1]) {
      const diffInMonths = value[1].diff(value[0], "month", true);

      if (diffInMonths > 6)
        return Modal.warning({
          title: i18n(dictionary.message.periodNotAllowed.title),
          content: i18n(dictionary.message.periodNotAllowed.sixMonths),
        });
    }
    setMonthRange(value);
    await getAmountMonth(value);
  };

  async function onMonthRangeOk(dateRange: RangePickerProps["value"]) {
    console.log("onOk: ", dateRange);

    if (dateRange) {
      if (dateRange[0] && dateRange[1] && dateRange[1].diff(dateRange[0], "days") > 30 * 6 + 10) {
        return Modal.warning({
          title: "Não é possível selecionar esse período",
          content: "A diferença de datas não pode ser maior que 6 meses.",
        });
      }
    }

    await getAmountMonth(dateRange);
  }

  const reminderMenuOptions = [
    {
      key: "SubMenu",
      icon: <EllipsisOutlined />,
      children: [
        {
          label: "Todos",
          key: "1",
        },
        {
          label: "Apenas atrasadas",
          key: "2",
        },
      ],
    },
  ];

  const [reminderFormRef] = Form.useForm();

  const navigate = useNavigate();

  const { boot, shutdown, hide, show, update } = useIntercom();

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const showReminderModal = () => {
    setIsReminderModalOpen(true);
  };

  const handleOkReminderModal = () => {
    setIsReminderModalOpen(false);
    reminderFormRef.submit();
  };

  const handleCancelReminderModal = () => {
    setIsReminderModalOpen(false);
    reminderFormRef.resetFields();
  };

  const handleEditReminder = (reminder: Reminder) => {
    reminderFormRef.setFieldsValue({
      ...reminder,
      dateTime: dayjs(reminder.dateTime),
    });
    setIsReminderModalOpen(true);
  };

  async function onFinish(reminderForm: Reminder) {
    if (reminderForm.id > 0) {
      onUpdateReminder(reminderForm.id, reminderForm);
    } else {
      onCreateReminder(reminderForm);
    }
  }

  const onStatusMonthRangeChange = async (value: RangePickerProps["value"], dateString: [string, string] | string) => {
    if (value && !!value[0] && !!value[1]) {
      const diffInMonths = value[1].diff(value[0], "month", true);

      if (diffInMonths > 6)
        return Modal.warning({
          title: i18n(dictionary.message.periodNotAllowed.title),
          content: i18n(dictionary.message.periodNotAllowed.sixMonths),
        });
    }

    setDateRange(value);
    await getAmountDateStatus(value);
  };

  async function onCreateReminder(reminderForm: Reminder) {
    ReminderApi.create(reminderForm)
      .then((res) => {
        setReminderCards([
          ...reminderCards,
          <Card style={{ marginBottom: 8 }}>
            <Row>
              <Col span={12}>
                <Text style={{ color: primaryColor, fontWeight: "bold" }}>
                  {dayjs(reminderForm.dateTime).format(i18n(dictionary.dateFormat)) + " - "}
                </Text>
                <Text>{reminderForm.message}</Text>
              </Col>
              <Col span={7} />
              <Col span={2}>
                <CheckOutlined
                  style={{ fontSize: 16, color: primaryColor }}
                  onClick={() => {
                    reminderForm.check = true;
                    onUpdateReminder(res.id, reminderForm);
                  }}
                />
              </Col>
              <Col span={2}>
                <EditOutlined
                  style={{ fontSize: 16, color: primaryColor }}
                  onClick={() => handleEditReminder(reminderForm)}
                />
              </Col>
              <Col span={1}>
                <DeleteOutlined
                  style={{ fontSize: 16, color: primaryColor }}
                  onClick={() => onDeleteReminder(res.id)}
                />
              </Col>
            </Row>
          </Card>,
        ]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_REMINDER_ERROR.title),
            message: i18n(dictionary.error.CREATE_REMINDER_ERROR.message),
            onOk: () => onCreateReminder(reminderForm),
            onClickLink: () => show(),
          });
        }
        console.error(error);
      });
  }

  async function onDeleteReminder(id: number) {
    ReminderApi.destroy(id)
      .then((res) => {
        getReminderData();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.DELETE_REMINDER_ERROR.title),
            message: i18n(dictionary.error.DELETE_REMINDER_ERROR.message),
            onOk: () => onDeleteReminder(id),
            onClickLink: () => show(),
          });
        }
        console.error(error);
      });
  }

  async function onUpdateReminder(id: number, reminder: Reminder) {
    ReminderApi.updateById(id, reminder)
      .then((res) => {
        getReminderData();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_REMINDER_ERROR.title),
            message: i18n(dictionary.error.UPDATE_REMINDER_ERROR.message),
            onOk: () => onUpdateReminder(id, reminder),
            onClickLink: () => show(),
          });
        }
        console.error(error);
      });
  }

  const tableColumns: ColumnsType<ChartTable> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          style={{ color: primaryColor }}
          onClick={() => navigate(Routes.EMPLOYEE_EDIT + `?id=${record.id}`)}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{getPendingAdmissionStatusText(status)}</>,
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date) => <div>{DateTime.fromISO(date?.toString()).toFormat("dd/MM/yyyy")}</div>,
    },
  ];

  const dateStatusTableColumns: ColumnsType<ChartTable> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link
          style={{ color: primaryColor }}
          onClick={() => navigate(Routes.EMPLOYEE_EDIT + `?id=${record.id}`)}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <>{getDateStatusText(status)}</>,
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (date) => <div>{DateTime.fromISO(date?.toString()).toFormat("dd/MM/yyyy")}</div>,
    },
  ];

  const columnConfig: ColumnConfig = {
    height: 200,
    data: columnChartData ? columnChartData.chartData : [],
    xField: "month",
    yField: "admissions",
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      month: {
        alias: "Mês",
      },
      admissions: {
        alias: "Admissões",
      },
    },
    columnStyle: {
      fill: primaryColor,
    },
  };

  const pieChartConfig: PieConfig = {
    height: 200,
    appendPadding: 5,
    // data: pieChartData
    //   ? pieChartData.chartData.map((item) => {
    //       return { ...item, dateStatus: item.dateStatus.split(" ")[0] };
    //     })
    //   : [],
    data: pieChartData ? pieChartData.chartData : [],
    angleField: "admissions",
    colorField: "dateStatus",
    // color: props.backgroundColor,
    radius: 1,
    innerRadius: 0.5,
    meta: {
      admissions: {
        formatter: (v) => {
          if (v === 0) {
            return "";
          }
          const total = pieChartData?.chartData.reduce((r: any, d: AmountDateStatusChart) => r + d.admissions, 0);
          return Number(((parseInt(v) * 100) / (total === 0 ? 1 : total)).toFixed(0))
            .toString()
            .concat("%");
        },
      },
      dateStatus: {
        formatter: (v) => {
          if (isBrowser) return v;
          return capitalizeFirstLetter(v.toString().replace(v.toString().split(" ")[0], "").trim());
        },
      },
    },
    label: {
      type: "inner",
      offset: 0,
      autoRotate: false,
      offsetY: 0,
    },
    statistic: {
      title: {
        style: {
          width: "100%",
        },
        offsetY: -4,
        customHtml: (container: any, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : "Total";
          return renderStatistic(d, "Percentual", {
            fontSize: 8,
            fontFamily: "Helvetica",
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: "16px",
        },
        customHtml: (container: any, view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? `${datum.admissions}` : `${data.reduce((r: any, d: any) => r + d.admissions, 0)}`;
          return renderStatistic(width, "", {
            fontSize: 8,
          });
        },
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
      {
        type: "pie-statistic-active",
      },
      {
        type: "tooltip",
      },
    ],
    legend: {
      layout: "horizontal",
      position: "right",
      flipPage: false,
      offsetX: isBrowser ? 1770 - windowDimensions.width : -10,
      offsetY: !isBrowser ? -16 : 0,
      marker: (text, index, item) => {
        return {
          style: {
            r: 6,
          },
        };
      },
      // itemValue: {
      //   formatter: (text: string, item: any, index: number) => {
      //     return text;
      //   },
      // },
      // selected: props.selectedLegend,
    },
    onEvent: (chart: Plot<PieOptions>, event: G2.Event) => {
      switch (event.type) {
        //evento chamado ao clicar no gráfico
        case "plot:click":
          break;
        //evento chamado ao clicar na legenda
        case "legend:click":
          break;
        //evento chamado antes de renderizar o gráfico
        case "beforrender":
          break;
      }
    },
  };

  function getPendingAdmissionStatusText(status?: PendingAdmissionStatus): string {
    switch (status) {
      case PendingAdmissionStatus.Waiting:
        return i18n(dictionary.pending_admission.waiting);
      case PendingAdmissionStatus.Conference:
        return i18n(dictionary.pending_admission.conference);
      case PendingAdmissionStatus.Completed:
        return capitalizeFirstLetter(i18n(dictionary.admission.dateStatus.completed));
      default:
        return "";
    }
  }

  function getPendingAdmissionStatusIcon(status?: PendingAdmissionStatus): ReactNode {
    switch (status) {
      case PendingAdmissionStatus.Conference:
        return <HourglassOutlined />;
      default:
        return <SendOutlined />;
    }
  }

  function getDateStatusText(status?: DateStatus): string {
    switch (status) {
      case DateStatus.UpToDate:
        return capitalizeFirstLetter(i18n(dictionary.admission.dateStatus.upToDate));
      case DateStatus.Late:
        return capitalizeFirstLetter(i18n(dictionary.admission.dateStatus.late));
      case DateStatus.Completed:
        return capitalizeFirstLetter(i18n(dictionary.admission.dateStatus.completed));
    }
    return capitalizeFirstLetter(i18n(dictionary.unknown));
  }

  function getPendingAdmissionStatusTagColor(status?: PendingAdmissionStatus): string {
    switch (status) {
      case PendingAdmissionStatus.Waiting:
        return "purple";
      case PendingAdmissionStatus.Conference:
        return "orange";
      default:
        return "black";
    }
  }

  function getPendingAdmissionStatusFinishAction(status?: PendingAdmissionStatus): boolean {
    switch (status) {
      case PendingAdmissionStatus.Conference:
        return true;
      default:
        return false;
    }
  }

  async function finishAdmission(employeeId: number, sentToEsocial: boolean) {
    AdmissionApi.finish(employeeId)
      .then((result) => {
        if (!result.success) {
          let specificMessage = " ";

          if (result.requiredDocumentErrors.length > 0) {
            i18n(dictionary.info.FINISH_ADMISSION_INFO.specificMessage);
            for (const documentError of result.requiredDocumentErrors) {
              specificMessage += i18n(dictionary.info.FINISH_ADMISSION_INFO.requiredDocument[documentError]) + ", ";
            }

            specificMessage = specificMessage.slice(0, -2) + ".";
          }

          return GoodInfoMessage({
            title: i18n(dictionary.info.FINISH_ADMISSION_INFO.title),
            message: i18n(dictionary.info.FINISH_ADMISSION_INFO.message) + specificMessage,            
          });
        }

        if (sentToEsocial) {
          sendESocialEvent(employeeId);
          GoodInfoMessage({
            title: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.title),
            message: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.message),
          });
        } else {
          Modal.success({
            title: i18n(dictionary.success.FINISH_ADMISSION_SUCCESS.title),
          });
        }

        getPendingAdmissionsData();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.FINISH_ADMISSION_ERROR.title),
            message: i18n(dictionary.error.FINISH_ADMISSION_ERROR.message),
            onOk: () => finishAdmission(employeeId, sentToEsocial),
            onClickLink: () => show(),
          });
        }
        console.error(error);
      });
  }

  async function getPendingAdmissionsData() {
    if (loadingPendingAdmission) {
      return;
    }
    setLoadingPendingAdmission(true);
    return await AdmissionApi.fetch()
      .then((res) => {
        const pendingAdmissionsCards: JSX.Element[] = [];
        res.forEach((employee) => {
          pendingAdmissionsCards.push(
            <Card style={{ marginBottom: 8 }}>
              <Row>
                <Space
                  style={{
                    width: "95%",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>{employee.name}</Text>
                </Space>
                <Space
                  style={{
                    width: "5%",
                    justifyContent: "end",
                    textAlign: "end",
                  }}
                >
                  <EditOutlined
                    style={{ fontSize: 20, color: primaryColor }}
                    onClick={() => navigate(Routes.EMPLOYEE_EDIT + "?id=" + employee.id)}
                  />
                </Space>
              </Row>
              <Row>
                <Text>{employee.jobOccupation}</Text>
              </Row>
              <Row>
                <Col span={20}>
                  <Tag
                    style={{ borderRadius: 25 }}
                    color={getPendingAdmissionStatusTagColor(employee.additionalData.admissionStatus)}
                  >
                    {getPendingAdmissionStatusText(employee.additionalData.admissionStatus)}
                  </Tag>
                  <Tag style={{ borderRadius: 25 }}>
                    {getPendingAdmissionStatusIcon(employee.additionalData.admissionStatus)}
                    <Text style={{ marginLeft: 5, fontSize: 12 }}>
                      {employee.additionalData.updatedAt
                        ? DateTime.fromISO(employee.additionalData.updatedAt.toString()).toFormat("dd/MM/yyyy")
                        : ""}
                    </Text>{" "}
                  </Tag>
                </Col>
                {getPendingAdmissionStatusFinishAction(employee.additionalData.admissionStatus) && (
                  <Button
                    size="small"
                    style={{ borderRadius: 25 }}
                    onClick={() => {
                      if (employee.additionalData.hasEsocial) {
                        //mensagem confirmando envio para o esocial
                        InfoMessage({
                          title: i18n(dictionary.confirm.FINISH_ADMISSION_CONFIRM.title),
                          message: i18n(dictionary.confirm.FINISH_ADMISSION_CONFIRM.message),
                          confirm: () => {
                            if (employee.employer.digitalCertificateName) {
                              //mensagem solicitando senha do certificado digital
                              CustomContentInfoMessage({
                                title: i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.title),
                                content: (
                                  <>
                                    <>{i18n(dictionary.info.E_SOCIAL_ALMOST_THERE.message)}</>
                                    <Form
                                      name="basic"
                                      labelCol={{ span: 8 }}
                                      wrapperCol={{ span: 16 }}
                                      style={{ maxWidth: 600 }}
                                      autoComplete="off"
                                    >
                                      <Form.Item
                                        name="password"
                                        rules={[
                                          { required: true, message: "Por favor informe a senha do certificado!" },
                                        ]}
                                      >
                                        <Input.Password
                                          placeholder="Digite aqui"
                                          onChange={(event) => {
                                            setCertPass(event.target.value);
                                          }}
                                        />
                                      </Form.Item>
                                    </Form>
                                  </>
                                ),
                                confirm: () => {
                                  finishAdmission(employee.id, employee.additionalData.hasEsocial ?? false);
                                },
                                okText: i18n(dictionary.action.conclude),
                                cancelText: i18n(dictionary.action.cancel),
                              });
                            } else {
                              //mensagem indicando que o certificado digital deve ser informado na tela de empregador
                              InfoMessage({
                                title: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.title),
                                message: i18n(dictionary.error.E_SOCIAL_INTEGRATION_ERROR.message),
                                confirm: () => {
                                  navigate(Routes.EMPLOYER);
                                },
                              });
                            }
                          },
                        });
                      } else {
                        finishAdmission(employee.id, employee.additionalData.hasEsocial ?? false);
                      }
                    }}
                  >
                    Concluir
                  </Button>
                )}
              </Row>
            </Card>,
          );
        });
        setPendingAdmissionsCards(pendingAdmissionsCards);
        setLoadingPendingAdmission(false);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_PENDING_ADMISSION_ERROR.title),
            message: i18n(dictionary.error.GET_PENDING_ADMISSION_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        setLoadingPendingAdmission(false);
        throw error;
      });
  }

  // getDaysWithRecord(date) {
  //   let headers = {
  //     idColaborador: this.state.idColaborador,
  //     data: date,
  //   };
  //   axios({
  //     url: "/controle-de-registros-de-ponto/getDateRecords",
  //     headers: headers,
  //   }).then((res: any) => {
  //     setDateRecords(res.data),

  //   });
  //   setHasDateChanged(false);
  // }

  async function verifyPersonalizations() {
    try {
      const response = await UserSendingConfigurations.fetch();
      if (response.length == 0) {
        const personalization: SendingPersonalization = {
          title: i18n(dictionary.components.sendings.formItems.defaultTitle),
        };
        await UserSendingConfigurations.create(personalization);
      }
    } catch (error) {
      throw error;
    }
  }

  async function getReminderData(onlyLate: boolean = false, date: string | string[] = "") {
    if (loadingReminder) {
      return;
    }
    setLoadingReminder(true);
    return await ReminderApi.fetch()
      .then((res) => {
        const reminderCards: JSX.Element[] = [];

        res = res
          .filter((reminder) => {
            return (
              ((DateTime.fromISO(reminder.dateTime.toString()).startOf("day") <
                DateTime.fromJSDate(new Date()).startOf("day") &&
                onlyLate) ||
                !onlyLate) &&
              (DateTime.fromISO(reminder.dateTime.toString()).toLocaleString() == date || date == "") &&
              ((reminder.check &&
                DateTime.fromISO(reminder.dateTime.toString()).toLocaleString() == DateTime.now().toLocaleString()) ||
                !reminder.check)
            );
          })
          .sort((n1, n2) => {
            if (n1.check && !n2.check) {
              return 1;
            }
            if (!n1.check && n2.check) {
              return -1;
            }
            return 0;
          });

        res.forEach((reminder) => {
          reminderCards.push(
            <Card style={{ marginBottom: 8 }}>
              <Row>
                <Col span={12}>
                  <Text style={{ color: primaryColor, fontWeight: "bold" }}>
                    {DateTime.fromISO(reminder.dateTime.toString()).toFormat("dd/MM/yyyy") + " - "}
                  </Text>
                  <Text>{reminder.message}</Text>
                </Col>
                <Col span={7}>
                  {reminder.check ? (
                    <Tag
                      style={{ borderRadius: 25 }}
                      color="green"
                    >
                      Concluído
                    </Tag>
                  ) : (
                    <>
                      {DateTime.fromISO(reminder.dateTime.toString()) < DateTime.fromJSDate(new Date()) && (
                        <Tag
                          style={{ borderRadius: 25 }}
                          color="red"
                        >
                          Atrasado
                        </Tag>
                      )}
                    </>
                  )}
                </Col>
                <Col span={2}>
                  <CheckOutlined
                    style={{ fontSize: 16, color: primaryColor }}
                    onClick={() => {
                      reminder.check = true;
                      onUpdateReminder(reminder.id, reminder);
                    }}
                  />
                </Col>
                <Col span={2}>
                  <EditOutlined
                    style={{ fontSize: 16, color: primaryColor }}
                    onClick={() => handleEditReminder(reminder)}
                  />
                </Col>
                <Col span={1}>
                  <DeleteOutlined
                    style={{ fontSize: 16, color: primaryColor }}
                    onClick={() => onDeleteReminder(reminder.id)}
                  />
                </Col>
              </Row>
            </Card>,
          );
        });
        setReminderCards(reminderCards);
        setLoadingReminder(false);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_REMINDER_ERROR.title),
            message: i18n(dictionary.error.GET_REMINDER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        setLoadingReminder(false);
        throw error;
      });
  }

  function getEmployeesVacationStatusText(status: EmployeeVacationStatus): string {
    switch (status) {
      case EmployeeVacationStatus.Upcoming:
        return i18n(dictionary.employee_vacation.upcoming);
      case EmployeeVacationStatus.Expired:
        return i18n(dictionary.employee_vacation.expired);
      case EmployeeVacationStatus.Approved:
        return i18n(dictionary.employee_vacation.approved);
      case EmployeeVacationStatus.ForApproval:
        return i18n(dictionary.employee_vacation.for_approval);
    }
  }

  function getEmployeesVacationStatusTagColor(status: EmployeeVacationStatus): string {
    switch (status) {
      case EmployeeVacationStatus.Upcoming:
        return "purple";
      case EmployeeVacationStatus.Expired:
        return "red";
      case EmployeeVacationStatus.Approved:
        return "green";
      case EmployeeVacationStatus.ForApproval:
        return "yellow";
      default:
        return "black";
    }
  }

  async function getEmployeesVacationData() {
    if (loadingEmployeeVacation) {
      return;
    }
    setLoadingEmployeeVacation(true);
    return await EmployeeVacationApi.fetch()
      .then((res) => {
        const employeesVacationCards: JSX.Element[] = [];

        res.forEach((employeeVacation) => {
          employeesVacationCards.push(
            <Card style={{ marginBottom: 8 }}>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col>
                  <Text style={{ fontWeight: "bold" }}>{employeeVacation.name}</Text>
                </Col>
                <Col>
                  <EditOutlined
                    style={{ fontSize: 16, color: primaryColor }}
                    onClick={() => console.log("Editar férias")}
                  />
                </Col>
              </Row>
              <Row>
                <Tag
                  style={{ borderRadius: 25 }}
                  color={getEmployeesVacationStatusTagColor(employeeVacation.status)}
                >
                  {getEmployeesVacationStatusText(employeeVacation.status)}
                </Tag>{" "}
                <Text>
                  {dayjs(employeeVacation.startDate, "DD/MM/YYYY").format(i18n(dictionary.dateFormat)) +
                    " - " +
                    employeeVacation.endDate}
                </Text>
              </Row>
            </Card>,
          );
        });

        // const employeesVacationCards: JSX.Element[] = [
        //   <Card style={{ marginBottom: 8 }}>
        //     <Row style={{ display: "flex", justifyContent: "space-between" }}>
        //       <Col>
        //         <Text style={{ fontWeight: "bold" }}>John Doe</Text>
        //       </Col>
        //       <Col>
        //         <EditOutlined
        //           style={{ fontSize: 16, color: primaryColor }}
        //           onClick={() => console.log("Editar férias")}
        //         />
        //       </Col>
        //     </Row>
        //     <Row>
        //       <Tag
        //         style={{ borderRadius: 25 }}
        //         color="orange"
        //       >
        //         Férias para aprovação
        //       </Tag>{" "}
        //       <Text>10/10/2023 - A definir</Text>
        //     </Row>
        //   </Card>,
        // ];

        setEmployeesVacationCards(employeesVacationCards);
        setLoadingEmployeeVacation(false);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_VACATION_ERROR.title),
            message: i18n(dictionary.error.GET_VACATION_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        setLoadingEmployeeVacation(false);
        throw error;
      });
  }

  async function getAmountMonth(monthRange?: any): Promise<boolean> {
    const initialMonthRange = getInitialMonthRange();
    return await AdmissionApi.calculateAmountMonth({
      startDate: monthRange ? monthRange[0] : initialMonthRange[0],
      endDate: monthRange ? monthRange[1] : initialMonthRange[1],
    })
      .then((res: AmountMonth) => {
        setColumnChartData(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.AMOUNT_MONTH_ADMISSION_ERROR.title),
            message: i18n(dictionary.error.AMOUNT_MONTH_ADMISSION_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              if (monthRange) {
                getAmountMonth(monthRange);
              } else {
                dataFetchedRef.current = false;
                getInitData();
              }
            },
          });
        }
        throw error;
      });
  }

  async function getAmountDateStatus(dateRange?: any): Promise<boolean> {
    const initialDateRange = getInitialDateRange();

    return await AdmissionApi.calculateAmountDateStatus({
      startDate: dateRange ? dateRange[0] : initialDateRange[0],
      endDate: dateRange ? dateRange[1] : initialDateRange[1],
    })
      .then((res: AmountDateStatus) => {
        setPieChartData({ ...pieChartData, ...res });
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.AMOUNT_DATE_STATUS_ADMISSION_ERROR.title),
            message: i18n(dictionary.error.AMOUNT_DATE_STATUS_ADMISSION_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getPendingAdmissionsData();
      await getReminderData();
      await getEmployeesVacationData();
      if (!userFinishedAdmissionAccess) {
        await getAmountMonth();
        await getAmountDateStatus();
        await verifyPersonalizations();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getFinanceData() {
    setLoadingFinanceData(true);
    const response = await FinanceApi.getAdmissionsLimit();
    setFinanceData(response);
    setLoadingFinanceData(false);
  }

  useEffect(() => {
    getInitData();
    getFinanceData();
    setUserName(Storage.getUserName());
    setUserEmail(Storage.getUserEmail());
    setUserFinishedAdmissionAccess(Storage.getUserFinishedAdmissionAccess());
    update({ customAttributes: { first_access_flow_modal: !Storage.getUserFinishedAdmissionAccess() } });
  }, []);

  const onClickReminderMenu: MenuProps["onClick"] = (e) => {
    const keyMenuItem = e.key;

    switch (keyMenuItem) {
      case "1":
        return getReminderData();
      case "2":
        return getReminderData(true);
    }
  };

  return (
    <>
      {!financeData.isCustomer && !financeData.isAccountingCustomer && !loadingFinanceData && (
        <Alert
          message={
            <>
              <Text>
                {financeData.limitReached ? (
                  <>
                    Você já utilizou as {financeData.admissionsLimit} admissões gratuitas do período de testes, para
                    contratar um plano e ter acesso a mais admissões,
                  </>
                ) : (
                  <>
                    Olá! {userName}, seja bem-vindo(a)! Você possui {financeData.admissionsLimit} admissões gratuitas no
                    seu período de testes. Caso queira contratar um plano com mais admissões,
                  </>
                )}
              </Text>
              &nbsp;
              <Link
                /*href="https://qrpoint.io/"*/ onClick={() => navigate(Routes.FINANCE, { replace: true })}
                style={{ color: light.colors.brand.primary.light }}
              >
                clique aqui
              </Link>
              <Text>.</Text>
            </>
          }
          type={financeData.limitReached ? "error" : "success"}
          closable
          onClose={onClose}
          style={{ marginBottom: 8 }}
        />
      )}
      <Row
        gutter={16}
        style={{ rowGap: 8 }}
      >
        <Col
          className="custom-ant-col"
          span={8}
        >
          <Card
            title={
              <>
                <AuditOutlined style={{ fontSize: "24px", color: primaryColor }} />{" "}
                <Text style={{ color: primaryColor }}>Admissões pendentes</Text>
              </>
            }
            actions={[
              <Text onClick={() => navigate(Routes.EMPLOYEE_CREATE)}>
                {i18n(dictionary.label.new_item, {
                  context: context.female,
                  item: i18n(dictionary.label.admission).toLowerCase(),
                })}{" "}
                <PlusOutlined style={{ fontSize: 18, color: "#520556" }} />
              </Text>,
            ]}
            extra={<EllipsisOutlined />}
          >
            {loadingPendingAdmission ? (
              <Skeleton />
            ) : (
              <div
                style={{
                  height: 200,
                  overflow: "auto",
                  padding: "0",
                }}
              >
                {pendingAdmissionsCards}
              </div>
            )}
          </Card>
        </Col>
        <Col
          className="custom-ant-col"
          span={8}
        >
          <Card
            title={
              <>
                <BellOutlined style={{ fontSize: "24px", color: light.colors.brand.primary.light }} />{" "}
                <Text style={{ color: primaryColor }}>Lembretes</Text>
              </>
            }
            actions={[
              <Text
                style={{ color: primaryColor, textDecoration: "underline" }}
                onClick={() => showReminderModal()}
              >
                {i18n(dictionary.label.new_item, {
                  context: context.male,
                  item: i18n(dictionary.reminder).toLowerCase(),
                })}{" "}
                <PlusOutlined style={{ fontSize: 18, color: "#520556" }} />
              </Text>,
            ]}
            extra={
              <Space direction="horizontal">
                <DatePicker
                  format={[i18n(dictionary.dateFormat)]}
                  onChange={(_, dateString) => {
                    getReminderData(false, dateString);
                  }}
                  dateRender={(current) => {
                    const style: any = {};
                    if (dateRecords.indexOf(current.format("YYYY-MM-DD")) !== -1) {
                      style.border = "1px solid #1890ff";
                      style.borderRadius = "50%";
                    }
                    return (
                      <div
                        className="ant-picker-cell-inner"
                        style={style}
                      >
                        {current.date()}
                      </div>
                    );
                  }}
                  // onOpenChange={(status) => {
                  //   if (status && hasDateChanged) {
                  //     this.getDaysWithRecord(null);
                  //   }
                  // }}
                />
                <Menu
                  onClick={onClickReminderMenu}
                  items={reminderMenuOptions}
                />
              </Space>
            }
          >
            {loadingReminder ? (
              <Skeleton></Skeleton>
            ) : (
              <div
                style={{
                  height: 200,
                  overflow: "auto",
                  padding: "0",
                }}
              >
                {reminderCards}
              </div>
            )}
          </Card>
        </Col>
        <Col
          className="custom-ant-col"
          span={8}
        >
          <Card
            title={
              <>
                <TeamOutlined style={{ fontSize: "24px", color: light.colors.brand.primary.light }} />{" "}
                <Text style={{ color: primaryColor }}>Férias dos colaboradores</Text>
              </>
            }
            // actions={[
            //   <Text>
            //     {i18n(dictionary.see_all)} <DownOutlined />
            //   </Text>,
            // ]}
            extra={<EllipsisOutlined />}
          >
            {loadingEmployeeVacation ? (
              <Skeleton></Skeleton>
            ) : (
              <div
                style={{
                  height: 246,
                  overflow: "auto",
                  padding: "0",
                }}
              >
                {employeesVacationCards}
              </div>
            )}
          </Card>
        </Col>
        <Col
          className="custom-ant-col"
          span={12}
        >
          <Card
            title={
              <>
                <BarChartOutlined style={{ fontSize: "24px", color: primaryColor }} />{" "}
                <Text style={{ color: primaryColor }}>Relatório de evolução mensal</Text>
              </>
            }
            // actions={[
            //   <Text>
            //     {i18n(dictionary.see_details)} <DownOutlined />
            //   </Text>,
            // ]}
            extra={
              <RangePicker
                picker="month"
                format={[i18n(dictionary.monthYearDateFormat)]}
                defaultValue={getInitialMonthRange()}
                onChange={onMonthRangeChange}
                style={{ width: 200 }}
                value={monthRange}
              />
            }
          >
            {loadingPendingAdmission || loadingReminder || loadingEmployeeVacation ? (
              <Skeleton></Skeleton>
            ) : (
              <Space className="custom-ant-space">
                <ColumnChart {...columnConfig} />
                <div
                  style={{
                    height: 200,
                    overflow: "auto",
                    padding: "0",
                  }}
                >
                  <Table
                    scroll={{ x: true }}
                    columns={tableColumns}
                    dataSource={columnChartData ? columnChartData.tableData : []}
                    // pagination={{ position: ["bottomCenter"] }}
                    pagination={false}
                  />
                </div>
              </Space>
            )}
          </Card>
        </Col>
        <Col
          className="custom-ant-col"
          span={12}
        >
          <Card
            title={
              <>
                <PieChartOutlined style={{ fontSize: "24px", color: primaryColor }} />{" "}
                <Text style={{ color: primaryColor }}>Indicadores por período</Text>
              </>
            }
            // actions={[
            //   <Text>
            //     {i18n(dictionary.see_details)} <DownOutlined />
            //   </Text>,
            // ]}
            extra={
              <RangePicker
                format={[i18n(dictionary.dateFormat)]}
                defaultValue={getInitialDateRange()}
                onChange={onStatusMonthRangeChange}
                style={{ width: 230 }}
                value={dateRange}
              />
            }
          >
            {loadingPendingAdmission || loadingReminder || loadingEmployeeVacation ? (
              <Skeleton></Skeleton>
            ) : (
              <Space className="custom-ant-space">
                <PieChartPureComponent {...pieChartConfig} />
                <div
                  style={{
                    height: 200,
                    overflow: "auto",
                    padding: "0",
                  }}
                >
                  <Table
                    scroll={{ x: true }}
                    columns={dateStatusTableColumns}
                    dataSource={pieChartData ? pieChartData.tableData : []}
                    // pagination={{ position: ["bottomCenter"] }}
                    pagination={false}
                  />
                </div>
              </Space>
            )}
          </Card>
        </Col>
      </Row>
      {!userFinishedAdmissionAccess && (
        <FirstAccessFlowModal
          userEmail={userEmail}
          updateDashboard={() => {
            dataFetchedRef.current = false;
            getInitData();
            getAmountDateStatus();
          }}
          userFinishedAdmissionAccess={userFinishedAdmissionAccess}
        />
      )}
      <Modal
        title="Lembrete"
        open={isReminderModalOpen}
        onOk={handleOkReminderModal}
        onCancel={handleCancelReminderModal}
      >
        <Form
          form={reminderFormRef}
          onFinish={onFinish}
        >
          <Form.Item<Reminder> name="message">
            <Input />
          </Form.Item>
          <Form.Item<Reminder> name="dateTime">
            <DatePicker format={i18n(dictionary.dateFormat)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default Dashboard;
