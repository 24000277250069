import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { ReactNode } from "react";

interface CustomContentInfoMessageProps {
  title: string;
  content: ReactNode;
  confirm: (values: any) => void;
  okText: string;
  cancelText: string;
}

export default function CustomContentInfoMessage(props: CustomContentInfoMessageProps) {
  return Modal.confirm({
    title: props.title,
    icon: <ExclamationCircleOutlined />,
    content: props.content,
    okText: props.okText,
    okType: "danger",
    cancelText: props.cancelText,
    onOk: props.confirm,
  });
}
