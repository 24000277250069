import { DeleteOutlined, EditOutlined, FormOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Modal, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { EmployeeNoteApi } from "src/api";
import { Card, Table as EmployeeTable, FormItem, InputGroup } from "src/components";
import { Storage } from "src/services";
import { dictionary, i18n } from "src/utils";

const { Column } = EmployeeTable;

type Props = {
  userNotes: EmployeeNote[];
  showNotesModal: boolean;
  setShowNotesModal: React.Dispatch<React.SetStateAction<boolean>>;
  insertNote(note: EmployeeNote): void;
  deleteNote(noteId: number): void;
  updateNote(note: EmployeeNote): void;
};

function TabNotes({
  userNotes,
  showNotesModal,
  setShowNotesModal,
  insertNote,
  deleteNote,
  updateNote,
}: Props): JSX.Element {
  const [form] = useForm();
  const [noteId, setNoteId] = useState<number | null>(null);
  const [params, setParams] = useSearchParams();

  function handleEdit(note: EmployeeNote) {
    setNoteId(note.id);
    form.setFieldsValue({
      noteDate: moment(note.dateTime),
      noteAuthor: Storage.getUserName(),
      noteDescription: note.description,
    });
    setShowNotesModal(true);
  }

  function handleDelete(id: number) {
    return Modal.warning({
      title: "Deseja deletar esta anotação?",
      content: "Esta ação não poderá ser desfeita.",
      okCancel: true,
      cancelText: "Não",
      okText: "Sim",
      onOk: () => deleteNote(id),
      okType: "danger",
    });
  }

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        const noteObject = {
          id: noteId || 0,
          dateTime: new Date(values.noteDate),
          description: values.noteDescription,
          user: {
            id: 0,
            name: "",
            email: "",
            rememberMeToken: false,
            finishedAdmissionAccess: false,
            configurations: {
              webNotifications: false,
              appNotifications: false,
              emailNotifications: false,
            },
          },
          employeeId: parseInt(params.get("id") ?? "0"),
        };
        if (noteId) {
          updateNote(noteObject);
          EmployeeNoteApi.updateById(noteId, noteObject);
        } else {
          insertNote(noteObject);
          EmployeeNoteApi.create(noteObject);
        }
        setShowNotesModal(false);
        form.resetFields();
        setNoteId(null);
      })
      .catch((exception) => console.info(exception));
  }

  function handleCancel() {
    setNoteId(null);
    form.resetFields();
    setShowNotesModal(false);
  }

  function NoteModal(): JSX.Element {
    return (
      <Modal
        title="Anotação"
        visible={showNotesModal}
        cancelText="Cancelar"
        okText={noteId ? "Atualizar anotação" : "Registrar anotação"}
        onCancel={() => handleCancel()}
        onOk={() => handleSubmit()}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <FormItem>
            <InputGroup>
              <FormItem
                initialValue={moment()}
                label="Data / Hora"
                name="noteDate"
                rules={[{ required: false }]}
              >
                <DatePicker
                  disabled
                  showTime
                  format={i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat)}
                />
              </FormItem>
              <FormItem
                initialValue={Storage.getUserName()}
                label="Responsável"
                name="noteAuthor"
                rules={[{ required: false }]}
              >
                <Input disabled />
              </FormItem>
            </InputGroup>
          </FormItem>
          <FormItem
            label="Anotação"
            name="noteDescription"
            rules={[{ required: true, message: i18n(dictionary.message.requiredField) }, { max: 255 }]}
            hasFeedback
            validateFirst
          >
            <Input.TextArea />
          </FormItem>
        </Form>
      </Modal>
    );
  }

  function FormActions(): JSX.Element {
    return (
      <Space direction="horizontal">
        <Button
          onClick={() => setShowNotesModal(true)}
          type="primary"
          icon={<PlusOutlined />}
        >
          Nova anotação
        </Button>
        <Button
          shape="circle"
          type="text"
          icon={<ReloadOutlined />}
        />
      </Space>
    );
  }

  return (
    <>
      <NoteModal />
      <Card
        icon={<FormOutlined />}
        title="Lista de anotações"
        actions={<FormActions />}
      >
        <EmployeeTable
          tableLayout="auto"
          dataSource={userNotes}
        >
          <Column
            title="Data / Hora"
            dataIndex="dateTime"
            key="dateTime"
            render={(value: Date) =>
              moment(value).format(i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat))
            }
            sorter={(a: EmployeeNote, b: EmployeeNote) => a.dateTime.getTime() - b.dateTime.getTime()}
          />
          <Column
            title="Anotação"
            dataIndex="description"
            key="description"
            width="60%"
          />
          <Column
            title="Responsável"
            dataIndex="author"
            key="author"
            sorter={(a: EmployeeNote, b: EmployeeNote) => a.user.name.localeCompare(b.user.name)}
          />
          <Column
            title="Ações"
            dataIndex="actions"
            key="actions"
            render={(_, record: EmployeeNote) => (
              <Space direction="horizontal">
                <Button
                  size="small"
                  type="default"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                >
                  Editar
                </Button>
                <Button
                  size="small"
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(record.id)}
                >
                  Deletar
                </Button>
              </Space>
            )}
          />
        </EmployeeTable>
      </Card>
    </>
  );
}

export default TabNotes;
