import { Form, Input, message, Modal, Typography } from "antd";
import Axios from "axios";
import { useState } from "react";
import { i18n } from "src/utils";

interface UpdatePasswordModalProps {
  userCode: string;
  show: boolean;
  closeUpdatePasswordModal: () => void;
}

export default function UpdatePasswordModal({ userCode, show, closeUpdatePasswordModal }: UpdatePasswordModalProps) {
  // Campos
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  // Validações
  const [equalPasswords, setEqualPasswords] = useState(true);
  const [smallPasswords, setSmallPasswords] = useState(false);
  const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);

  // Salva a nova senha do usuário
  async function saveNewPassword() {
    let haveError = false;
    setWrongCurrentPassword(false);
    if (newPassword === newPasswordConfirmation) {
      setEqualPasswords(true);
    } else {
      haveError = true;
      setEqualPasswords(false);
    }
    if (newPassword.length < 8) {
      haveError = true;
      setSmallPasswords(true);
    } else if (newPassword.length >= 8) {
      setSmallPasswords(false);
    }
    if (haveError) {
      return;
    }
    const response = await Axios.post("/user-update-password", {
      newPassword,
      currentPassword,
      userCode,
    });
    if (response.data.message === "PASSWORD_SUCCESSFULLY_UPDATED") {
      message.destroy();
      message.success(i18n("components.perfil.updatePasswordModal.notifications.passwordUpdated"), 3);
      clearFields();
      closeUpdatePasswordModal();
    } else {
      setWrongCurrentPassword(true);
      setCurrentPassword("");
    }
  }

  // Limpa os campos do formulário
  function clearFields() {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
    setEqualPasswords(true);
    setSmallPasswords(false);
    setWrongCurrentPassword(false);
  }

  return (
    <Modal
      width={400}
      open={show}
      title={<Typography.Title level={4}>{i18n("components.perfil.updatePasswordModal.title")}</Typography.Title>}
      okText={i18n("components.perfil.updatePasswordModal.save")}
      onOk={() => saveNewPassword()}
      onCancel={() => {
        closeUpdatePasswordModal();
        clearFields();
      }}
      okButtonProps={{ disabled: !currentPassword }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Form layout="vertical">
        {/* Senha atual */}
        <Form.Item
          required
          label={i18n("components.perfil.updatePasswordModal.currentPassword")}
          validateStatus={wrongCurrentPassword ? "error" : "success"}
          help={
            wrongCurrentPassword && i18n("components.perfil.updatePasswordModal.notifications.incorrectCurrentPassword")
          }
        >
          <Input.Password
            value={currentPassword}
            onChange={({ target }) => setCurrentPassword(target.value)}
          />
        </Form.Item>

        {/* Nova senha */}
        <Form.Item
          required
          label={i18n("components.perfil.updatePasswordModal.newPassword")}
          validateStatus={smallPasswords ? "error" : "success"}
          help={i18n("components.perfil.updatePasswordModal.warnings.smallPasswords")}
        >
          <Input.Password
            value={newPassword}
            onChange={({ target }) => setNewPassword(target.value)}
          />
        </Form.Item>

        {/* Confirmação da nova senha */}
        <Form.Item
          required
          label={i18n("components.perfil.updatePasswordModal.newPasswordConfirmation")}
          validateStatus={!equalPasswords ? "error" : "success"}
          help={!equalPasswords && i18n("components.perfil.updatePasswordModal.warnings.equalPasswords")}
        >
          <Input.Password
            value={newPasswordConfirmation}
            onChange={({ target }) => setNewPasswordConfirmation(target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
