import { WarningOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { dictionary, i18n } from "src/utils";

const { Title, Paragraph } = Typography;

export default function FinanceAccessDenied() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "50px",
        width: "100%",
      }}
    >
      <WarningOutlined style={{ color: "#e8c605", fontSize: "80px" }} />
      <Title
        level={2}
        style={{ marginTop: "10px" }}
      >
        {i18n(dictionary.components.finance.financeAccessDenied.title)}
      </Title>
      <Paragraph style={{ fontSize: "12pt" }}>
        {i18n(dictionary.components.finance.financeAccessDenied.subtitle)}
      </Paragraph>
    </div>
  );
}
