import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<Benefit[]> => {
  try {
    const { data } = await request.get(`/benefits`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchByEmployer = async (employerId: string): Promise<Benefit[]> => {
  try {
    const { data } = await request.get(`/benefits/employer/${employerId}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Benefit): Promise<Benefit> => {
  try {
    const { data } = await request.post(`/benefits`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
