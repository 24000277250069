import { Link } from "react-router-dom";
import { light } from "src/styles/themes";

type CustomLinkProps = {
  path: string;
  text: string;
};

export default function CustomLink({ path, text }: CustomLinkProps) {
  return (
    <Link
      style={{
        color: light.colors.brand.primary.light,
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "normal",
      }}
      to={path}
    >
      {text}
    </Link>
  );
}
