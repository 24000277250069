import { Col, Form, FormInstance, Input, Modal, Row, Typography, message } from "antd";
import Axios from "axios";
import { useEffect, useState } from "react";
import { UserApi } from "src/api";
import { dictionary, i18n, validateEmail } from "src/utils";
import "./Profile.css";
import UpdatePasswordModal from "./UpdatePasswordModal";

interface ProfileProps {
  user: User;
  onFinish(values: any): void;
  form: FormInstance<any>;
}

export default function Profile(props: ProfileProps) {
  const [nome, setNome] = useState<string>(props.user.name);
  const [email, setEmail] = useState<string>(props.user.email);
  const [cpf, setCpf] = useState<string>("");
  const [razaoSocial, setRazaoSocial] = useState<string>("");
  const [managerName, setManagerName] = useState<string>("");
  const [managerPhone, setManagerPhone] = useState<string>("");
  const [alternativePhone, setalternativePhone] = useState<string>("");
  const [codUsuario, setcodUsuario] = useState<string>("");
  const [saving, setsaving] = useState<boolean>(false);
  const [fileURL, setfileURL] = useState<string>("");
  const [countryOrigin, setcountryOrigin] = useState<string>("");
  const [userType, setuserType] = useState<string>("");
  const [showAlternativePhone, setshowAlternativePhone] = useState<boolean>("" ? true : false);
  const [showUpdatePasswordModal, setshowUpdatePasswordModal] = useState<boolean>(false);
  const [validName, setvalidName] = useState<string>("success");
  const [validEmail, setvalidEmail] = useState<string>("success");
  const [validRazaoSocial, setvalidRazaoSocial] = useState<string>("success");
  const [validCompanyName, setValidCompanyName] = useState<string>("success");

  function closeUpdatePasswordModal() {
    setshowUpdatePasswordModal(false);
  }

  function handleChange(info: any) {
    if (info.event) {
      getBase64(info.file.originFileObj, (fileURL: string) => {
        setfileURL(fileURL);
      });
    }
  }

  function saveImage() {
    Axios.post("/user-change-image", {
      image: fileURL.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*;(base64),)/g, ""),
      codUsuario: codUsuario,
    })
      .then((_) => {
        message.success(i18n(dictionary.image_updated));
        Axios.get("/perfil/" + codUsuario)
          // .then(() =>
          //   this.props.dispatch(
          //     actions.setSession({
          //       fileURL: this.state.fileURL,
          //     })
          //   )
          // )
          .then((res) => setfileURL(""));
      })
      .catch((_) => {
        message.error(i18n(dictionary.image_update_error));
      });
  }

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  async function save() {
    let haveError = false;
    setvalidName("success");
    setvalidEmail("success");
    setValidCompanyName("success");
    if (!nome.length || nome.length > 150) {
      setvalidName("error");
      haveError = true;
    }
    if (userType === "master" && (!razaoSocial || !razaoSocial.length || razaoSocial.length > 70)) {
      setvalidRazaoSocial("error");
      haveError = true;
    }
    if (!validateEmail(email) || !email.length) {
      setvalidEmail("error");
      haveError = true;
    }
    if (haveError) {
      return Modal.error({
        title: i18n(dictionary.components.perfil.modalTitle),
        content: i18n(dictionary.components.perfil.modalEmail),
      });
    }
    setsaving(true);

    await UserApi.updateUser(0, props.user).then((res: any) => {
      if (res.data.success) {
        let equalNames = false;
        if (managerName && nome) {
          equalNames = managerName.toLowerCase() === nome.toLowerCase();
        }
        // props.dispatch(
        //   actions.setSession({
        //     nomeTopBar:
        //       this.props.perfilUsuario.userType === "master" && managerName
        //         ? equalNames
        //           ? nome
        //           : managerName + " - " + nome
        //         : nome,
        //     email,
        //     cpf,
        //     managerName,
        //     managerPhone,
        //     alternativePhone,
        //     nomeUsuario: nome,
        //     razaoSocial,
        //   })
        // );
        message.success(i18n(dictionary.components.perfil.messageAtualizado));
      } else {
        if (res.data.exists) {
          message.error(i18n(dictionary.components.perfil.messageEmail));
        } else {
          message.error(i18n(dictionary.components.perfil.messageErro));
        }
      }
      setsaving(false);
      setshowAlternativePhone(alternativePhone ? true : false);
    });
  }

  function handleAddPhone() {
    setshowAlternativePhone(true);
  }

  useEffect(() => {
    props.form.setFieldsValue(props.user);
  }, [props.user]);

  return (
    <Form
      form={props.form}
      layout="vertical"
      onFinish={() => props.onFinish(props.form.getFieldsValue())}
    >
      <UpdatePasswordModal
        userCode={props.user.id.toString()}
        show={showUpdatePasswordModal}
        closeUpdatePasswordModal={closeUpdatePasswordModal}
      />
      {/* <FormTopBar
          initialData={initialData}
          currentData={{
            nome,
            email: email,
            cpf: cpf,
            razaoSocial: razaoSocial,
            managerName,
            managerPhone,
            alternativePhone,
          }}
          hideDelete={true}
          goBack={() => props.history.goBack()}
          onSave={() => save()}
        /> */}
      {/* <Divider /> */}
      <Row
        className="profile-form-row"
        gutter={32}
      >
        <Col
          className="profile-form-col"
          span={12}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {userType === "master"
              ? i18n(dictionary.components.perfil.formItems.masterTitle)
              : i18n(dictionary.components.perfil.formItems.managerTitle)}
          </Typography>

          {/* Nome da empresa */}
          <Form.Item<User>
            name="name"
            required
            style={{ marginTop: "30px", marginBottom: "64px" }}
            label={
              userType === "master"
                ? i18n(dictionary.components.perfil.formItems.masterName)
                : i18n(dictionary.components.perfil.formItems.nome)
            }
            colon={false}
            // validateStatus={validName}
            hasFeedback={validName === "error"}
          >
            <Input
              onChange={({ target }) => setNome(target.value.toUpperCase())}
              style={{ textTransform: "uppercase" }}
              maxLength={40}
              defaultValue={nome}
            />
          </Form.Item>

          {/* E-mail de acesso */}
          <Form.Item<User>
            name="login"
            required
            style={{ marginTop: "26px" }}
            label={i18n(dictionary.components.perfil.formItems.email)}
            colon={false}
            // validateStatus={validEmail}
            hasFeedback={validEmail === "error"}
          >
            <Input
              maxLength={100}
              defaultValue={email}
              onChange={({ target }) => {
                const { value } = target;
                setEmail(value);
              }}
              disabled={saving}
            />
          </Form.Item>

          {/* Razão social */}
          {userType === "master" && (
            <Form.Item
              required
              style={{ marginTop: "26px" }}
              label={i18n(dictionary.components.perfil.formItems.razaoSocial)}
              colon={false}
              // validateStatus={validRazaoSocial}
              hasFeedback={validRazaoSocial === "error"}
            >
              <Input
                maxLength={100}
                defaultValue={razaoSocial}
                onChange={({ target }) => {
                  const { value } = target;
                  setRazaoSocial(value);
                }}
                disabled={saving}
              />
            </Form.Item>
          )}

          {/* {(!countryOrigin || countryOrigin === "1") && (            
            <Form.Item
              className="profile-field"
              label="CPF"
              colon={false}
              style={{ marginTop: "64px" }}
            >
              <MaskedInput
                mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                showMask={false}
                placeholderChar={" "}
                keepCharPositions={false}
                defaultValue={cpf}
                onChange={({ target }) => {
                  const { value } = target;
                  if (!value) return;
                  setCpf(target.value.replace(/[\.\-]/g, ""));
                }}
                render={(ref: any, props) => (
                  <input
                    ref={ref}
                    className="ant-input"
                    {...props}
                  />
                )}
                disabled={props.perfilUsuario ? props.perfilUsuario.userType === "user" : false}
              />
            </Form.Item>
          )}
          {countryOrigin === "2" && (            
            <Form.Item
              className="profile-field"
              label="NIF"
              colon={false}
              style={{ marginTop: "64px" }}
            >
              <Input
                defaultValue={cpf}
                onChange={({ target }) => {
                  const { value } = target;
                  if (!value) return;
                  setCpf(value);
                }}
                maxLength={40}
              />
            </Form.Item>
          )} */}
        </Col>
        {/* <Col
          className="profile-form-col"
          span={12}
        >          
          <Form.Item
            className="profile-avatar"
            label={
              userType === "master"
                ? i18n(dictionary.components.perfil.formItems.foto.master)
                : userType === "gestor"
                ? i18n(dictionary.components.perfil.formItems.foto.manager)
                : i18n(dictionary.components.perfil.formItems.foto.employee)
            }
            colon={false}
            style={{ textAlign: "center" }}
            labelCol={{ span: 6, offset: 10 }}
          >
            <Popconfirm
              visible={!!fileURL}
              title={i18n(dictionary.components.perfil.formItems.manter)}
              okText={i18n(dictionary.label.yes)}
              cancelText={i18n(dictionary.label.no)}
              onCancel={() => setfileURL("")}
              onConfirm={saveImage}
            >
              <Avatar
                shape="circle"
                size={116}
                src={fileURL ? fileURL : `/perfil/${codUsuario}`}
                style={{ marginTop: "10px" }}
              />
            </Popconfirm>
          </Form.Item>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Upload
              name={i18n(dictionary.components.perfil.formItems.avatar)}
              accept={"image/x-png,image/jpeg"}
              showUploadList={false}
              onChange={handleChange}
              multiple={false}
            >
              <Button type="primary">
                <UploadOutlined /> {i18n(dictionary.components.perfil.alterar)}
              </Button>
            </Upload>
          </div>          
          {userType === "master" && (
            <>
              <Typography style={{ fontWeight: "bold", margin: "15px 0 15px 0" }}>
                {i18n(dictionary.components.perfil.contactInfo.title)}
              </Typography>
              
              <Form.Item
                colon={false}
                label={i18n(dictionary.components.perfil.contactInfo.name)}
                className="ant-profile-form-item"
              >
                <Input
                  style={{ border: !managerName ? "5px solid red" : "" }}
                  defaultValue={managerName}
                  onChange={({ target }) => setManagerName(target.value)}
                  maxLength={20}
                />
              </Form.Item>
            </>
          )}
          {userType !== "master" && <br />}
          
          <Form.Item
            className="profile-field-custom"
            style={{ marginTop: "28px" }}
            colon={false}
            label={i18n(dictionary.components.perfil.contactInfo.phone)}
          >
            <ReactInputMask
              style={{ border: !managerPhone ? "5px solid red" : "", width: "50%" }}
              defaultValue={managerPhone}
              value={managerPhone}
              onChange={({ target }) => setManagerPhone(target.value)}
              mask={
                i18n(dictionary.locale) === "pt-br"
                  ? "(99) 99999-9999"
                  : i18n(dictionary.locale) === "pt"
                  ? "999 999 999"
                  : "(999) 999-9999"
              }
              disabled={false}
            >
              {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    disabled={false}
                  />
                )}
            </ReactInputMask>
            {managerPhone && !showAlternativePhone && (
              <Button
                onClick={() => handleAddPhone()}
                style={{ marginLeft: "1.4rem", color: "#F1FBF8", backgroundColor: "#009262" }}
              >
                <PlusOutlined />
                {i18n(dictionary.components.perfil.contactInfo.addPhone)}
              </Button>
            )}
            {showAlternativePhone && (
              <ReactInputMask
                style={{ width: "45%", marginLeft: "22px" }}
                defaultValue={alternativePhone}
                value={alternativePhone}
                onChange={({ target }) => setalternativePhone(target.value)}
                mask={
                  i18n(dictionary.locale) === "pt-br"
                    ? "(99) 99999-9999"
                    : i18n(dictionary.locale) === "pt"
                    ? "999 999 999"
                    : "(999) 999-9999"
                }
                disabled={false}
              >
                {(inputProps) => (
                    <Input
                      {...inputProps}
                      disabled={false}
                    />
                  )}
              </ReactInputMask>
            )}
          </Form.Item>
        </Col> */}
      </Row>
    </Form>
  );
}
