import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { capitalizeFirstLetter, ptBR } from 'src/utils';

const resources = {
  pt_BR: {
    translation: ptBR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt_BR',
  interpolation: {
    escapeValue: false,
  },
});

i18n.services.formatter?.add('capitalized', (value) =>
  capitalizeFirstLetter(value),
);

export default i18n;
