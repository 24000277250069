import { Modal, Typography } from "antd";
import { dictionary, i18n } from "src/utils";

const { Text, Link } = Typography;

interface GoodErrorMessageProps {
  title: string;
  message: string;
  onOk: () => void;
  onClickLink: () => void;
}

export default function GoodErrorMessage(props: GoodErrorMessageProps) {
  return Modal.confirm({
    okText: i18n(dictionary.tryAgain),
    title: props.title,
    content: (
      <>
        <Text>{props.message.concat(" " + i18n(dictionary.message.tryAgain.text))}</Text>
        <Link onClick={() => (props.onClickLink ? props.onClickLink() : null)}>
          {i18n(dictionary.message.tryAgain.alternativeAction)}
        </Link>
      </>
    ),
    onOk: () => props.onOk(),
  });
}
