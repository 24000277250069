import React, { useContext } from 'react';
import Dashboard from './Dashboard';
import { RoutingContext } from 'src/contexts';
import { inject, observer } from 'src/modules';

const DashboardContainer: React.FC = () => {
  const { setTitle, setSubtitle } = useContext(RoutingContext);
  setTitle('Sistema de admissões');
  setSubtitle('Dashboard');

  return <Dashboard />;
};
export default inject('routing', 'users')(observer(DashboardContainer));
