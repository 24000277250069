import { Button, Col, Modal, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/routes/routing";
import { dictionary, i18n } from "src/utils";

export interface AdmissionsLimitModalData {
  open: boolean;
  admissionsLimit: number;
  isCustomer: boolean;
  isAccountingCustomer: boolean;
}

interface AdmissionsLimitModalProps {
  data: AdmissionsLimitModalData;
  handleCancel: () => void;
}
function AdmissionsLimitModal({ data, handleCancel }: AdmissionsLimitModalProps) {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={data.open}
        title={i18n(
          data.isCustomer
            ? dictionary.error.ADMISSION_LIMIT_ERROR.title2
            : data.isAccountingCustomer
            ? dictionary.error.ADMISSION_LIMIT_ERROR.title3
            : dictionary.error.ADMISSION_LIMIT_ERROR.title1,
        )}
        onCancel={handleCancel}
        footer={[
          <>
            {!data.isAccountingCustomer && (
              <Row
                key="row"
                justify="center"
                align="middle"
                gutter={[8, 8]}
                style={{ flexDirection: "column", alignContent: "center" }}
              >
                <Col
                  key="columnComercial"
                  span={20}
                >
                  <Button
                    className="7ce5a661-7b43-4fc2-bb9c-15e3ee343f35"
                    key="link"
                    type="primary"
                    onClick={() => navigate(Routes.FINANCE, { replace: true })}
                  >
                    {i18n(!data.isCustomer ? dictionary.action.contract_plan : dictionary.action.change_plan)}
                  </Button>
                </Col>
                <Col
                  key="column"
                  span={20}
                >
                  <Button
                    key="back"
                    onClick={handleCancel}
                  >
                    {i18n(dictionary.action.cancel)}
                  </Button>
                </Col>
              </Row>
            )}
          </>,
        ]}
      >
        <p style={{ textAlign: "center" }}>
          {data.isCustomer
            ? i18n(dictionary.error.ADMISSION_LIMIT_ERROR.message2_1) +
              data.admissionsLimit +
              i18n(dictionary.error.ADMISSION_LIMIT_ERROR.message2_2)
            : data.isAccountingCustomer
            ? i18n(dictionary.error.ADMISSION_LIMIT_ERROR.message3_1) +
              data.admissionsLimit +
              i18n(dictionary.error.ADMISSION_LIMIT_ERROR.message3_2)
            : i18n(dictionary.error.ADMISSION_LIMIT_ERROR.message1)}
        </p>
      </Modal>
    </>
  );
}

export default AdmissionsLimitModal;
