import request from "../request";

export const update = async (field: string): Promise<boolean> => {
  try {
    const { status, data } = await request.post("/intercom", {
      field,
    });
    return !!(status === 200 && data.success);
  } catch (_) {
    return false;
  }
};
