import { Button, Flex, Image, Layout, Space, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdmissionApi, AdmissionFlowApi } from "src/api";
import { SendingDocument, SendingDocumentEnd } from "src/assets/images";
import PublicEmployeeStartPage from "src/components/PublicEmployeeStartPage";
import { REACT_APP_BASE_PATH } from "src/constants";
import { dictionary, getDescryptedParamsData, i18n } from "src/utils";
import styled from "styled-components";

const { Text, Title, Link } = Typography;
const { Header, Footer, Content } = Layout;

const headerStyle: React.CSSProperties = {
  textAlign: "center",
  height: 64,
  paddingInline: 50,
  lineHeight: "128px",
  background: "#f5f5f5",
};

const contentStyle: React.CSSProperties = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "200px",
};

const footerStyle: React.CSSProperties = {
  textAlign: "center",
};

const StyledTitle = styled(Title)`
  &.ant-typography {
    color: ${(props) => props.color};
  }
`;

export default function DownloadContract() {
  const [step, setStep] = useState(0);

  const [employeeId, setEmployeeId] = useState("");

  const [data, setData] = useState({
    primaryColor: "#884ea6",
    secondaryColor: "#66C790",
    logo: "",
    pagePhrase: "",
    pageDescription: "",
  });

  const [downloadingContract, setDownloadingContract] = useState(false);

  const params = useParams();

  async function getData(employeeId: string) {
    const responseData = await AdmissionFlowApi.findByEmployeeId(employeeId);
    if (responseData) {
      const stepData = responseData.admissionFlow.flowSteps.find(({ stepId }: { stepId: number }) => stepId === 2);
      setEmployeeId(employeeId);
      setData({
        primaryColor: responseData.admissionFlow.primaryColor,
        secondaryColor: responseData.admissionFlow.secondaryColor,
        logo: responseData.admissionFlow.logo,
        pagePhrase: stepData.pagePhrase,
        pageDescription: stepData.pageDescription,
      });
      setStep(1);
    }
  }

  async function downloadContract() {
    message.destroy();
    setDownloadingContract(true);
    const response = await AdmissionApi.downloadContract(Number(employeeId)).catch((_) => false);
    if (!response) {
      message.error(i18n(dictionary.label.error_download_contract));
    }
    setDownloadingContract(false);
  }

  useEffect(() => {
    const paramsData = getDescryptedParamsData(params.data || "");
    if (paramsData && paramsData.employeeId) {
      getData(paramsData.employeeId);
    }
  }, []);

  return (
    <>
      {step === 0 && (
        <Flex
          style={{ height: "100vh", width: "100vw" }}
          align="center"
          justify="center"
        >
          <Spin size="large" />
        </Flex>
      )}
      {step === 1 && (
        <PublicEmployeeStartPage
          next={() => setStep((prevStep) => prevStep + 1)}
          data={data}
        />
      )}
      {step === 2 && (
        <Space
          direction="vertical"
          style={{ width: "100%" }}
          size={[0, 48]}
        >
          <Layout>
            <Header style={headerStyle}>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "end",
                  textAlign: "end",
                }}
              >
                <Image
                  style={{ marginTop: "64px", marginRight: "64px", height: "25%", width: "25%" }}
                  src={!data.logo ? SendingDocument : `${REACT_APP_BASE_PATH}/download/${data.logo}`}
                  alt="Logo"
                  preview={false}
                />
              </Space>
            </Header>
            <Content style={contentStyle}>
              <Image
                style={{ height: "25%", width: "25%" }}
                src={SendingDocumentEnd}
                alt="Foguete subindo"
                preview={false}
              />
              <Space
                direction="vertical"
                wrap
                size={0}
                style={{ display: "flex" }}
              >
                <StyledTitle
                  style={{ fontSize: "78px", marginBottom: "16px" }}
                  color={data.secondaryColor}
                >
                  Deu tudo certo!
                </StyledTitle>
                <StyledTitle
                  level={2}
                  style={{
                    marginLeft: "16%",
                    marginRight: "16%",
                  }}
                >
                  A Admissão Digital deseja que a sua nova jornada seja incrível :)
                </StyledTitle>
                <StyledTitle
                  color="gray"
                  level={3}
                  style={{
                    marginLeft: "30%",
                    marginRight: "30%",
                  }}
                >
                  Qualquer dúvida não deixe de entrar em contato com o departamento pessoal da sua empresa!
                </StyledTitle>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    background: "#6164C2",
                    minHeight: "42px",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                  loading={downloadingContract}
                  onClick={downloadContract}
                >
                  {i18n(dictionary.label.download_contract)}
                </Button>
              </Space>
            </Content>
            <Footer style={footerStyle}>Desenvolvido com carinho pela Admissão Digital</Footer>
          </Layout>
        </Space>
      )}
    </>
  );
}
