export default {
  colors: {
    primary: "#FF7400",
    primaryDark: "#ed6c00",
    secondary: "#62009b",
    gray: "#8C91A5",
    grayLowerOpacity: "#abaebf",
    lightGray: "#F5F5F5",
    darkGray: "#6C757D",
    success: "#37B24D",
    error: "#dd0000",
    info: "#4324fa",
    label: "#616161",
    background: "#fff",
    yellow: "#ffd008",
    text: "#424242",
    textLight: "#9E9E9E",
    white: "#fff",
    line: "#eff4fa",
    green: "#66C790",
    brand: {
      primary: {
        light: "#884EA6",
        main: "#520556",
        dark: "#713391",
        contrast: "#fff",
      },
      secondary: {
        pink: "#941C9A",
        purple: "#640A68",
        light: "#8B34A7",
        main: "#62009b",
        dark: "#62009b",
        contrast: "#fff",
      },
      tertiary: {
        light: "#2804FB",
        main: "#2804FB",
        dark: "#2804FB",
        contrast: "#fff",
      },
      accent: {
        light: "#ffd008",
        main: "#ffd008",
        dark: "#ffd008",
        contrast: "#fff",
      },
      success: {
        light: "#8CE99A",
        main: "#51CF66",
        dark: "#37B24D",
        contrast: "#fff",
      },
      danger: {
        light: "#dd0000",
        main: "#dd0000",
        dark: "#dd0000",
        contrast: "#fff",
      },
      warning: {
        light: "#FFF6B3",
        main: "#FFF53D",
        dark: "#C6BB38",
        contrast: "#000",
      },
      info: {
        light: "#91A7FF",
        main: "#5C7CFA",
        dark: "#4263EB",
        contrast: "#fff",
      },
      text: {
        light: "#444444",
        main: "#757575",
        dark: "#999999",
        contrast: "#fff",
      },
      background: {
        z0: "#ffffff",
        z1: "#fafafa",
        z2: "#f0f0f0",
        z3: "#dddddd",
        z4: "#d1d1d1",
      },
      gray: {
        ultralight: "#EEEEEE",
        dark: "#616161",
        silver: "#f5f7fa",
      },
    },
    backgroundLinear: {
      green: "#66C790",
      blue: "#6164C2",
      purple: "#884ea6",
    },
  },
  fontSizes: {
    default: "1.4rem",
    large: "1.6rem",
    small: "1.2rem",
    tiny: "1rem",
  },
  spacing: {
    default: "1.6rem",
    vertical: "1.6rem 0",
    horizontal: "0 1.6rem",
    large: "2.4rem",
  },
  transition: {
    default: "180ms ease-in-out",
  },
  radii: {
    default: "0.8rem",
    small: "0.4rem",
    smallTop: "0.4rem 0.4rem 0 0",
    smallBottom: "0 0 0.4rem 0.4rem",
    tiny: "0.2rem",
  },
  shadows: {
    default: "0 0.5rem 2rem rgba(0, 0, 0, 0.08)",
    flat: "0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)",
  },
};
