import { ContainerOutlined, MailOutlined, UnlockOutlined } from "@ant-design/icons";
import { TreeSelect } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { DepartmentApi, EmployeeApi, EmployerApi } from "src/api";

import { Card, Form, FormItem, If, InputGroup, Tabs } from "src/components";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { Input, Select } from "src/modules";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";

const { Option } = Select;

type Props = {
  onGoBack(): void;
  onFinish(): void;
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  editMode: boolean;
};

const treeData = [
  {
    value: "parent 1-0",
    title: "Página inicial",
  },
  {
    value: "parent 1-1",
    title: "Cadastros",
    children: [
      {
        value: "leaf1",
        title: "Contratos",
      },
      {
        value: "leaf2",
        title: "Vínculos Empregatícios",
      },
      {
        value: "leaf3",
        title: "Empregadores",
      },
      {
        value: "leaf4",
        title: "Colaboradores",
      },
    ],
  },
  {
    value: "parent 1-2",
    title: "Relatórios",
    children: [
      {
        value: "leaf5",
        title: "Fichas cadastrais",
      },
    ],
  },
];

function UserForm({ onGoBack, onFinish, tabs, setCurrentTab, currentTab, editMode }: Props): JSX.Element {
  const [value, setValue] = useState<string>();
  const [departments, setDepartments] = useState<Department[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [form] = useForm();

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  const { show } = useIntercom();

  async function getDepartments(): Promise<boolean> {
    return await DepartmentApi.fetch()
      .then((res: Department[]) => {
        setDepartments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.GET_DEPARTMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployees(): Promise<boolean> {
    return await EmployeeApi.fetch()
      .then((res: Employee[]) => {
        setEmployees(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYEE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployers(): Promise<boolean> {
    return await EmployerApi.fetch()
      .then((res: Employer[]) => {
        setEmployers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getDepartments();
      await getEmployees();
      await getEmployers();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <Form
          onCancel={onGoBack}
          onSubmit={() => onFinish()}
          submitText={editMode ? "Salvar alterações" : "Cadastrar usuário"}
          form={form}
        >
          <Card
            icon={<ContainerOutlined />}
            title="Dados do usuário"
          >
            <FormItem
              label="Nome completo"
              name="name"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label="E-mail"
              name="email"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input
                type="email"
                addonBefore={<MailOutlined />}
              />
            </FormItem>

            <FormItem>
              <InputGroup>
                <FormItem
                  label="Senha"
                  name="password"
                  rules={[{ required: !editMode, message: i18n(dictionary.message.requiredField) }]}
                >
                  <Input.Password />
                </FormItem>

                <FormItem
                  label="Confirmação de senha"
                  name="passwordConfirmation"
                  rules={[{ required: !editMode, message: i18n(dictionary.message.requiredField) }]}
                >
                  <Input.Password />
                </FormItem>
              </InputGroup>
            </FormItem>
          </Card>

          <Card
            title="Acessos do usuário"
            icon={<UnlockOutlined />}
          >
            <FormItem
              label="Empregadores"
              name="employers"
              rules={[{ required: false }]}
            >
              <Select mode="multiple">
                {employers.map((employer) => (
                  <Option
                    key={employer.id}
                    title={employer.shortName}
                    value={employer.id}
                  >
                    {employer.shortName}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label="Departamentos"
              name="departments"
              rules={[{ required: false }]}
            >
              <Select mode="multiple">
                {departments.map((department) => (
                  <Option
                    key={department.id}
                    title={department.description}
                    value={department.id}
                  >
                    {department.description}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label="Colaboradores"
              name="employees"
              rules={[{ required: false }]}
            >
              <Select mode="multiple">
                {employees.map((employee) => (
                  <Option
                    key={employee.id}
                    title={employee.name}
                    value={employee.id}
                  >
                    {employee.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label="Seções do sistema"
              name="menus"
              rules={[{ required: false }]}
            >
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Por favor selecione aqui"
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeData={treeData}
              />
              {/* <Select mode="multiple">
                {employers.map((employer) => (
                  <Option
                    key={employer.id}
                    title={employer.tradingName}
                    value={employer.id}
                  >
                    {employer.tradingName}
                  </Option>
                ))}
              </Select> */}
            </FormItem>
          </Card>
        </Form>
      </If>
    </>
  );
}

export default UserForm;
