import { Input } from "antd";
import styled from "styled-components";

export const EmployeeSearch = styled(Input.Search)`
  width: 360px;

  @media (max-width: 830px) {
    width: 100%;
  }
`;
