import React from 'react';
import { Select as AntSelect, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

export default function Select(props: SelectProps): React.ReactElement {
  const { children, showSearch } = props;

  function filterOption(
    input: string,
    option: DefaultOptionType | undefined,
  ): boolean {
    const lowerCaseInput = input.toLocaleLowerCase();
    if (String(option?.children).toLocaleLowerCase().includes(lowerCaseInput))
      return true;
    return false;
  }

  return (
    <AntSelect {...props} filterOption={showSearch ? filterOption : false}>
      {children}
    </AntSelect>
  );
}
