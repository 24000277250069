import { useEffect, useRef, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { EmployeeApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";
import RegistrationForm from "./RegistrationForm";

function RegistrationFormContainer(): JSX.Element {
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const { show } = useIntercom();

  async function getEmployeeData(): Promise<boolean> {
    return await EmployeeApi.fetch()
      .then((res: Employee[]) => {
        setEmployeeList(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYEE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmployeeData();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return <RegistrationForm employees={employeeList} />;
}

export default RegistrationFormContainer;
