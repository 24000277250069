import { Button, Flex, InputNumber, Modal, Typography, notification } from "antd";
import { useState } from "react";
import { createFranchiseRequest } from "src/api/auth/admission.api";
import { dictionary, i18n } from "src/utils";

const { Text } = Typography;

interface EmployeeLimitReachedModalProps {
  open: boolean;
  cancel: () => void;
}

export default function EmployeeLimitReachedModal({ open, cancel }: EmployeeLimitReachedModalProps) {
  const [notificationApi, contextHolder] = notification.useNotification();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const [amount, setAmount] = useState(1);
  const [sending, setSending] = useState(false);

  async function handleSend() {
    setSending(true);
    notificationApi.destroy();
    const response = await createFranchiseRequest(amount);
    if (response) {
      modalApi.success({
        title: i18n(dictionary.components.employeeLimitReachedModal.notifications.success.title),
        content: (
          <Text>
            {i18n(dictionary.components.employeeLimitReachedModal.notifications.success.texts.p1)}
            <Text style={{ color: "#884ea6" }}>
              {amount} {i18n(dictionary.components.employeeLimitReachedModal.notifications.success.texts.p2)}
            </Text>
            {i18n(dictionary.components.employeeLimitReachedModal.notifications.success.texts.p3)}
          </Text>
        ),
        onOk: () => {
          setAmount(1);
          cancel();
        },
      });
    } else {
      notificationApi.error({
        message: i18n(dictionary.ops),
        description: i18n(dictionary.components.employeeLimitReachedModal.notifications.error),
      });
    }
    setSending(false);
  }

  return (
    <Modal
      open={open}
      onCancel={() => {
        setAmount(1);
        cancel();
      }}
      title={i18n(dictionary.components.employeeLimitReachedModal.title)}
      footer={
        <Flex
          justify="center"
          align="center"
        >
          <Button
            type="primary"
            onClick={handleSend}
            loading={sending}
          >
            {i18n(dictionary.components.employeeLimitReachedModal.button)}
          </Button>
        </Flex>
      }
    >
      {contextHolder}
      {modalContextHolder}
      <Flex
        vertical
        gap={10}
      >
        <Text style={{ margin: "10px 0" }}>{i18n(dictionary.components.employeeLimitReachedModal.text)}</Text>
        <Flex
          align="center"
          justify="center"
        >
          <InputNumber
            min={1}
            value={amount}
            onChange={(value) => setAmount(Number(value))}
          />
        </Flex>
      </Flex>
    </Modal>
  );
}
