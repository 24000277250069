import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ChangeHistoryApi, ContractApi, DocumentApi, EmploymentRelationshipApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { employmentRelationshipCodes } from "src/data";
import { useEditMode } from "src/hooks";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import DocumentationEdit from "./DocumentationEdit";

const initialForm: EmploymentRelationship = {
  code: "",
  contractId: 0,
  description: "",
  documents: [],
  id: 0,
};

function DocumentationEditContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(1);
  const [documentCount, setDocumentCount] = useState(0);
  const [relationshipDocuments, setRelationshipDocuments] = useState<EmploymentRelationshipDocument[]>([]);
  const [editMode] = useEditMode();
  const [tabs, setTabs] = useState<Array<{ index: number; key: string; title: string }>>([]);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [documents, setDocuments] = useState<RelDocument[]>([]);
  const { show } = useIntercom();
  const [employmentRelationship, setEmploymentRelationship] = useState<EmploymentRelationship>(initialForm);
  const [params, setParams] = useSearchParams();
  const [documentLogs, setDocumentLogs] = useState<ChangeHistory[]>([]);

  async function getChangeHistory(): Promise<boolean> {
    return await ChangeHistoryApi.fetchByTable("admission.pp_vinculo_empregaticio", params.get("id") ?? "0")
      .then((res: ChangeHistory[]) => {
        setDocumentLogs(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.title),
            message: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    // if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getContracts();
      await getDocuments();
      if (editMode) {
        await getEmploymentRelationshipData();
        await getChangeHistory();
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
    if (editMode) {
      setTabs([
        {
          index: 1,
          key: "editRelationship",
          title: i18n(dictionary.label.edit_item, {
            item: i18n(dictionary.entity.employment_relationship),
          }),
        },
        {
          index: 2,
          key: "history",
          title: i18n(dictionary.label.history),
        },
      ]);
    } else {
      setTabs([
        {
          index: 1,
          key: "newRelationship",
          title: i18n(dictionary.label.new_item, {
            context: context.male,
            item: i18n(dictionary.entity.employment_relationship),
          }),
        },
      ]);
    }
  }, [editMode]);

  function addRelationshipDocument(): void {
    setRelationshipDocuments([
      ...relationshipDocuments,
      {
        id: documentCount,
        documentId: documents[0].id,
        description: i18n(dictionary.label.document, { count: 1 }) + ` ${documentCount}`,
        isRequired: true,
        hasAttachment: true,
        editable: true,
      },
    ]);
    setDocumentCount(documentCount - 1);
  }

  function removeDocument(key: number): void {
    setRelationshipDocuments(relationshipDocuments.filter((document) => key !== document.id));
  }

  function updateDocument<PropertyType extends keyof EmploymentRelationshipDocument>(
    key: number,
    property: PropertyType,
    value: EmploymentRelationshipDocument[PropertyType],
  ) {
    const documentIndex = relationshipDocuments.findIndex((document) => document.id === key);
    if (documentIndex > -1) {
      // relationshipDocuments[documentIndex][property] = value;
    }
  }

  function updateRelationship<PropertyType extends keyof EmploymentRelationship>(
    key: number,
    property: PropertyType,
    value: EmploymentRelationship[PropertyType],
  ) {
    const documentIndex = relationshipDocuments.findIndex((document) => document.id === key);
    if (documentIndex > -1) {
      //relationshipDocuments[documentIndex][property] = value;
    }
  }

  const navigate = useNavigate();

  const onGoBack = () => navigate(-1);

  const onFinish = (employmentRelationshipForm: EmploymentRelationship): void => {
    if (editMode) onUpdateDocumentation(employmentRelationshipForm);
    else onCreateDocumentation(employmentRelationshipForm);
  };

  const onCreateDocumentation = (employmentRelationshipForm: EmploymentRelationship): void => {
    EmploymentRelationshipApi.create(employmentRelationshipForm)
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.entity.employment_relationship),
          }),
        });
        return onGoBack();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.CREATE_CONTRACT_ERROR.message),
            onOk: () => onCreateDocumentation(employmentRelationshipForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const onUpdateDocumentation = (employmentRelationshipForm: EmploymentRelationship): void => {
    EmploymentRelationshipApi.updateById(employmentRelationship!.id, employmentRelationshipForm)
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.updated_success, {
            context: context.male,
            item: i18n(dictionary.entity.employment_relationship),
          }),
        });
        return onGoBack();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_EMPLOYMENT_RELATIONSHIP_ERROR.title),
            message: i18n(dictionary.error.UPDATE_EMPLOYMENT_RELATIONSHIP_ERROR.message),
            onOk: () => onUpdateDocumentation(employmentRelationshipForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  async function getEmploymentRelationshipData(): Promise<boolean> {
    return await EmploymentRelationshipApi.fetchById(parseInt(params.get("id") ?? "0"))
      .then((res: EmploymentRelationship) => {
        setEmploymentRelationship(res);
        setRelationshipDocuments(res.documents);
        setDocumentCount(-res.documents.length);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getContracts(): Promise<boolean> {
    return await ContractApi.fetch()
      .then((res: Contract[]) => {
        setContracts(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.GET_CONTRACT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getDocuments(): Promise<boolean> {
    return await DocumentApi.fetch()
      .then((res: RelDocument[]) => {
        setDocuments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DOCUMENTS_ERROR.title),
            message: i18n(dictionary.error.GET_DOCUMENTS_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  return (
    <DocumentationEdit
      onGoBack={onGoBack}
      onFinish={onFinish}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      employmentRelationship={employmentRelationship}
      employmentRelationshipCodes={employmentRelationshipCodes}
      contracts={contracts}
      documents={documents}
      employmentRelationshipDocuments={relationshipDocuments}
      addDocument={() => addRelationshipDocument()}
      removeDocument={(key: number) => removeDocument(key)}
      updateDocument={(
        key: number,
        property: keyof EmploymentRelationshipDocument,
        value: EmploymentRelationshipDocument[keyof EmploymentRelationshipDocument],
      ) => updateDocument(key, property, value)}
      updateRelationship={(
        key: number,
        property: keyof EmploymentRelationship,
        value: EmploymentRelationship[keyof EmploymentRelationship],
      ) => updateRelationship(key, property, value)}
      editMode={editMode}
      documentLogs={documentLogs}
    />
  );
}
export default DocumentationEditContainer;
