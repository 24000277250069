import { AuditOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  CollapseProps,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Switch,
  Tag,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { NumberFormatBase } from "react-number-format";
import { useIntercom } from "react-use-intercom";
import {
  AdmissionApi,
  DepartmentApi,
  EmployerApi,
  EmploymentRelationshipApi,
  EmploymentRelationshipDocumentApi,
  IntercomApi,
} from "src/api";
import { AdmissionForm } from "src/api/auth/admission.api";
import {
  FirstAccessFlowDocumentation,
  FirstAccessFlowNewAdmission,
  FirstAccessFlowPartners,
  LogoCompact,
} from "src/assets/images";
import { SelectInsert } from "src/components";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { shifts } from "src/data";
import { Storage } from "src/services";
import { ExpiredSessionError, capitalizeFirstLetter, context, dictionary, formatToBRL, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";
import "./CheckboxGroup.css";

const { Text, Title, Link } = Typography;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const onFinishFailed = (errorInfo: any) => {
  console.error(errorInfo);
};

type EmploymentRelationshipForm = {
  employmentRelationships: EmploymentRelationship[];
};

interface FirstAccessFlowModalProps {
  userEmail: string;
  updateDashboard: () => void;
  userFinishedAdmissionAccess: boolean;
}

function FirstAccessFlowModal({ userEmail, updateDashboard, userFinishedAdmissionAccess }: FirstAccessFlowModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { token } = theme.useToken();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [isPartnersModalOpen, setIsPartnersModalOpen] = useState(false);
  const [disabledForm, setDisabledForm] = useState(false);
  const [employmentRelationshipForm, setEmploymentRelationshipForm] = useState<EmploymentRelationshipForm>({
    employmentRelationships: [],
  });
  const [checkGroupValues, setCheckGroupValues] = useState<any[][]>([]);
  const [employmentExpanded, setEmploymentExpanded] = useState<number[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [employerId, setEmployerId] = useState("");

  const [form] = Form.useForm();
  const { show, update } = useIntercom();
  // const email = Form.useWatch("employeeEmail", form);

  const validateMessages = {
    required: i18n(dictionary.message.requiredRule),
    types: {
      email: i18n(dictionary.message.emailRule),
      number: i18n(dictionary.message.numberRule),
    },
    number: {
      range: i18n(dictionary.message.numberRangeRule),
    },
  };

  async function handleSaveConfig() {
    try {
      await saveConfig();
      next();
    } catch (error) {
      console.error(error);
    }
  }

  const filteredDepartments = employerId
    ? departments.filter(
        (department) => department.employers?.find((employerDepartment) => employerDepartment.employerId == employerId),
      )
    : [];

  const testEmployeeAdmission: AdmissionForm = {
    employerId: employers[0]?.id,
    employmentRelationshipId: employmentRelationshipForm.employmentRelationships[0]?.id,
    name: "COLABORADOR TESTE",
    email: Storage.getUserEmail(),
    jobOccupation: "Programador",
    departmentId: departments[0]?.id,
    salary: "R$ 5.275,75",
    admissionDate: moment(new Date()),
    workExperiencePeriodInDays: 30,
    shiftType: 0,
    firstEmployment: false,
    onboarding: true,
  };

  const insertDepartment = (department: string, employerId: string) => {
    DepartmentApi.create({ id: 0, description: department, employerId })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.department),
          }),
        });
        setDepartments([...departments, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.message),
            onOk: () => insertDepartment(department, employerId),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const collapseItems: CollapseProps["items"] = employmentRelationshipForm.employmentRelationships.map(
    (employmentRelationship, index) => {
      // const checkboxOptions: CheckboxOptionType[] = employmentRelationship.documents.map((document) => {
      //   return {
      //     label: document.description,
      //     value: document.id,
      //   };
      // });

      const checkboxComponents = employmentRelationship.documents.map((document) => {
        return (
          <Row>
            <Checkbox
              key={document.id}
              value={document.id}
            >
              {document.description}
            </Checkbox>
          </Row>
        );
      });

      return {
        key: employmentRelationship.id,
        label: (
          <Title
            level={5}
            style={{ width: 400, display: "flex" }}
          >
            {employmentRelationship.description}
          </Title>
        ),
        children: (
          <>
            <Row>
              <Text strong>{i18n(dictionary.requiredAdmissionDocuments)}</Text>
              <Tooltip title={i18n(dictionary.message.requiredDocumentInformation)}>
                <InfoCircleOutlined style={{ margin: "0 4px" }} />
              </Tooltip>
            </Row>

            <Checkbox.Group
              key={employmentRelationship.id}
              className="custom-ant-checkbox-group-item"
              // options={checkboxOptions}
              // disabled
              // defaultValue={employmentRelationship.relationshipDocuments.map(
              //   (relationship) => relationship.document.id,
              // )}
              value={checkGroupValues[index]}
              onChange={(values) => {
                const nextValues = checkGroupValues.map((item) => item);
                nextValues[index] = values;
                setCheckGroupValues(nextValues);
              }}
            >
              {checkboxComponents}
            </Checkbox.Group>
          </>
        ),
      };
    },
  );

  async function sendEmail(email: string) {
    AdmissionApi.sendEmail(email)
      .then((res) => {
        Modal.success({
          title: i18n(dictionary.success.ADMISSION_DOCUMENTS_REQUEST_EMAIL_SUCCESS.title),
          content: i18n(dictionary.success.ADMISSION_DOCUMENTS_REQUEST_EMAIL_SUCCESS.message),
          onOk: () => updateDashboard(),
        });
        handleOk();
        Storage.setUserFinishedAdmissionAccess(true);
        update({ customAttributes: { first_access_flow_modal: false } });
        setIsModalOpen(false);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.ADMISSION_DOCUMENTS_REQUEST_EMAIL_ERROR.title),
            message: i18n(dictionary.error.ADMISSION_DOCUMENTS_REQUEST_EMAIL_ERROR.message),
            onOk: () => sendEmail(email),
            onClickLink: () => show(),
          });
        }
        console.error(error);
      });
  }

  async function saveConfig(): Promise<boolean> {
    const config = employmentRelationshipForm.employmentRelationships.map((employmentRelationship, index) => {
      return {
        id: employmentRelationship.id,
        requiredDocumentIds: checkGroupValues[index].map(Number),
      };
    });

    return EmploymentRelationshipDocumentApi.saveConfig(config)
      .then((res) => {
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.SAVE_CONFIG_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.title),
            message: i18n(dictionary.error.SAVE_CONFIG_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.message),
            onOk: () => handleSaveConfig(),
            onClickLink: () => show(),
          });
        }
        throw error;
      });
  }

  async function onFinish(admissionForm: AdmissionForm) {
    AdmissionApi.create(admissionForm)
      .then((res) => {
        IntercomApi.update(INTERCOM_FIELDS.AD_ADDED_EMPLOYEE_ONBOARDING);
        sendEmail(admissionForm.email);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_ADMISSION_ERROR.title),
            message: i18n(dictionary.error.CREATE_ADMISSION_ERROR.message),
            onOk: () => onFinish(admissionForm),
            onClickLink: () => show(),
          });
        }
      });
  }

  const onChangeSwitch = (checked: boolean) => {
    if (checked) {
      form.setFieldsValue(testEmployeeAdmission);
      setEmployerId(testEmployeeAdmission.employerId);
    } else {
      form.resetFields();
      form.setFieldsValue({ onboarding: false });
      setEmployerId("");
    }

    setDisabledForm(checked);
  };

  const handleOk = () => {
    update({ customAttributes: { first_access_flow_modal: false } });
    setIsModalOpen(false);
    setIsPartnersModalOpen(false);
  };

  const handleCancel = () => {
    update({ customAttributes: { first_access_flow_modal: false } });
    setIsModalOpen(false);
    setIsPartnersModalOpen(false);
  };

  const next = () => {
    if (currentStep === 1 && !isPartnersModalOpen) {
      setIsPartnersModalOpen(true);
    } else {
      if (isPartnersModalOpen) {
        setIsPartnersModalOpen(false);
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const steps = [
    {
      title: i18n(dictionary.label.documentation),
      description: i18n(dictionary.documentation_description),
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex", margin: 16 }}
        >
          <Row>
            <Text>
              {i18n(dictionary.step)} {currentStep + 1} / {totalSteps}
            </Text>
          </Row>
          <Row>
            <Title
              level={3}
              style={{ color: "#884EA6" }}
            >
              Configuração de Documentos
            </Title>
          </Row>
          <Row>
            {employmentExpanded.length > 0 && (
              <Collapse
                className="full-size-medium"
                items={collapseItems}
                defaultActiveKey={employmentExpanded}
                collapsible="header"
              />
            )}
          </Row>
          <Row>
            <Form>
              <Form.Item<AdmissionForm>
                name="admissionDeadlineDays"
                label={<Text strong>{i18n(dictionary.label.due_date)}</Text>}
                initialValue={15}
              >
                <InputNumber
                  min={0}
                  addonAfter={i18n(dictionary.label.days)}
                  onChange={(value) => {
                    form.setFieldsValue({ admissionDeadlineDays: value });
                  }}
                />
              </Form.Item>
            </Form>
          </Row>
        </Space>
      ),
    },
    {
      title: i18n(dictionary.label.new_item, {
        context: context.female,
        item: i18n(dictionary.label.admission).toLowerCase(),
      }),
      description: i18n(dictionary.new_admission_description),
      content: (
        <Space
          direction="vertical"
          style={{ display: "flex", margin: 16 }}
        >
          <Row>
            <Text>
              {i18n(dictionary.step)} {currentStep + 1} / {totalSteps}
            </Text>
          </Row>
          <Row>
            <Title
              level={3}
              style={{ color: "#884EA6" }}
            >
              {i18n(dictionary.label.new_item, {
                context: context.female,
                item: i18n(dictionary.label.admission).toLowerCase(),
              })}
            </Title>
          </Row>
          <Row>
            <Tag
              className="custom-tag-mobile"
              color="purple"
              closable
            >
              <Text
                strong
                style={{ color: "#884EA6", fontSize: 12 }}
              >
                Tudo no seu tempo!
              </Text>{" "}
              Você pode optar por inserir dados fictícios neste cadastro.
            </Tag>
          </Row>
          <Form.Item>
            <Form.Item<AdmissionForm>
              name="onboarding"
              noStyle
            >
              <Switch onChange={onChangeSwitch} />
            </Form.Item>
            <span
              className="ant-form-text"
              style={{ marginLeft: 8 }}
            >
              <Text style={{ color: "#5C7CFA" }}>{i18n(dictionary.label.testEmployeeAdmission)}</Text>
            </span>
          </Form.Item>
          <Form
            {...layout}
            name="admissionForm"
            onFinish={onFinish}
            style={{ maxWidth: 600, marginTop: 8 }}
            validateMessages={validateMessages}
            disabled={disabledForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            {/* campos ocultos do formulário */}
            <Form.Item<AdmissionForm>
              name="onboarding"
              style={{ display: "none" }}
              initialValue={false}
            />
            <Form.Item<AdmissionForm>
              name="admissionDeadlineDays"
              style={{ display: "none" }}
              initialValue={15}
            />
            {/* campos do formulário */}
            <Form.Item<AdmissionForm>
              name="employerId"
              label={<Text strong>{capitalizeFirstLetter(i18n(dictionary.entity.employer))}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Select
                placeholder={i18n(dictionary.action.selectItem, {
                  item: i18n(dictionary.entity.employer),
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.title ?? "").toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA?.title ?? "").toLowerCase().localeCompare((optionB?.title ?? "").toLowerCase())
                }
                defaultValue={undefined}
                onChange={(value: string) => {
                  setEmployerId(value);
                  form.setFieldValue("departmentId", undefined);
                }}
              >
                {employers.map((employer) => (
                  <Select.Option
                    key={employer.id}
                    title={employer.shortName ?? employer.fullName}
                    value={employer.id}
                  >
                    {employer.shortName ?? employer.fullName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {employerId && (
              <Form.Item<AdmissionForm>
                name="departmentId"
                label={<Text strong>{i18n(dictionary.label.department)}</Text>}
                rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
              >
                <SelectInsert
                  onInsert={(value) => insertDepartment(value, employerId)}
                  placeholder={i18n(dictionary.action.selectItem, {
                    item: i18n(dictionary.label.department).toLowerCase(),
                  })}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.title ?? "").toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA?.title ?? "").toLowerCase().localeCompare((optionB?.title ?? "").toLowerCase())
                  }
                >
                  {filteredDepartments.map((department) => (
                    <Select.Option
                      key={department.id}
                      title={department.description}
                      value={department.id}
                    >
                      {department.description}
                    </Select.Option>
                  ))}
                </SelectInsert>
              </Form.Item>
            )}
            <Form.Item<AdmissionForm>
              name="employmentRelationshipId"
              label={<Text strong>{capitalizeFirstLetter(i18n(dictionary.entity.employment_relationship))}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Select
                placeholder={i18n(dictionary.action.selectItem, {
                  item: i18n(dictionary.entity.employment_relationship),
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.title ?? "").toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA?.title ?? "").toLowerCase().localeCompare((optionB?.title ?? "").toLowerCase())
                }
              >
                {employmentRelationshipForm.employmentRelationships.map((relationship) => (
                  <Select.Option
                    key={relationship.id}
                    title={relationship.description}
                    value={relationship.id}
                  >
                    {relationship.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item<AdmissionForm>
              name="name"
              label={<Text strong>{i18n(dictionary.label.full_name)}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input placeholder={i18n(dictionary.label.full_name)} />
            </Form.Item>
            <Form.Item<AdmissionForm>
              name="email"
              label={<Text strong>{i18n(dictionary.label.personalEmail)}</Text>}
              rules={[{ required: true, type: "email" }]}
            >
              <Input placeholder={i18n(dictionary.label.personalEmail)} />
            </Form.Item>
            <Form.Item<AdmissionForm>
              name="jobOccupation"
              label={<Text strong>{i18n(dictionary.label.occupation)}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              {/* <Select
                placeholder={i18n(dictionary.action.selectItem, {
                  item: i18n(dictionary.label.occupation).toLowerCase(),
                })}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.title ?? "").toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA?.title ?? "").toLowerCase().localeCompare((optionB?.title ?? "").toLowerCase())
                }
              >
                {jobOccupations.map((occupation) => (
                  <Select.Option
                    key={occupation.code}
                    title={occupation.name}
                    value={occupation.code}
                  >
                    {occupation.name}
                  </Select.Option>
                ))}
              </Select> */}
              <Input placeholder={i18n(dictionary.label.occupation)} />
            </Form.Item>
            {/* <Form.Item label={<Text strong>Centro de Custo</Text>}>
              <Select placeholder="Selecione o centro de custo">
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item> */}
            <Form.Item<AdmissionForm>
              name="salary"
              label={<Text strong>{i18n(dictionary.label.salary)}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <NumberFormatBase
                customInput={Input}
                defaultValue="000"
                format={formatToBRL}
              />
            </Form.Item>
            {/* <Form.Item<AdmissionForm>
              name="admissionDate"
              label={<Text strong>{i18n(dictionary.label.admission_date)}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <DatePicker format={[i18n(dictionary.dateFormat)]} />
            </Form.Item> */}
            <Form.Item<AdmissionForm>
              name="workExperiencePeriodInDays"
              label={<Text strong>{i18n(dictionary.label.experience_period)}</Text>}
              initialValue={0}
            >
              <InputNumber
                min={0}
                addonAfter={i18n(dictionary.label.days)}
              />
            </Form.Item>
            <Form.Item<AdmissionForm>
              name="shiftType"
              label={<Text strong>{capitalizeFirstLetter(i18n(dictionary.entity.shift))}</Text>}
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Radio.Group defaultValue="1">
                {shifts.map((shift) => (
                  <Radio
                    key={shift.key}
                    title={shift.name}
                    value={shift.key}
                  >
                    {shift.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item<AdmissionForm>
              name="firstEmployment"
              label={<Text strong>{i18n(dictionary.label.firstEmployment)}</Text>}
              initialValue={true}
            >
              <Radio.Group defaultValue={true}>
                <Radio value={true}> Sim </Radio>
                <Radio value={false}> Não </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Space>
      ),
    },
    // {
    //   title: i18n(dictionary.accountants),
    //   description: i18n(dictionary.accountants_description),
    //   content: (
    //     <Space
    //       direction="vertical"
    //       style={{ display: "flex", margin: 16 }}
    //     >
    //       <Row>
    //         <Text>{i18n(dictionary.step)} {currentStep+1} / {totalSteps}</Text>
    //       </Row>
    //       <Row>
    //         <Title
    //           level={3}
    //           style={{ color: "#884EA6" }}
    //         >
    //           Cadastro de contadores
    //         </Title>
    //       </Row>
    //       <Form
    //         {...layout}
    //         name="nest-messages"
    //         onFinish={onFinish}
    //         style={{ maxWidth: 600, marginTop: 8 }}
    //         validateMessages={validateMessages}
    //         layout="vertical"
    //       >
    //         <Form.Item
    //           name={["accountant", "name"]}
    //           label={<Text strong>Nome do contador</Text>}
    //           rules={[{ required: true }]}
    //         >
    //           <Input placeholder="Nome do contador" />
    //         </Form.Item>
    //         <Form.Item
    //           label={<Text strong>Telefone</Text>}
    //           rules={[{ required: true }]}
    //         >
    //           <Input placeholder="Telefone" />
    //         </Form.Item>
    //         <Form.Item
    //           name={["user", "email"]}
    //           label={<Text strong>E-mail</Text>}
    //           rules={[{ type: "email", required: true }]}
    //         >
    //           <Input placeholder="exemplo@qrpoint.com.br" />
    //         </Form.Item>
    //         <Form.Item
    //           label={
    //             <Text strong>
    //               Responsável por <InfoCircleOutlined />
    //             </Text>
    //           }
    //         >
    //           <Radio.Group defaultValue="1">
    //             <Space direction="vertical">
    //               <Radio value="1"> Admissão </Radio>
    //               <Radio value="2"> Desligamento </Radio>
    //               <Radio value="3"> Férias </Radio>
    //               <Radio value="4"> Folha de Pagamento </Radio>
    //             </Space>
    //           </Radio.Group>
    //         </Form.Item>
    //         <Form.Item>
    //           <Button
    //             type="primary"
    //             htmlType="submit"
    //           >
    //             Cadastrar novo contador
    //           </Button>
    //         </Form.Item>
    //       </Form>
    //     </Space>
    //   ),
    // },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }));

  const contentStyle: React.CSSProperties = {
    lineHeight: "260px",
    // textAlign: "center",
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  async function getEmploymentRelationshipData(): Promise<boolean> {
    return await EmploymentRelationshipApi.fetch()
      .then(async (res: EmploymentRelationship[]) => {
        setEmploymentRelationshipForm({ employmentRelationships: res });
        const checkValues: number[][] = [];
        let checkGroupValue: number[] = [];
        let emplExpanded: number[] = [];
        res.map((rel) => {
          checkGroupValue = [];
          rel.documents.map((doc) => {
            if (doc.isRequired) checkGroupValue.push(doc.id);
          });
          checkValues.push(checkGroupValue);
          emplExpanded.push(rel.id);
        });
        setCheckGroupValues(checkValues);
        setEmploymentExpanded(emplExpanded);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_DOCUMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployersData(): Promise<boolean> {
    return await EmployerApi.fetch()
      .then((res: Employer[]) => {
        setEmployers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getDepartmentsData(): Promise<boolean> {
    return await DepartmentApi.fetch()
      .then((res: Department[]) => {
        setDepartments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.GET_DEPARTMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      let result = await getEmploymentRelationshipData();
      if (result) result = await getEmployersData();
      if (result) result = await getDepartmentsData();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
    setTotalSteps(steps.length);
  }, []);

  return (
    <>
      <Modal
        className="full-size-modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1280}
        closeIcon={false}
        footer={null}
        centered
      >
        <Row className="row-to-col-mobile">
          <Col
            className="full-max-size-mobile"
            span={6}
          >
            <Space
              direction="vertical"
              size="large"
            >
              <Image
                src={LogoCompact}
                alt="Logo"
                preview={false}
                style={{ width: "50%", height: "70%" }}
              />
              <Steps
                current={currentStep}
                items={items}
                direction="vertical"
              />
              <Image
                className="remove-element-mobile"
                src={
                  currentStep === 0
                    ? FirstAccessFlowDocumentation
                    : currentStep === 1
                    ? FirstAccessFlowNewAdmission
                    : FirstAccessFlowPartners
                }
                alt="FirstAccessFlowFooter"
                preview={false}
                style={{ marginTop: 378 }}
              />
            </Space>
          </Col>

          <Col
            className="full-max-size-mobile negative-margin-top-mobile"
            span={18}
          >
            <div style={contentStyle}>{steps[currentStep].content}</div>
            <div
              className="container-button-mobile"
              style={{ marginTop: 24 }}
            >
              {currentStep === 0 && <Button onClick={() => handleCancel()}>Configurar mais tarde</Button>}
              {currentStep > 0 && (
                <Button
                  type="text"
                  onClick={() => prev()}
                >
                  Passo anterior
                </Button>
              )}
              {/* {current > 0 && <Button style={{ margin: "0 8px" }}>Pular etapa</Button>} */}
              {currentStep < steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleSaveConfig()}
                  style={{ margin: "0 8px" }}
                >
                  <RightOutlined />
                  {i18n(dictionary.saveAndNextStep)}
                </Button>
              )}
              {currentStep === steps.length - 1 && (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    form.submit();
                  }}
                  style={{ margin: "0 8px" }}
                >
                  {i18n(dictionary.saveAndSendEmail)}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        open={isPartnersModalOpen}
        title={
          <>
            <AuditOutlined style={{ marginRight: 8 }} /> Deseja realizar o cadastro dos contadores parceiros
          </>
        }
        closeIcon={false}
        centered
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
          >
            Pular etapa
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={next}
          >
            Cadastrar parceiros
          </Button>,
        ]}
      >
        <Text style={{ marginLeft: 28 }}>Você pode realizar este e outros cadastros posteriormente.</Text>
      </Modal>
    </>
  );
}

export default FirstAccessFlowModal;
