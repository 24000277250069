import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { useStores } from "src/hooks";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import { dictionary, i18n, removeHashFromColor } from "src/utils";
import { AnimatedDropdown, Container, NavButton } from "./styles";

const AccountDropdown: React.FC = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>("");
  const { auth } = useStores();
  const { shutdown, boot } = useIntercom();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleDropdownVisibility = (): void => {
    setIsDropdownVisible((oldState) => !oldState);
  };

  const onSignOut = async (): Promise<void> => {
    await auth.logout();
    shutdown();
    boot();
    navigate(Routes.LOGIN);
  };

  useEffect(() => {
    setUserName(Storage.getUserName());
  }, []);

  return (
    <Container onClick={handleDropdownVisibility}>
      <span style={{ fontWeight: "normal" }}>{i18n(dictionary.hello, { item: userName })}</span>
      <img
        src={`https://ui-avatars.com/api/?rounded=true&format=svg&background=${removeHashFromColor(
          "#941C9A",
        )}&color=${removeHashFromColor("#F5F6FA")}&name=${userName.substring(0, 1)}`}
        alt={`User ${userName}`}
      />
      {isDropdownVisible && (
        <AnimatedDropdown>
          <ul>
            <NavButton onClick={onSignOut}>
              <FiLogOut />
              {i18n(dictionary.exit)}
            </NavButton>
          </ul>
        </AnimatedDropdown>
      )}
    </Container>
  );
};

export default AccountDropdown;
