import { ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import { ConfigProviderProps } from "antd/lib/config-provider";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

dayjs.locale("pt-br");

const config: ConfigProviderProps = {
  direction: "ltr",
  locale: ptBR,
};

ConfigProvider.config(config);

export default ConfigProvider;
