export function formatToBRL(value: string): string {
  let formattedValue = value.replace(/^0+(?=\d)/g, "");
  formattedValue = formattedValue.padStart(3, "0");
  formattedValue = formattedValue.replace(/(?=(\d{2})$)/g, ",");
  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return `R$ ${formattedValue}`;
}

export function parseFromBRL(value: string): number {
  return parseFloat(value);
}
