import { create } from 'mobx-persist';
import AuthStore from './auth.store';
import NavigationStore from './navigation.store';
import RolesStore from './roles.store';
import UserStore from './users.store';

class RootStore {
  auth: AuthStore;

  users: UserStore;

  navigation: NavigationStore;

  constructor() {
    this.auth = new AuthStore();
    this.users = new UserStore();
    this.navigation = new NavigationStore();
  }
}

const hydrate = create({
  storage: localStorage,
});
const store = new RootStore();

hydrate('auth', store.auth);
hydrate('users', store.users);

export { UserStore, AuthStore, RolesStore };
export default store;
