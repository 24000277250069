import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { dictionary, i18n } from "src/utils";

interface InfoMessageProps {
  title: string;
  message: string;
  confirm: (values: any) => void;
}

export default function InfoMessage(props: InfoMessageProps) {
  return Modal.confirm({
    title: props.title,
    icon: <ExclamationCircleOutlined />,
    content: <div dangerouslySetInnerHTML={{ __html: props.message }} />,
    okText: i18n(dictionary.action.yes),
    okType: "danger",
    cancelText: i18n(dictionary.action.no),
    onOk: props.confirm,
  });
}
