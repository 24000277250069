import { EyeOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { lowerCase } from "lodash";
import { IntercomApi } from "src/api";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "src/assets/icons";
import { Card, Table } from "src/components";
import { capitalizeFirstLetter, context, dictionary, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";

const { Column } = Table;

type Props = {
  contracts: Contract[];
  onCreateContract(): void;
  onDeleteContract(id: number): void;
  onEditContract(id: number): void;
  onViewContract(id: number): void;
};

function Contract({
  contracts,
  onCreateContract,
  onDeleteContract,
  onEditContract,
  onViewContract,
}: Props): JSX.Element {
  return (
    <Card
      title={capitalizeFirstLetter(i18n(dictionary.entity.contract))}
      actions={
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => onCreateContract()}
        >
          {i18n(dictionary.insert, {
            context: context.male,
            item: lowerCase(i18n(dictionary.entity.contract)),
          })}
        </Button>
      }
    >
      <Table
        scroll={{ x: true }}
        dataSource={contracts}
      >
        <Column<Contract>
          title={capitalizeFirstLetter(i18n(dictionary.entity.contract))}
          dataIndex="description"
          sorter={(a, b) => a.description.localeCompare(b.description)}
        />
        <Column<Contract>
          width={20}
          title={i18n(dictionary.label.action)}
          render={(_, record) => (
            <Space direction="horizontal">
              <Button
                type="default"
                icon={<EyeOutlined />}
                onClick={() => {
                  IntercomApi.update(INTERCOM_FIELDS.AD_VIEW_EDIT_CONTRACT_CLICKED);
                  onViewContract(record.id);
                }}
              >
                {i18n(dictionary.action.view)}
              </Button>
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={() => {
                  IntercomApi.update(INTERCOM_FIELDS.AD_VIEW_EDIT_CONTRACT_CLICKED);
                  onEditContract(record.id);
                }}
              >
                {i18n(dictionary.action.edit)}
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteContract(record.id)}
              >
                {i18n(dictionary.action.exclude)}
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
}

export default Contract;
