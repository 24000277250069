import React, { Dispatch } from "react";
import { SessionAction, SessionState } from "src/actions/session.action";

interface IContextProps {
  sessionState: SessionState;
  sessionDispatch: Dispatch<SessionAction>;
}

const SessionContext = React.createContext({} as IContextProps);

export function useSessionContext() {
  return React.useContext(SessionContext);
}

export default SessionContext;
