import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import {
  AdmissionFlowApi,
  BenefitApi,
  DepartmentApi,
  EmployeeApi,
  EmployerApi,
  EmploymentRelationshipApi,
  IntercomApi,
} from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { jobOccupations } from "src/data";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";
import AdmissionsLimitModal, { AdmissionsLimitModalData } from "../../Dashboard/AdmissionsLimitModal";
import EmployeeLimitReachedModal from "../../Dashboard/EmployeeLimitReachedModal";
import EmployeeCreate from "./EmployeeCreate";

function EmployeeCreateContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(1);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [employmentRelationships, setEmploymentRelationships] = useState<EmploymentRelationship[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [admissionFlows, setAdmissionFlows] = useState<AdmissionFlow[]>([]);
  const { show } = useIntercom();

  const [amountLimitAdmissionModal, setAmountLimitAdmissionModal] = useState<AdmissionsLimitModalData>({
    open: false,
    admissionsLimit: 0,
    isCustomer: false,
    isAccountingCustomer: false,
  });

  const [openEmployeeLimitReachedModal, setOpenEmployeeLimitReachedModal] = useState(false);

  const tabs = [
    {
      index: 1,
      title: "Novo colaborador",
    },
  ];

  const navigate = useNavigate();
  const onGoBack = () => navigate(-1);

  const insertDepartment = (department: string, employerId: string) => {
    DepartmentApi.create({ id: 0, description: department, employerId })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.department),
          }),
        });
        setDepartments([...departments, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.message),
            onOk: () => insertDepartment(department, employerId),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const insertBenefit = (benefit: string, employerId: string) => {
    BenefitApi.create({ id: 0, name: benefit, employerId })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.benefit),
          }),
        });
        setBenefits([...benefits, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.CREATE_BENEFIT_ERROR.message),
            onOk: () => insertBenefit(benefit, employerId),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const onFinish = (employeeForm: Employee): void => {
    EmployeeApi.create(employeeForm)
      .then((res) => {
        IntercomApi.update(INTERCOM_FIELDS.AD_ADDED_EMPLOYEE_SYSTEM);
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.entity.employee),
          }),
        });
        onGoBack();
      })
      .catch((error) => {
        if (error.code === 403 && error.message === "ADMISSIONS_LIMIT_REACHED") {
          if (error.data.isAccountingCustomer) {
            setOpenEmployeeLimitReachedModal(true);
          } else {
            setAmountLimitAdmissionModal({
              open: true,
              admissionsLimit: error.data.admissionsLimit,
              isCustomer: error.data.isCustomer,
              isAccountingCustomer: error.data.isAccountingCustomer,
            });
          }
        } else if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.CREATE_EMPLOYEE_ERROR.message),
            onOk: () => onFinish(employeeForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  async function getDepartmentsData(): Promise<boolean> {
    return await DepartmentApi.fetch()
      .then((res: Department[]) => {
        setDepartments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.GET_DEPARTMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployers(): Promise<boolean> {
    return await EmployerApi.fetch()
      .then((res: Employer[]) => {
        setEmployers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmploymentRelationships(): Promise<boolean> {
    return await EmploymentRelationshipApi.fetch()
      .then((res: EmploymentRelationship[]) => {
        setEmploymentRelationships(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getBenefits(): Promise<boolean> {
    return await BenefitApi.fetch()
      .then((res: Benefit[]) => {
        setBenefits(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.GET_BENEFIT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getAdmissionFlows() {
    return await AdmissionFlowApi.fetchAdmissionFlows()
      .then((res: AdmissionFlow[]) => {
        setAdmissionFlows(res);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_ADMISSION_FLOW_ERROR.title),
            message: i18n(dictionary.error.GET_ADMISSION_FLOW_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getDepartmentsData();
      await getBenefits();
      await getEmployers();
      await getEmploymentRelationships();
      await getAdmissionFlows();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      <EmployeeCreate
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        onGoBack={onGoBack}
        onFinish={onFinish}
        employmentRelationships={employmentRelationships}
        jobOccupationRegisters={jobOccupations}
        employers={employers}
        departments={departments}
        insertDepartment={insertDepartment}
        benefits={benefits}
        insertBenefit={insertBenefit}
        admissionFlows={admissionFlows}
      />
      <EmployeeLimitReachedModal
        open={openEmployeeLimitReachedModal}
        cancel={() => setOpenEmployeeLimitReachedModal(false)}
      />
      <AdmissionsLimitModal
        data={amountLimitAdmissionModal}
        handleCancel={() =>
          setAmountLimitAdmissionModal({
            open: false,
            admissionsLimit: 0,
            isCustomer: false,
            isAccountingCustomer: false,
          })
        }
      />
    </>
  );
}

export default EmployeeCreateContainer;
