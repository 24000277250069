import React from 'react';
import { Outlet } from 'react-router';

import DefaultLayout from '../../_layouts/default';

function Home(): JSX.Element {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
}

export default Home;
