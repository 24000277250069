import React from "react";

import { useStores } from "src/hooks";
import Login from "./Login";

const LoginContainer: React.FC = () => {
  const { auth, navigation } = useStores();
  navigation.setSelectedMenu("1");

  return <Login auth={auth.login} />;
};

export default LoginContainer;
