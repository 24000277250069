import { MailOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Input, Typography, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "src/api";
import CustomLink from "src/components/Text/CustomLink";
import { Routes } from "src/routes";
import { light } from "src/styles/themes";
import { dictionary, i18n, validateEmail } from "src/utils";
import GradientBackground from "../../../components/GradientBackground";

const { Item } = Form;
const { Title } = Typography;

export default function ForgotPassword() {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);

  async function handleSubmit() {
    setSending(true);
    notification.destroy();
    await AuthApi.forgotPassword(email.toLowerCase())
      .then((response) => {
        if (response.success) {
          notification.success({
            message: i18n(dictionary.alright),
            description: i18n(dictionary.pages.forgotPassword.notifications.success),
          });
        } else {
          notification.error({
            message: i18n(dictionary.ops),
            description: t(`pages.forgotPassword.notifications.${response.message}`),
          });
        }
      })
      .catch((_) =>
        notification.error({
          message: i18n(dictionary.ops),
          description: i18n(dictionary.pages.forgotPassword.notifications.generic),
        }),
      );
    setSending(false);
  }

  return (
    <GradientBackground>
      <Card className="forgot-password-card">
        <Form layout="vertical">
          <Title
            style={{
              textAlign: "center",
              marginBottom: 20,
              color: light.colors.brand.primary.light,
            }}
            level={4}
          >
            {i18n(dictionary.pages.forgotPassword.title)}
          </Title>
          <Item label={i18n(dictionary.pages.forgotPassword.fields.email.label)}>
            <Input
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              placeholder={i18n(dictionary.pages.forgotPassword.fields.email.placeholder)}
              prefix={<MailOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginRight: 4 }} />}
            />
          </Item>
          <Item style={{ textAlign: "center" }}>
            <Button
              disabled={!validateEmail(email)}
              type="primary"
              htmlType="submit"
              loading={sending}
              onClick={handleSubmit}
            >
              {sending ? i18n(dictionary.sending) : i18n(dictionary.send)}
            </Button>
          </Item>
          <Flex justify="center">
            <CustomLink
              text={i18n(dictionary.pages.forgotPassword.fields.backToLoginPage)}
              path={Routes.LOGIN}
            />
          </Flex>
        </Form>
      </Card>
    </GradientBackground>
  );
}
