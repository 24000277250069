import { theme } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, RouterProps } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider } from "styled-components";
import "./App.css";
import { AntdProvider, MobxProvider, stores } from "./config";
import "./config/i18n.config";
import { SocketContext, socket } from "./contexts";
import SessionContext from "./contexts/session.context";
import { useSessionReducer } from "./reducers";
import Routes from "./routes";
import GlobalStyle from "./styles/global";
import { light } from "./styles/themes";

if (process.env.NODE_ENV != "production")
  console.info("Variáveis de ambiente carregadas na inicialização:", process.env);

interface CustomRouterProps extends RouterProps {
  history: {
    action: any;
    location: any;
    listen: (
      setState: React.Dispatch<
        React.SetStateAction<{
          action: any;
          location: any;
        }>
      >,
    ) => void;
  };
}

// function NavigateFunctionComponent(props: any) {
//   let navigate = useNavigate();
//   const ran = useRef(false);

//   {
//     /* only run setup once */
//   }
//   if (!ran.current) {
//     SetupInterceptors(navigate);
//     ran.current = true;
//   }
//   return <></>;
// }

const root = createRoot(document.getElementById("root") as HTMLElement);

function App() {
  const [sessionState, sessionDispatch] = useSessionReducer();

  // const CustomRouter = ({ history, ...props }: CustomRouterProps) => {
  //   const [state, setState] = useState({
  //     action: history.action,
  //     location: history.location,
  //   });

  //   useLayoutEffect(() => history.listen(setState), [history]);

  //   return (
  //     <Router
  //       {...props}
  //       location={state.location}
  //       navigationType={state.action}
  //       navigator={history}
  //     />
  //   );
  // };

  const providerState = {
    sessionState,
    sessionDispatch,
  };

  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM || "xos8ywf1"}
      autoBoot
    >
      <SessionContext.Provider value={providerState}>
        <GlobalStyle />
        <Router>
          <SocketContext.Provider value={socket}>
            {/* {<NavigateFunctionComponent />} */}
            <Routes />
          </SocketContext.Provider>
        </Router>
      </SessionContext.Provider>
    </IntercomProvider>
  );
}

root.render(
  <React.StrictMode>
    <MobxProvider {...stores}>
      <AntdProvider
        locale={ptBR}
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: "#884EA6",
            motion: true,
          },
          components: {
            Layout: {
              colorBgHeader: "#FFFFFF",
              colorBgTrigger: "#d1d1d1",
            },
            Typography: {
              colorPrimary: "#884EA6",
            },
            Form: {
              marginLG: 16,
            },
          },
        }}
      >
        <ThemeProvider theme={light}>
          <App />
        </ThemeProvider>
      </AntdProvider>
    </MobxProvider>
  </React.StrictMode>,
);
