export enum CONTRACT_TEXT_FIELD {
  EMPLOYER_NAME = "EMPLOYER_NAME",
  EMPLOYER_IDENTIFICATION = "EMPLOYER_IDENTIFICATION",
  EMPLOYER_ADDRESS_STREET = "EMPLOYER_ADDRESS_STREET",
  EMPLOYER_ADDRESS_NUMBER = "EMPLOYER_ADDRESS_NUMBER",
  EMPLOYER_ADDRESS_DISTRICT = "EMPLOYER_ADDRESS_DISTRICT",
  EMPLOYER_ADDRESS_ZIP_CODE = "EMPLOYER_ADDRESS_ZIP_CODE",
  EMPLOYEE_NAME = "EMPLOYEE_NAME",
  EMPLOYEE_NATIONALITY = "EMPLOYEE_NATIONALITY",
  EMPLOYEE_MARITAL_STATUS = "EMPLOYEE_MARITAL_STATUS",
  EMPLOYEE_OCCUPATION = "EMPLOYEE_OCCUPATION",
  EMPLOYEE_IDENTIFICATION_1 = "EMPLOYEE_IDENTIFICATION_1",
  EMPLOYEE_IDENTIFICATION_2 = "EMPLOYEE_IDENTIFICATION_2",
  EMPLOYEE_ADDRESS_STREET = "EMPLOYEE_ADDRESS_STREET",
  EMPLOYEE_ADMISSION_DAY = "EMPLOYEE_ADMISSION_DAY",
  EMPLOYEE_ADMISSION_FULL_MONTH = "EMPLOYEE_ADMISSION_FULL_MONTH",
  EMPLOYEE_ADMISSION_YEAR = "EMPLOYEE_ADMISSION_YEAR",
  EMPLOYER_CITY = "EMPLOYER_CITY",
  CONTRACT_DATE = "CONTRACT_DATE",
}
