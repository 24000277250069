import styled from 'styled-components';

export const LogsActionList = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;

  li:before {
    content: •;
    margin: 0 16px;
  }
`;
