import { FileDoneOutlined, IdcardOutlined, PlusOutlined } from "@ant-design/icons";
import { FormInstance, Input, InputRef, Select, Switch, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card, Table as DocumentsTable, Form, FormItem, If, TableLogs, Tabs } from "src/components";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";
import { AddDocumentButton } from "./styles";

const { Option } = Select;
const { Column } = DocumentsTable;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = useForm();
  return (
    <Form
      form={form}
      component={false}
      onSubmit={() => {}}
      onCancel={() => {}}
      submitText="SubmitText"
    >
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof RelDocument;
  record: RelDocument;
  handleSave: (record: RelDocument) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.info("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <FormItem
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
        />
      </FormItem>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  id: number;
  isRequired: boolean;
  hasAttachment: boolean;
}

type Props = {
  onGoBack(): void;
  onFinish(values: any): void;
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  employmentRelationship: EmploymentRelationship;
  employmentRelationshipCodes: EmploymentRelationshipCode[];
  contracts: Contract[];
  documents: RelDocument[];
  employmentRelationshipDocuments: EmploymentRelationshipDocument[];
  addDocument(): void;
  removeDocument(key: number): void;
  updateRelationship<PropertyType extends keyof EmploymentRelationship>(
    key: number,
    property: PropertyType,
    value: EmploymentRelationship[PropertyType],
  ): void;
  updateDocument<PropertyType extends keyof EmploymentRelationshipDocument>(
    key: number,
    property: PropertyType,
    value: EmploymentRelationshipDocument[PropertyType],
  ): void;
  editMode: boolean;
  documentLogs: ChangeHistory[];
};

function DocumentationEdit({
  onGoBack,
  onFinish,
  tabs,
  currentTab,
  setCurrentTab,
  employmentRelationship,
  employmentRelationshipCodes,
  contracts,
  documents,
  employmentRelationshipDocuments,
  addDocument,
  removeDocument,
  updateRelationship,
  updateDocument: updateDocumentRelationship,
  editMode,
  documentLogs,
}: Props): JSX.Element {
  const [employmentRelationshipDocumentsState, setEmploymentRelationshipDocumentsState] = useState<
    EmploymentRelationshipDocument[]
  >([]);
  const [form] = useForm();

  useEffect(() => {
    if (employmentRelationship.id !== 0) {
      form.setFieldsValue(employmentRelationship);
      setEmploymentRelationshipDocumentsState(employmentRelationshipDocuments);
    }
  }, [employmentRelationship, employmentRelationshipDocuments]);

  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <Form
          form={form}
          onSubmit={() => {
            onFinish({ ...form.getFieldsValue(), documents: employmentRelationshipDocumentsState });
          }}
          onCancel={onGoBack}
          submitText={
            editMode
              ? i18n(dictionary.action.update_save)
              : i18n(dictionary.action.create_item, {
                  item: i18n(dictionary.entity.relationship),
                })
          }
        >
          <Card
            title={i18n(dictionary.label.documentation_settings)}
            icon={<FileDoneOutlined />}
          >
            <FormItem
              label={i18n(dictionary.label.relationship_description)}
              name="description"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={capitalizeFirstLetter(i18n(dictionary.label.relationType))}
              name="code"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Select>
                {employmentRelationshipCodes.map((item) => (
                  <Option
                    key={item.code}
                    title={`${item.code} – ${item.name}`}
                    value={item.code}
                  >
                    {item.code} – {item.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label={capitalizeFirstLetter(i18n(dictionary.entity.contract))}
              name="contractId"
              rules={[{ required: false }]}
            >
              <Select>
                {contracts.map((contract) => (
                  <Option
                    key={contract.id}
                    value={contract.id}
                  >
                    {contract.description}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Card>

          <Card
            title={i18n(dictionary.label.document_list)}
            icon={<IdcardOutlined />}
          >
            <FormItem
              fullWidth
              rules={[{ required: false }]}
              name="documents"
            >
              <DocumentsTable
                scroll={{ x: true }}
                tableLayout="auto"
                pagination={false}
                dataSource={employmentRelationshipDocuments}
                rowKey={(record) => record.id}
              >
                {/* <Column
                  title={i18n(dictionary.label.name)}
                  dataIndex="description"
                  key="description"
                  render={(description, relationship: EmploymentRelationshipDocument) =>
                    relationship.editable ? (
                      <Input
                        defaultValue={description}
                        onChange={(event) =>
                          updateDocumentRelationship(relationship.id, "description", event.target.value)
                        }
                      />
                    ) : (
                      description
                    )
                  }
                  width="70%"
                /> */}
                <Column
                  title={i18n(dictionary.label.name)}
                  dataIndex="documentId"
                  key="documentId"
                  render={(documentId, relationship: EmploymentRelationshipDocument) => (
                    <Select
                      defaultValue={documentId}
                      onChange={(value) => {
                        setEmploymentRelationshipDocumentsState(
                          employmentRelationshipDocumentsState.map((item) => {
                            if (item.id == relationship.id) return { ...item, documentId: value };
                            else return item;
                          }),
                        );
                      }}
                    >
                      {documents.map((document) => (
                        <Option
                          key={document.id}
                          value={document.id}
                        >
                          {document.description}
                        </Option>
                      ))}
                    </Select>
                  )}
                  width="70%"
                />
                <Column
                  title={i18n(dictionary.label.required)}
                  dataIndex="isRequired"
                  key="isRequired"
                  render={(isRequired, relationship: EmploymentRelationshipDocument) => (
                    <Switch
                      defaultChecked={isRequired}
                      // onChange={(checked) => updateDocumentRelationship(relationship.id, "isRequired", checked)}
                      onChange={(value) => {
                        setEmploymentRelationshipDocumentsState(
                          employmentRelationshipDocumentsState.map((item) => {
                            if (item.id == relationship.id) return { ...item, isRequired: value };
                            else return item;
                          }),
                        );
                      }}
                    />
                  )}
                />
                <Column
                  title={i18n(dictionary.label.attachment)}
                  dataIndex="hasAttachment"
                  key="hasAttachment"
                  render={(hasAttachment, relationship: EmploymentRelationshipDocument) => (
                    <Switch
                      defaultChecked={hasAttachment}
                      // onChange={(checked) => updateDocumentRelationship(relationship.id, "hasAttachment", checked)}
                      onChange={(value) => {
                        setEmploymentRelationshipDocumentsState(
                          employmentRelationshipDocumentsState.map((item) => {
                            if (item.id == relationship.id) return { ...item, hasAttachment: value };
                            else return item;
                          }),
                        );
                      }}
                    />
                  )}
                />
                {/* <Column
                  title={i18n(dictionary.label.due_date)}
                  dataIndex="deadlineDays"
                  key="deadlineDays"
                  render={(deadlineDays, relationship: RelationshipDocumentRecord) => (
                    <InputNumber
                      min={1}
                      defaultValue={deadlineDays}
                      addonAfter={i18n(dictionary.label.days)}
                      onChange={(value) => updateRelationship(relationship.id, "deadlineDays", value)}
                    />
                  )}
                  width={164}
                /> */}
                {/* <Column
                  dataIndex="editable"
                  key="editable"
                  render={(editable, relationship: EmploymentRelationship) => (
                    <Button
                      onClick={() => removeDocument(relationship.id)}
                      disabled={!editable}
                      ghost
                      type="link"
                    >
                      {i18n(dictionary.action.remove)}
                    </Button>
                  )}
                /> */}
              </DocumentsTable>

              <AddDocumentButton
                ghost
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setEmploymentRelationshipDocumentsState([
                    ...employmentRelationshipDocumentsState,
                    {
                      id: -employmentRelationshipDocumentsState.length,
                      documentId: documents[0].id,
                      description:
                        i18n(dictionary.label.document, { count: 1 }) +
                        ` ${employmentRelationshipDocumentsState.length}`,
                      isRequired: true,
                      hasAttachment: true,
                      editable: true,
                    },
                  ]);
                  addDocument();
                }}
              >
                {i18n(dictionary.action.add_document)}
              </AddDocumentButton>
            </FormItem>
          </Card>
        </Form>
      </If>
      <If condition={currentTab === 2}>
        <TableLogs logs={documentLogs} />
      </If>
    </>
  );
}

export default DocumentationEdit;
