import React from "react";
import { Row, RowProps } from "../Row";

export interface ColumnProps extends RowProps {
  children?: React.ReactNode;
}

const ColumnComponent: React.FC<ColumnProps> = (props) => (
  <Row
    flexDirection="column"
    {...props}
  />
);

export default ColumnComponent;
