/**
 * Valida e-mail passado no parâmetro e retorna um boleano informando se é válido ou não
 * @param email E-mail que será validado.
 */
export function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (typeof email === "string") {
    return re.test(email);
  } else {
    return false;
  }
}
