import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import CryptoJS from "crypto-js";
import dayjs from "dayjs";
import { useState } from "react";
import { Storage } from "src/services";

export default function SystemSelector() {
  const [clicked, setClicked] = useState(false);

  function redirect(data: string, system: number) {
    const secretKey = process.env.REACT_APP_KEY_ENCRYPT_URL;
    if (secretKey && data) {
      const encryptedData = CryptoJS.AES.encrypt(data, secretKey);
      const encodedEncryptedData = encodeURIComponent(encryptedData.toString());
      switch (system) {
        case 1:
          return window.location.replace(
            `${process.env.REACT_APP_QRPOINT_ENDPOINT}/fast-login?data=${encodedEncryptedData}`,
          );
        case 2:
          return window.location.replace(`${process.env.REACT_APP_VACATION_ENDPOINT}/login/${encodedEncryptedData}`);
      }
    }
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Controle de ponto",
      onClick: () => redirect(String(Storage.getUserId()), 1),
    },
    {
      key: "2",
      label: "Gestão de férias",
      onClick: () =>
        redirect(JSON.stringify({ userId: Storage.getUserId(), expiration: dayjs().add(5, "minutes") }), 2),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="appstore"
        width="1em"
        height="1em"
        aria-hidden="true"
        style={{
          fontSize: 30,
          transform: clicked ? "rotate(145deg)" : "",
          transition: "transform 0.2s ease-in-out",
          cursor: "pointer",
          border: "1px solid rgb(231, 231, 231)",
          borderRadius: "8px",
          padding: "1px",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 5px",
        }}
        onClick={() => {
          setClicked(!clicked);
        }}
      >
        <defs>
          <linearGradient
            id="gradient"
            x1="0%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop
              offset="0%"
              style={{ stopColor: "#66C790", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#884EA6", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <path
          d="M464 144H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H212V212h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V160c0-8.8-7.2-16-16-16zm-52 268H612V212h200v200zM464 544H160c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H212V612h200v200zm452-268H560c-8.8 0-16 7.2-16 16v304c0 8.8 7.2 16 16 16h304c8.8 0 16-7.2 16-16V560c0-8.8-7.2-16-16-16zm-52 268H612V612h200v200z"
          fill="url(#gradient)"
        />
      </svg>
      <Dropdown
        placement="bottomRight"
        menu={{ items }}
        open={clicked}
      >
        <div
          onClick={() => {
            setClicked(!clicked);
          }}
          style={{
            marginLeft: 10,
            fontWeight: 600,
            fontSize: 16,
            color: "#884EA6",
            cursor: "pointer",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <label
            className="system-selector-text"
            style={{
              border: "1px solid #d9d9d9",
              padding: "3px 10px",
              borderRadius: "17px",
              cursor: "pointer",
              userSelect: "none",
              boxShadow: "1px 2px 5px #0000003b",
              margin: "5px",
              paddingBottom: "5px",
            }}
          >
            Admissão digital
          </label>
        </div>
      </Dropdown>
    </div>
  );
}
