import { Button, Space } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "src/assets/icons";
import { Card, Table } from "src/components";
import { context, dictionary, i18n } from "src/utils";

const { Column } = Table;

type Props = {
  employers: Employer[];
  onCreateEmployer(): void;
  onDeleteEmployer(id: string): void;
  onUpdateEmployer(id: string): void;
};

function Employer({ employers, onCreateEmployer, onDeleteEmployer, onUpdateEmployer }: Props): JSX.Element {
  return (
    <Card
      title={i18n(dictionary.label.list_title, {
        item: i18n(dictionary.entity.employer, { count: 2 }),
      })}
      icon={false}
      actions={
        <Button
          className="full-size-mobile"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => onCreateEmployer()}
        >
          {i18n(dictionary.action.register_new, {
            context: context.male,
            item: i18n(dictionary.entity.employer),
          })}
        </Button>
      }
    >
      <Table
        scroll={{ x: true }}
        dataSource={employers}
      >
        <Column<Employer>
          key="fullName"
          dataIndex="fullName"
          title={i18n(dictionary.label.company_name)}
          sorter={(a, b) => a.fullName.localeCompare(b.fullName)}
        />
        <Column<Employer>
          key="shortName"
          dataIndex="shortName"
          title={i18n(dictionary.label.trading_name)}
          sorter={(a, b) => (a.shortName ? a.shortName?.localeCompare(String(b.shortName)) : 0)}
        />
        <Column<Employer>
          key="taxId"
          dataIndex="taxId"
          title={i18n(dictionary.label.cnpj)}
          sorter={(a, b) => a.taxId.localeCompare(b.taxId)}
        />
        <Column<Employer>
          key="action"
          title={i18n(dictionary.label.action)}
          width={20}
          render={(_, record) => (
            <Space direction="horizontal">
              <Button
                type="default"
                icon={<EditOutlined />}
                onClick={() => onUpdateEmployer(record.id)}
              >
                {i18n(dictionary.action.edit)}
              </Button>
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => onDeleteEmployer(record.id)}
              >
                {i18n(dictionary.action.delete)}
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
}

export default Employer;
