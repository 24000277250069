import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export async function fetchAdmissionFlows(): Promise<AdmissionFlow[]> {
  try {
    const { data } = await request.get("/admission-flow");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}

export async function createAdmissionFlow(admissionFlow: AdmissionFlowForm): Promise<boolean> {
  try {
    const { status } = await request.post("/admission-flow", { ...admissionFlow });
    return status === 201;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      return false;
    }
  }
}

export async function findByIdAdmissionFlow(id: string) {
  try {
    const { data } = await request.get(`/admission-flow/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}

export async function findByEmployeeId(employeeId: string) {
  try {
    const { data } = await request.get(`/admission-flow/find-by-employee-id/${employeeId}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}

export async function updateAdmissionFlow(id: string, admissionFlow: AdmissionFlowForm): Promise<boolean> {
  try {
    const { data } = await request.put(`/admission-flow/${id}`, { ...admissionFlow });
    return !!data.success;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      return false;
    }
  }
}

export async function deleteAdmissionFlow(id: string): Promise<boolean> {
  try {
    const { data } = await request.delete(`/admission-flow/${id}`);
    return !!data.success;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      return false;
    }
  }
}
