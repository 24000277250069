import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export interface EmploymentRelationshipConfig {
  id: number;
  requiredDocumentIds: number[];
}

export const saveConfig = async (config: EmploymentRelationshipConfig[]) => {
  try {
    const { data } = await request.post(`/employment-relationship-documents/save-config`, { config });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
