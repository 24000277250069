import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<Reminder[]> => {
  try {
    const { data } = await request.get("/reminders");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: Reminder) => {
  try {
    const { data } = await request.put(`/reminders/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Reminder) => {
  try {
    const { data } = await request.post(`/reminders`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/reminders/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
