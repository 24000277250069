import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<EmploymentRelationship[]> => {
  try {
    const { data } = await request.get(`/employment-relationships`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<EmploymentRelationship> => {
  try {
    const { data } = await request.get(`/employment-relationships/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: EmploymentRelationship) => {
  try {
    const { data } = await request.put(`/employment-relationships/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: EmploymentRelationship) => {
  try {
    const { data } = await request.post(`/employment-relationships`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/employment-relationships/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
