import {
  ArrowRightOutlined,
  AuditOutlined,
  ClearOutlined,
  EditOutlined,
  IdcardOutlined,
  PlusOutlined,
  SaveOutlined,
  SmileOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { Card as AntCard, Button, Dropdown, Flex, Form, Input, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createAdmissionFlow, findByIdAdmissionFlow, updateAdmissionFlow } from "src/api/auth/admission-flow.api";
import { Card, If, Tabs } from "src/components";
import { Routes } from "src/routes/routing";
import { dictionary, i18n } from "src/utils";
import EditAdmissionFlowStep from "./EditAdmissionFlowStep";
import EditAdmissionFlowVisualSettings from "./EditAdmissionFlowVisualSettings";

const { Text, Title } = Typography;

const EMAIL_BODY_DEFAULT_TEXTS = [
  {
    stepId: 1,
    content: `[{"type":"paragraph","children":[{"text":"Prezado(a) "},{"text":"<NOME_COLABORADOR>","field":false,"bold":true},{"text":","}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Gostaríamos de solicitar gentilmente que nos envie os documentos necessários para completar o seu processo de admissão o mais breve possível."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Por favor, envie os documentos até a data limite definida, para que possamos dar continuidade ao seu processo de admissão sem nenhum atraso."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Atenciosamente,"}]},{"type":"paragraph","children":[{"text":"<NOME_EMPRESA>","field":false,"bold":true}]}]`,
  },
  {
    stepId: 2,
    content: `[{"type":"paragraph","children":[{"text":"Prezado(a) "},{"text":"<NOME_COLABORADOR>","field":false,"bold":true},{"text":","}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Gostaríamos de informar que o seu contrato de trabalho está disponível para download."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Para acessar o contrato, por favor, clique no botão abaixo:"}]}]`,
  },
  {
    stepId: 3,
    content: `[{"type":"paragraph","children":[{"text":"Prezado(a) "},{"text":"<NOME_COLABORADOR>","field":false,"bold":true},{"text":","}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"É com grande prazer que damos as boas-vindas à "},{"text":"<NOME_EMPRESA>","field":false,"bold":true},{"text":"! Estamos muito felizes em tê-lo(a) conosco."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"A sua admissão foi concluída com sucesso, e estamos ansiosos para começar esta jornada juntos. Acreditamos que suas habilidades e experiências serão uma adição valiosa ao nosso time."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Para ajudá-lo(a) a se integrar rapidamente, estamos preparando todas as informações e recursos necessários para o seu primeiro dia. Se houver algo específico que você gostaria de saber ou se precisar de qualquer assistência adicional, não hesite em entrar em contato conosco."}]},{"type":"paragraph","children":[{"text":""}]},{"type":"paragraph","children":[{"text":"Mais uma vez, seja muito bem-vindo(a) à "},{"text":"<NOME_EMPRESA>","field":false,"bold":true},{"text":". Estamos empolgados com o que o futuro reserva para nós!"}]}]`,
  },
];

export interface AdmissionFlowStepData {
  id: number;
  index: number;
  emailSubject: string;
  emailBody: string;
  pagePhrase: string;
  pageDescription: string;
}

const initialAdmissionFlowStepData: AdmissionFlowStepData = {
  id: 0,
  index: -1,
  emailSubject: "",
  emailBody: JSON.stringify([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]),
  pagePhrase: "",
  pageDescription: "",
};

export default function AdmissionFlowCreateUpdate() {
  const navigate = useNavigate();

  const params = useParams();

  const [admissionFlowId, setAdmissionFlowId] = useState("");

  const [currentTab, setCurrentTab] = useState(1);

  // Opções do menu
  const [items, setItems] = useState([
    {
      key: "1",
      label: i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles.t1),
      onClick: () => createStep(1),
      disabled: false,
      icon: <IdcardOutlined />,
    },
    {
      key: "2",
      label: i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles.t2),
      onClick: () => createStep(2),
      disabled: false,
      icon: <AuditOutlined />,
    },
    {
      key: "3",
      label: i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles.t3),
      onClick: () => createStep(3),
      disabled: false,
      icon: <SmileOutlined />,
    },
  ]);

  // Nome do fluxo
  const [flowName, setFlowName] = useState("");

  // Passos do fluxo
  const [steps, setSteps] = useState<AdmissionFlowStepData[]>([]);

  // Configurações visuais do fluxo
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [primaryColor, setPrimaryColor] = useState<string>("#884EA6");
  const [secondaryColor, setSecondaryColor] = useState<string>("#66C790");

  // Configurações dos passos do fluxo
  const [selectedStep, setSelectedStep] = useState<AdmissionFlowStepData>(initialAdmissionFlowStepData);

  // Tela de edição dos passos do fluxo
  const [editStep, setEditStep] = useState(false);
  // Tela de edição das configurações visuais do fluxo
  const [editVisualSettings, setEditVisualSettings] = useState(false);

  // Controla carregamento do botão de ação (criação e atualização do fluxo)
  const [loadingAction, setLoadingAction] = useState(false);

  // Controla carregamento dos dados quando for edição de fluxo de admissão
  const [loading, setLoading] = useState(false);

  /**
   * Cria o card do passo e desabilita a opção já utilizada
   * @param { number } step Identificador único do passo
   */
  function createStep(step: number) {
    setSteps((prevSteps) => [
      ...prevSteps,
      {
        id: step,
        index: prevSteps.length,
        emailSubject: i18n(
          dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[
            step === 1 ? "t1" : step === 2 ? "t2" : "t3"
          ],
        ),
        emailBody:
          EMAIL_BODY_DEFAULT_TEXTS.find(({ stepId }) => stepId === step)?.content ||
          JSON.stringify([
            {
              type: "paragraph",
              children: [{ text: "" }],
            },
          ]),
        pagePhrase:
          step !== 3 ? i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.phrase.defaultValue) : "",
        pageDescription:
          step !== 3 ? i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.description.defaultValue) : "",
      },
    ]);
    setItems((prevItems) => {
      const targetItemIndex = prevItems.findIndex(({ key }) => Number(key) === step);
      prevItems[targetItemIndex].disabled = true;
      return [...prevItems];
    });
  }

  /**
   * Limpa o array de passos e habilita todas as opções
   */
  function clearSteps() {
    setSteps([]);
    setSelectedStep(initialAdmissionFlowStepData);
    setItems((prevItems) => prevItems.map((item) => ({ ...item, disabled: false })));
    setImageUrl(undefined);
    setPrimaryColor("#884EA6");
    setSecondaryColor("#66C790");
    setFlowName("");
    setLoadingLogo(false);
  }

  async function create() {
    setLoadingAction(true);
    message.destroy();
    const response = await createAdmissionFlow({
      name: flowName,
      logo: imageUrl || null,
      primaryColor,
      secondaryColor,
      flowSteps: steps.map((element) => ({ stepId: element.id, ...element })),
    });
    if (response) {
      message.success(i18n(dictionary.pages.admissionFlowCreate.tabs.t1.notifications.create.success));
      navigate(Routes.ADMISSION_FLOW);
    } else {
      message.error(i18n(dictionary.pages.admissionFlowCreate.tabs.t1.notifications.create.error));
    }
    setLoadingAction(false);
  }

  async function update() {
    setLoadingAction(true);
    message.destroy();
    const response = await updateAdmissionFlow(admissionFlowId, {
      name: flowName,
      logo: imageUrl || null,
      primaryColor,
      secondaryColor,
      flowSteps: steps.map((element) => ({ stepId: element.id, ...element })),
    });
    if (response) {
      message.success(i18n(dictionary.pages.admissionFlowCreate.tabs.t1.notifications.update.success));
    } else {
      message.error(i18n(dictionary.pages.admissionFlowCreate.tabs.t1.notifications.update.error));
    }
    setLoadingAction(false);
  }

  async function getAdmissionFlowById() {
    setLoading(true);
    if (params && params.id) {
      setAdmissionFlowId(params.id);
      const data = await findByIdAdmissionFlow(params.id);
      if (data) {
        setImageUrl(data.logo);
        setPrimaryColor(data.primaryColor);
        setSecondaryColor(data.secondaryColor);
        setFlowName(data.name);
        const tempSteps: AdmissionFlowStepData[] = [];
        for (const element of data.flowSteps) {
          tempSteps.push({
            ...element,
            id: element.stepId,
          });
          setItems((prevItems) => {
            const targetItemIndex = prevItems.findIndex(({ key }) => Number(key) === element.stepId);
            prevItems[targetItemIndex].disabled = true;
            return [...prevItems];
          });
        }
        setSteps([...tempSteps]);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getAdmissionFlowById();
  }, []);

  useEffect(() => {
    if (selectedStep.id > 0) setEditStep(true);
  }, [selectedStep]);

  return (
    <>
      <Tabs
        data={[
          {
            index: 1,
            title: i18n(dictionary.pages.admissionFlowCreate.tabs.t1.titles[!!admissionFlowId ? "edit" : "new"]),
          },
        ]}
        setCurrentTab={setCurrentTab}
      />
      <Spin spinning={loading}>
        <If condition={currentTab === 1}>
          {/* Configurações visuais do fluxo */}
          <EditAdmissionFlowVisualSettings
            open={editVisualSettings}
            close={() => setEditVisualSettings(false)}
            loadingLogo={loadingLogo}
            setLoadingLogo={setLoadingLogo}
            imageUrl={imageUrl}
            setImageUrl={setImageUrl}
            primaryColor={primaryColor}
            setPrimaryColor={setPrimaryColor}
            secondaryColor={secondaryColor}
            setSecondaryColor={setSecondaryColor}
          />
          {/* Configurações dos passos do fluxo */}
          <EditAdmissionFlowStep
            open={editStep}
            close={() => {
              setSelectedStep(initialAdmissionFlowStepData);
              setEditStep(false);
            }}
            data={selectedStep}
            setData={setSelectedStep}
            setSteps={setSteps}
            imageUrl={imageUrl}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
          <Card>
            <Flex
              style={{ width: "100%" }}
              vertical
            >
              <Form
                layout="vertical"
                style={{ marginBottom: 5 }}
              >
                <Form.Item
                  label="Nome do fluxo"
                  required
                >
                  <Input
                    maxLength={100}
                    onChange={({ target }) => setFlowName(target.value)}
                    value={flowName}
                  />
                </Form.Item>
              </Form>
              <Flex
                wrap
                align="center"
                gap={10}
                style={{ marginBottom: 8 }}
              >
                <AntCard
                  title={
                    <Title
                      style={{ margin: 0, color: "#884ea6", textAlign: "center", width: "100%" }}
                      level={5}
                    >
                      <ToolOutlined />
                      &nbsp;
                      {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.settings.title)}
                    </Title>
                  }
                >
                  <Flex
                    style={{ width: "100%" }}
                    justify="center"
                  >
                    <Button
                      icon={<EditOutlined style={{ color: "#884ea6" }} />}
                      onClick={() => setEditVisualSettings(true)}
                    >
                      {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.settings.editButton)}
                    </Button>
                  </Flex>
                </AntCard>
                {steps.map((element, index) => (
                  <>
                    <ArrowRightOutlined style={{ color: "#884ea6" }} />
                    <AntCard
                      title={
                        <Title
                          style={{ margin: 0, color: "#884ea6", textAlign: "center", width: "100%" }}
                          level={5}
                        >
                          {element.id === 1 ? (
                            <IdcardOutlined />
                          ) : element.id === 2 ? (
                            <AuditOutlined />
                          ) : (
                            <SmileOutlined />
                          )}
                          &nbsp;
                          {i18n(
                            dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[
                              element.id === 1 ? "t1" : element.id === 2 ? "t2" : "t3"
                            ],
                          )}
                        </Title>
                      }
                      key={index}
                    >
                      <Flex
                        style={{ width: "100%" }}
                        align="center"
                        justify="center"
                      >
                        <Text
                          style={{ cursor: "pointer", color: "#884ea6", textDecoration: "underline" }}
                          onClick={() => {
                            setSelectedStep(element);
                          }}
                        >
                          {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.content)}
                        </Text>
                      </Flex>
                    </AntCard>
                  </>
                ))}
                {!items.every(({ disabled }) => disabled) && (
                  <>
                    <ArrowRightOutlined style={{ color: "#884ea6" }} />
                    <Flex
                      vertical
                      align="center"
                      justify="center"
                      gap={3}
                    >
                      <Dropdown menu={{ items }}>
                        <Button icon={<PlusOutlined style={{ color: "#884ea6" }} />}>
                          {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.cards.newStep)}
                        </Button>
                      </Dropdown>
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>
          </Card>
          <Flex gap={10}>
            <Button
              icon={<SaveOutlined />}
              type="primary"
              onClick={!!admissionFlowId ? update : create}
              loading={loadingAction}
              disabled={!flowName.length || !steps.length}
            >
              {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.buttons[!!admissionFlowId ? "edit" : "register"])}
            </Button>
            <Button
              icon={<ClearOutlined />}
              onClick={clearSteps}
              disabled={!steps.length}
            >
              {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.buttons.clear)}
            </Button>
            <Button onClick={() => navigate(Routes.ADMISSION_FLOW)}>
              {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.buttons.cancel)}
            </Button>
          </Flex>
        </If>
      </Spin>
    </>
  );
}
