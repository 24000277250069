import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { EmploymentRelationshipApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import useFormCrud from "src/hooks/useFormCrud";
import { Routes } from "src/routes";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import Documentation from "./Documentation";

function DocumentationContainer(): JSX.Element {
  const [employmentRelationships, setEmploymentRelationships] = useState<EmploymentRelationship[]>([]);
  const { show } = useIntercom();
  const navigate = useNavigate();
  const { handleDelete } = useFormCrud();

  const onDeleteDocumentation = (id: number): void => {
    const onFinishDeleteDocumentation = (): void => {
      EmploymentRelationshipApi.destroy(id)
        .then((res) => {
          const updatedEmployerRelationshipList: EmploymentRelationship[] = employmentRelationships.filter(
            (relationship) => relationship.id !== id,
          );
          setEmploymentRelationships(updatedEmployerRelationshipList);
          message.success({
            content: i18n(dictionary.prompt.deleted_success, {
              context: context.male,
              item: i18n(dictionary.entity.employment_relationship),
            }),
          });
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.DELETE_EMPLOYMENT_RELATIONSHIP_ERROR.title),
              message: i18n(dictionary.error.DELETE_EMPLOYMENT_RELATIONSHIP_ERROR.message),
              onOk: () => onFinishDeleteDocumentation(),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    };

    handleDelete(
      i18n(dictionary.prompt.delete_confirm, {
        context: context.male,
        item: i18n(dictionary.entity.relationship),
      }),
      i18n(dictionary.prompt.action_cant_be_undone),
      onFinishDeleteDocumentation,
    );
  };

  const onCreateDocumentation = () => navigate(Routes.DOCUMENTATION_CREATE);

  const onEditDocumentation = (id: number): void => navigate(`${Routes.DOCUMENTATION_EDIT}?id=${id}`);

  async function getEmploymentRelationships(): Promise<boolean> {
    return await EmploymentRelationshipApi.fetch()
      .then((res: EmploymentRelationship[]) => {
        setEmploymentRelationships(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmploymentRelationships();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <Documentation
      employmentRelationships={employmentRelationships}
      onCreateDocumentation={onCreateDocumentation}
      onDeleteDocumentation={onDeleteDocumentation}
      onEditDocumentation={onEditDocumentation}
    />
  );
}

export default DocumentationContainer;
