import { Drawer, Flex, Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import RichTextEditorSlate from "src/components/RichTextEditorSlate";
import { dictionary, i18n } from "src/utils";
import { EMAIL_BODY_TEXT_FIELD } from "src/utils/enums/email-body.enum";
import { AdmissionFlowStepData } from ".";
import PreviewSending from "../../Settings/PreviewSending";

const { Title, Text } = Typography;
const { Item } = Form;
const { TextArea } = Input;

const fieldList: EMAIL_BODY_TEXT_FIELD[] = [EMAIL_BODY_TEXT_FIELD.EMPLOYER_NAME, EMAIL_BODY_TEXT_FIELD.EMPLOYEE_NAME];

interface EditAdmissionFlowStepProps {
  open: boolean;
  close: () => void;
  data: AdmissionFlowStepData;
  setData: React.Dispatch<React.SetStateAction<AdmissionFlowStepData>>;
  setSteps: React.Dispatch<React.SetStateAction<AdmissionFlowStepData[]>>;
  imageUrl?: string;
  primaryColor: string;
  secondaryColor: string;
}

export default function EditAdmissionFlowStep({
  open,
  close,
  data,
  setData,
  setSteps,
  primaryColor,
  secondaryColor,
  imageUrl,
}: EditAdmissionFlowStepProps) {
  const [forceRenderKey, setForceRenderKey] = useState(0);

  function handleClose() {
    setSteps((prevSteps) => {
      let tempSteps = [...prevSteps];
      tempSteps[data.index] = { ...data };
      return tempSteps;
    });
    close();
  }

  useEffect(() => {
    // Força renderização do componente RichTextEditorSlate
    if (open) setForceRenderKey((prevKey) => prevKey + 1);
  }, [open]);

  return (
    <Drawer
      width={600}
      open={open}
      onClose={handleClose}
      title={
        <Flex
          align="center"
          justify="center"
          gap={10}
        >
          <Text>
            {i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.label)}&nbsp;
            {Number(data.index + 1)}
          </Text>
          <Text type="secondary">{"➝"}</Text>
          <Title
            style={{ margin: 0, color: "#884ea6" }}
            level={5}
          >
            {i18n(
              dictionary.pages.admissionFlowCreate.tabs.t1.cards.others.titles[
                data.id === 1 ? "t1" : data.id === 2 ? "t2" : "t3"
              ],
            )}
          </Title>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.subject)}>
          <Input
            maxLength={50}
            onChange={({ target }) => setData((prevData) => ({ ...prevData, emailSubject: target.value }))}
            value={data.emailSubject}
          />
        </Item>
        <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.text)}>
          <RichTextEditorSlate
            key={forceRenderKey}
            content={JSON.parse(data.emailBody)}
            fieldList={fieldList}
            onChange={(value) =>
              setData((prevData) => ({
                ...prevData,
                emailBody: JSON.stringify(value),
              }))
            }
            placeHolder={""}
            selectWithMarginTop
          />
        </Item>
        {data.id !== 3 && (
          <>
            <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.phrase.label)}>
              <Input
                maxLength={255}
                onChange={({ target }) => setData((prevData) => ({ ...prevData, pagePhrase: target.value }))}
                value={data.pagePhrase}
              />
            </Item>
            <Item label={i18n(dictionary.pages.admissionFlowCreate.tabs.t1.step.fields.description.label)}>
              <TextArea
                style={{ resize: "none", height: 62 }}
                maxLength={100}
                onChange={({ target }) => setData((prevData) => ({ ...prevData, pageDescription: target.value }))}
                value={data.pageDescription}
              />
            </Item>
            <div style={{ border: "1px solid #d9d9d9", marginTop: 20 }}>
              <PreviewSending
                logoCompany={imageUrl}
                principalColor={primaryColor}
                secondaryColor={secondaryColor}
                frase={data.pagePhrase}
                description={data.pageDescription}
                optionPreview="medium"
              />
            </div>
          </>
        )}
      </Form>
    </Drawer>
  );
}
