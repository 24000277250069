import React from 'react';
import { FormItemProps } from 'antd';
import { FormItem } from './styles';

type Props = FormItemProps & {
  fullWidth?: boolean | undefined;
};

export default function Item(props: Props): React.ReactElement {
  const { children } = props;
  return <FormItem {...props}>{children}</FormItem>;
}
