import { BellOutlined, DeleteFilled, SyncOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Dropdown, List } from "antd";
import lodash, { remove } from "lodash";
import { LowSync } from "lowdb";
import { LocalStorage } from "lowdb/browser";
import { useEffect, useState } from "react";
import { NotificationState, removeNotification } from "src/actions/session.action";
import { REACT_APP_NAME } from "src/constants/env.constant";
import { useSessionContext } from "src/contexts/session.context";
import { Storage } from "src/services";
import { dictionary, i18n } from "src/utils";

export class LowSyncWithLodash<T> extends LowSync<T> {
  chain: lodash.ExpChain<this["data"]> = lodash.chain(this).get("data");
}

export type Data = {
  notifications: NotificationState[];
};

export function NotificationsList(props: any) {
  const [bgColor, setBgColor] = useState<string>("");
  const { sessionState, sessionDispatch } = useSessionContext();
  // const [sessionState, sessionDispatch] = useSessionReducer();
  const [userId, setUserId] = useState<number>(0);

  const getUserId = async () => setUserId(Storage.getUserId());

  useEffect(() => {
    initComponent(props);
    getUserId();
  }, []);

  function initComponent(props: any) {
    if (props.session && props.session.notificationBadge > 0) {
      document.title = `(${props.session.notificationBadge}) QRPoint`;
      setBgColor("#FFF");
    } else {
      document.title = REACT_APP_NAME;
      setBgColor("#cccccc");
    }
  }

  function deleteAllNotifications() {
    const adapter = new LocalStorage<Data>("qrpoint-notifications");
    const defaultData: Data = {
      notifications: [],
    };
    const db = new LowSyncWithLodash(adapter, defaultData);
    db.read();
    const notificationCollection = db.data.notifications;
    for (let notf of notificationCollection) {
      sessionDispatch(removeNotification(notf.id));
    }

    remove(notificationCollection, (notification) => notification.userId === userId);

    db.data.notifications = notificationCollection;
    db.write();
  }

  const DropdownRender = () => (
    <Card
      title="Notificações"
      size="small"
      bodyStyle={{ padding: "8px" }}
      actions={
        sessionState.notificationList.length > 0
          ? [
              <Button
                type="link"
                size="small"
                danger
                onClick={deleteAllNotifications}
                icon={<DeleteFilled />}
              >
                Limpar notificações
              </Button>,
            ]
          : undefined
      }
    >
      <List
        itemLayout="vertical"
        style={{
          minWidth: "100px",
          maxWidth: "620px",
          maxHeight: "360px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        locale={{
          emptyText: i18n(dictionary.emptyNotification),
        }}
        dataSource={sessionState.notificationList}
        renderItem={(item) => (
          <List.Item style={{ padding: "10px", minWidth: "300px" }}>
            <List.Item.Meta
              title={item.link ? <a href={item.link}>{item.title}</a> : item.title}
              description={item.link ? <a href={item.link}>{item.content}</a> : item.content}
            />
          </List.Item>
        )}
      />
    </Card>
  );

  const { loadingNotifications, notificationList } = sessionState;

  return (
    <Dropdown
      trigger={["hover"]}
      placement="bottomRight"
      menu={{ items: [{ key: "1", label: <DropdownRender /> }] }}
    >
      <Badge
        offset={[-2, 7]}
        count={loadingNotifications ? 0 : notificationList.length}
      >
        <div>
          <Badge
            offset={[-2, 7]}
            style={{
              display: loadingNotifications ? "block" : "none",
              padding: 3,
              backgroundColor: "white",
              borderRadius: "50%",
              color: "#4D4D4D",
            }}
            count={loadingNotifications && <SyncOutlined spin />}
          >
            {/* <Avatar
              className="custom-notication-avatar"
              size="large"
              icon={<BellOutlined />}
              style={{
                ...props.style,
                background: bgColor,
                color: "#7262b0",
              }}
            /> */}
            <BellOutlined />
          </Badge>
        </div>
      </Badge>
    </Dropdown>
  );
}
