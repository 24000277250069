export enum SessionActionKind {
  SET_SESSION = "SET_SESSION",
}

export enum NotificationActionKind {
  ADD_NOTIFICATION = "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION",
  LOADING_NOTIFICATION = "LOADING_NOTIFICATION",
}

export interface SessionAction {
  type: SessionActionKind | NotificationActionKind;
  payload?: SessionState;
  notification?: NotificationState;
  notificationId?: string;
  notificationLoading?: boolean;
}

// An interface for our actions
interface NotificationAction {
  type: NotificationActionKind;
  payload: NotificationState;
}

export interface NotificationState {
  id: string;
  title: string;
  userId: number;
  expires?: number;
  content: string;
  link: string;
}

export interface SessionState {
  auth: boolean;
  key: string;
  userName: string;
  userNameTopBar: string;
  email: string;
  notificationBadge: number;
  notificationList: NotificationState[];
  loadingNotifications: boolean;
  //código do usuário no sistema
  userId: number;
  //plano da empresa de acordo com a quantidade de funcionários
  planId: number;
  //funcionalidades extras habilitadas para a empresa
  functionalities: any;
  //informações do perfil do usuário
  userProfile: {
    type: string;
    config: string;
  };
  employeeCount: number;
  companyName: string;
  cnpj: string;
  phone: string;
  type: number;
  freeTrial: boolean;
  freeTrialDaysLeft: number;
  blockedAccess: boolean;
  inActivation: boolean;
  token: string;
  //variável de sessão do usuário que armazena os novos recursos que houve alguma interação
  newFeaturesInteracted: any[];
}

export const setSession = (payload: SessionState): SessionAction => ({
  type: SessionActionKind.SET_SESSION,
  payload,
});

export const addNotification = (notification: NotificationState): SessionAction => ({
  type: NotificationActionKind.ADD_NOTIFICATION,
  notification: notification,
});

export const removeNotification = (id: string): SessionAction => ({
  type: NotificationActionKind.REMOVE_NOTIFICATION,
  notificationId: id,
});

export const loadingNotification = (value: boolean): SessionAction => ({
  type: NotificationActionKind.LOADING_NOTIFICATION,
  notificationLoading: value,
});
