import { action, observable } from "mobx";
import { persist } from "mobx-persist";
import { UserApi } from "src/api";

export default class UserStore {
  @persist("object")
  @observable
  users: User[] = [];

  @action
  fetchUser = async (): Promise<void> => {
    const users = await UserApi.fetchUser();
    this.users = users;
  };

  @action
  fetchById = async (id: number): Promise<User> => {
    const user = await UserApi.fetchById(id);
    return user;
  };

  @action
  createUser = async (data: User): Promise<void> => {
    await UserApi.createUser(data);
  };

  @action
  updateUser = async (data: User, id: number): Promise<void> => {
    await UserApi.updateUser(id, data);
  };

  @action
  updateUnity = async (data: User, id: number): Promise<void> => {
    await UserApi.updateUnity(data, id);
    await this.fetchById(id);
  };

  @action
  updateRole = async (data: User, id: number): Promise<void> => {
    await UserApi.updateRole(data, id);
    await this.fetchById(id);
  };
}
