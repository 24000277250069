import React, { useState } from "react";

import { RoutingContextProvider } from "../../../contexts";
import Home from "./Home";

const HomeContainer: React.FC = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  return (
    <RoutingContextProvider
      value={{
        title,
        setTitle,
        subtitle,
        setSubtitle,
      }}
    >
      <Home />
    </RoutingContextProvider>
  );
};
export default HomeContainer;
