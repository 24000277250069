import request from "../request";

interface CustomerPlan {
  id?: number;
  description?: string;
  minAmount?: number;
  maxAmount?: number;
  value?: string;
  superlogicaId?: string;
  recurrence?: number;
  numberOfAdmissions?: number;
  type?: number;
}

interface Customer {
  companyCode?: string;
  companyName?: string;
  email?: string;
  phone?: string;
  document?: string;
  zipCode?: string;
  addressNumber?: string;
  complement?: string;
  addressName?: string;
  district?: string;
  city?: string;
  state?: string;
  country?: string;
  active?: boolean;
  invoiceDueDate?: string;
  automation?: boolean;
  paymentMethod?: string;
  recurrence?: string;
  unitValue?: string;
  maxEmployees?: number;
  agreementText?: string;
  omieApiId?: string;
  pagcertoApiId?: string;
  status?: string;
  blockReason?: string;
  blockText?: string;
  billingDate: string;
  cityCode?: string;
  planId?: number;
  billingDay?: string;
  contractDate?: string;
  featuresAutomation?: boolean;
  customerPlan?: CustomerPlan;
  accountingId?: string;
}

export interface Finance {
  id?: string;
  companyCode?: string;
  superlogicaId?: string;
  status?: number;
  financeStatus?: number;
  plan?: number;
  isClientGroup?: boolean;
  customer?: Customer;
}

export const getFinanceData = async (): Promise<Finance | null> => {
  try {
    const response = await request.get("/finance/get");
    return response.status === 200 && response.data ? response.data : null;
  } catch (_) {
    return null;
  }
};

export const getSuperlogicaUserToken = async (email: string): Promise<string | null> => {
  try {
    const response = await request.post("/finance/get-user-token", { email });
    return response.status === 200 && response.data ? response.data : null;
  } catch (_) {
    return null;
  }
};

export const createSuperlogicaUserAccount = async (customerName: string, email: string, password: string) => {
  try {
    const response = await request.post("/finance/create-account", { customerName, email, password });
    return response.data.success || false;
  } catch (error) {
    return false;
  }
};

export const getAdmissionsLimit = async () => {
  try {
    const response = await request.post("/finance/get-admissions-limit");
    return response.status === 200 && response.data ? response.data : { isCustomer: false };
  } catch (error) {
    return { isCustomer: false };
  }
};
