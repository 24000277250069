import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetchByEmployeeId = async (id: number): Promise<Dependent[]> => {
  try {
    const { data } = await request.get(`/employee-dependents/employee/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<EmployeeNote> => {
  try {
    const { data } = await request.get(`/employee-dependents/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: EmployeeNote) => {
  try {
    const { data } = await request.put(`/employee-dependents/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Dependent): Promise<Dependent> => {
  try {
    const { data } = await request.post(`/employee-dependents`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/employee-dependents/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
