import { Checkbox, Col, Divider, Form, FormInstance, Row } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { dictionary, i18n } from "src/utils";
import "./Settings.css";

interface SettingsProps {
  user: User;
  onFinish(values: any): void;
  form: FormInstance<any>;
}

export default function Settings(props: SettingsProps) {
  const [list, setList] = useState<any[]>([]);
  const [listSelecteds, setListSelecteds] = useState<any[]>([]);
  const [qr_notif_mobile, setQr_notif_mobile] = useState<boolean>(false);
  const [qr_notif_web, setQr_notif_web] = useState<boolean>(false);
  const [qr_notif_email, setQr_notif_email] = useState<boolean>(false);
  const [qr_notif_solicitacoes, setQr_notif_solicitacoes] = useState<boolean>(false);

  async function getColaboradores() {
    let headers = {
      filtro: null,
    };
    // if (this.props.session.perfilUsuario.userType === "manager") {
    //   headers.filtro = this.props.session.perfilUsuario.config;
    // }

    axios
      .get("/colaborador/list-all", {
        headers: headers,
      })
      .then((res) => {
        let dados = res.data;
        if (dados.length > 0) {
          setList(dados);
        } else {
          setList([]);
        }
      });
  }

  // getColaboradoresSelecteds() {
  //   const { codUsuario } = this.props.session;
  //   axios
  //     .get("/settings/funcionarios-push", {
  //       params: {
  //         codUsuario,
  //       },
  //     })
  //     .then((res) => {
  //       let dados = res.data;
  //       if (dados.length > 0) {
  //         this.setState({
  //           listSelecteds: dados.map((item) => String(item.idfuncionario)),
  //         });
  //       } else {
  //         this.setState({
  //           listSelecteds: [],
  //         });
  //       }
  //     });
  // }

  // getSettings() {
  //   const { codUsuario } = this.props.session;
  //   axios
  //     .get("/settings", {
  //       params: {
  //         codUsuario,
  //       },
  //     })
  //     .then((res) => {
  //       let dados = res.data;
  //       this.setState({
  //         ...dados,
  //       });
  //     });
  // }

  // setSettings(settings: any) {
  //   this.setState({ ...settings }, () => {
  //     const { codUsuario } = this.props.session;
  //     axios
  //       .put("/settings/config-notification", {
  //         settings,
  //         codUsuario,
  //       })
  //       .then((res) => {
  //         if (res.data.success) {
  //           message.success(I18n.t("components.settings.messages.success"));
  //         } else {
  //           message.error(I18n.t("components.settings.messages.error"));
  //         }
  //       })
  //       .catch((err) => {
  //         message.error(I18n.t("components.settings.messages.error"));
  //       });
  //   });
  // }

  // handleChange = (listSelecteds, direction, moveKeys) => {
  //   this.setState({ listSelecteds });
  //   const { codUsuario } = this.props.session;

  //   if (direction === "right") {
  //     axios
  //       .put("/settings/funcionarios-push", {
  //         codUsuario,
  //         colaboradores: listSelecteds,
  //       })
  //       .then((res) => {
  //         if (res.data.result) {
  //           message.success(I18n.t("components.settings.messages.success"));
  //         } else {
  //           message.error(I18n.t("components.settings.messages.error"));
  //         }
  //       })
  //       .catch((_) => {
  //         message.error(I18n.t("components.settings.messages.error"));
  //       });
  //   } else {
  //     axios
  //       .post("/settings/funcionarios-push", {
  //         codUsuario,
  //         colaboradores: moveKeys,
  //       })
  //       .then((res) => {
  //         if (res.data.result) {
  //           message.success(I18n.t("components.settings.messages.success"));
  //         } else {
  //           message.error(I18n.t("components.settings.messages.error"));
  //         }
  //       })
  //       .catch((_) => {
  //         message.error(I18n.t("components.settings.messages.error"));
  //       });
  //   }
  // };

  function filterOption(inputValue: any, option: any) {
    return option.nomecompleto.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
  }

  function componentDidMount() {
    getColaboradores();
    // this.getColaboradoresSelecteds();
    // this.getSettings();
  }

  const [user, setUser] = useState<User>(props.user);

  // const { list, listSelecteds, qr_notif_mobile, qr_notif_web, qr_notif_email, qr_notif_solicitacoes } = this.state;
  useEffect(() => {
    props.form.setFieldsValue(props.user);
    setUser(props.user);
  }, [props.user]);

  return (
    <Form
      form={props.form}
      layout="vertical"
      onFinish={() => props.onFinish(user)}
    >
      {/* Checkbox de receber notificações no pc e no app */}
      <Row className="settings-form-row">
        <Col
          className="settings-form-col"
          span={12}
        >
          <Form.Item<User> name={["configurations", "webNotifications"]}>
            <Checkbox
              onChange={({ target }) =>
                setUser({ ...user, configurations: { ...user.configurations, webNotifications: target.checked } })
              }
              checked={user.configurations?.webNotifications}
            >
              {i18n(dictionary.components.settings.notificacoesComputador)}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col
          className="settings-form-col"
          span={12}
        >
          <Form.Item<User> name={["configurations", "appNotifications"]}>
            <Checkbox
              onChange={({ target }) =>
                setUser({ ...user, configurations: { ...user.configurations, appNotifications: target.checked } })
              }
              checked={user.configurations?.appNotifications}
            >
              {i18n(dictionary.components.settings.notificacoesApp)}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Divider
        dashed
        className="settings-form-hidden-divider"
      />
      {/* Checkbox de receber notificações por email e notofocações de solicitações do colaborador */}
      <Row className="settings-form-row">
        <Col
          className="settings-form-col"
          span={12}
        >
          <Form.Item<User> name={["configurations", "emailNotifications"]}>
            <Checkbox
              onChange={({ target }) =>
                setUser({ ...user, configurations: { ...user.configurations, emailNotifications: target.checked } })
              }
              checked={user.configurations?.emailNotifications}
            >
              {i18n(dictionary.components.settings.notificacoesEmail)}
            </Checkbox>
          </Form.Item>
        </Col>
        {/* <Col
          className="settings-form-col"
          span={12}
        >
          <Checkbox
          // onChange={({ target }) => this.setSettings({ qr_notif_solicitacoes: target.checked })}
          // checked={qr_notif_solicitacoes}
          >
            {i18n(dictionary.components.settings.notificacoesSolicitacoes)}
          </Checkbox>
        </Col> */}
      </Row>

      {/* <Divider /> */}
      {/* Token API */}
      {/* <Row className="settings-form-row">
        <Col
          className="settings-form-col"
          span={16}
        >
          <Form.Item
            className="settings-form-input"
            label={
              <span>
                {i18n(dictionary.components.settings.token")}
                &nbsp;
                <Tooltip title={i18n(dictionary.components.settings.identificacao")}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            hasFeedback
          >
            <Input.Password
              style={{ textAlign: "center" }}
              disabled
              // value={this.props.session.token}
              // hasFeedback
              // addonAfter={
              //   <CopyToClipboard
              //     text={this.props.session.token}
              //     onCopy={() => message.info(I18n.t("components.settings.messages.copiado"))}
              //   >
              //     <CopyOutlined style={{ cursor: "pointer" }} />
              //   </CopyToClipboard>
              // }
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider /> */}
      {/* <Form.Item label={i18n(dictionary.components.settings.selecioneAlguemNotificacao")}>
        <Transfer
          className="settings-form-transfer"
          filterOption={filterOption}
          titles={[i18n(dictionary.components.settings.colaboradores"), `${i18n(dictionary.components.settings.receberNotificacao")}:`]}
          showSearch
          // onChange={this.handleChange.bind(this)}
          rowKey={(record) => record.idfuncionario}
          render={(item) => item.nomecompleto}
          // dataSource={list}
          // targetKeys={listSelecteds}
          style={{ textAlign: "center" }}
          listStyle={{ width: "40%", height: 300, textAlign: "left" }}
          locale={{
            itemUnit: "item",
            itemsUnit: "itens",
          }}
        />
      </Form.Item> */}
    </Form>
  );
}
