import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetchByTable = async (table: string, recordId: string): Promise<ChangeHistory[]> => {
  try {
    const { data } = await request.get(`/change-history-table/${table}/${recordId}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
