import { CalculatorOutlined, EyeOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import { useState } from "react";
import { Card, FormItem, InputGroup, Table } from "src/components";
import { dictionary, i18n } from "src/utils";
import { LogsActionList } from "./styles";

const { Column } = Table;

type Props = {
  logs: ChangeHistory[];
};

function LogTable({ logs }: Props): JSX.Element {
  const [form] = useForm();
  const [showLogsModal, setShowLogsModal] = useState<boolean>(false);

  const logRecords = logs.map((log) => ({
    key: log.id,
    ...log,
  }));

  function handleOpenModal(log: ChangeHistory) {
    form.setFieldsValue({
      logItem: log.userName,
      logAuthor: log.userName,
      logActions: [log.action],
      logDateTime: moment(log.createdAt).format(i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat)),
    });
    setShowLogsModal(true);
  }

  function handleCloseModal() {
    setShowLogsModal(false);
    form.resetFields();
  }

  function LogModal(): JSX.Element {
    function renderActionList(): JSX.Element[] {
      const actions = form.getFieldValue("logActions");
      if (actions) {
        return actions.map((action: string) => <li>{action}</li>);
      }
      return [];
    }

    function renderTitle(): string {
      //const dateTime = form.getFieldValue("logDateTime");
      // if (dateTime) {
      //   const formattedDateTime = moment(dateTime).format(
      //     i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat),
      //   );
      //   return `${i18n(dictionary.label.logs)} / ${formattedDateTime}`;
      // }

      return i18n(dictionary.label.logs);
    }

    return (
      <Modal
        title={renderTitle()}
        visible={showLogsModal}
        onCancel={() => handleCloseModal()}
        footer={
          <Button
            type="default"
            onClick={() => handleCloseModal()}
          >
            {i18n(dictionary.action.close_visualize)}
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
        >
          <FormItem>
            <InputGroup>
              <FormItem
                label={i18n(dictionary.label.date_time)}
                name="logDateTime"
                rules={[{ required: false }]}
              >
                <Input disabled />
              </FormItem>

              <FormItem
                label={i18n(dictionary.label.author)}
                name="logAuthor"
                rules={[{ required: false }]}
              >
                <Input disabled />
              </FormItem>
            </InputGroup>
          </FormItem>

          <FormItem
            label={i18n(dictionary.label.action)}
            name="logActions"
            rules={[{ required: false }]}
          >
            <LogsActionList>{renderActionList()}</LogsActionList>
          </FormItem>
        </Form>
      </Modal>
    );
  }

  return (
    <>
      <LogModal />
      <Card
        icon={<HistoryOutlined />}
        title={i18n(dictionary.label.history_updates)}
      >
        <Table
          scroll={{ x: true }}
          tableLayout="auto"
          dataSource={logRecords}
        >
          <Column<ChangeHistory>
            title={i18n(dictionary.label.date_time)}
            dataIndex="createdAt"
            key="createdAt"
            render={(value: Date) =>
              moment(value).format(i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat))
            }
            sorter={(a, b) => a.createdAt.getTime() - b.createdAt.getTime()}
          />
          <Column
            title={i18n(dictionary.label.action)}
            dataIndex="action"
            key="action"
            width="60%"
          />
          <Column<ChangeHistory>
            title={i18n(dictionary.label.author)}
            dataIndex="user"
            key="user"
            sorter={(a, b) => a.user.name.localeCompare(b.user.name)}
            render={(_, record: ChangeHistory) =>
              !record.userId ? (
                <>
                  {record.userName}&nbsp;
                  <CalculatorOutlined />
                </>
              ) : (
                record.userName
              )
            }
          />
          <Column<ChangeHistory>
            title={i18n(dictionary.label.logs)}
            dataIndex="action"
            key="action"
            render={(_, record) => (
              <Button
                size="small"
                type="default"
                icon={<EyeOutlined />}
                onClick={() => handleOpenModal(record)}
              >
                {i18n(dictionary.action.view)}
              </Button>
            )}
          />
        </Table>
      </Card>
    </>
  );
}

export default LogTable;
