import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { EmployeeApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { Routes } from "src/routes";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import Employee from "./Employee";

function EmployeeContainer(): JSX.Element {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const navigate = useNavigate();
  const { show } = useIntercom();
  const [search, setSearch] = useState("");

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onCreate = () => {
    navigate(Routes.EMPLOYEE_CREATE);
  };

  const onEdit = (id: number) => navigate(`${Routes.EMPLOYEE_EDIT}?id=${id}`);

  const onDisable = async (id: number) => {
    function onFinishDelete() {
      EmployeeApi.destroy(id)
        .then(async (res) => {
          await getEmployeeData();
          message.success({
            content: i18n(dictionary.employee.disabled, {
              context: context.male,
              item: i18n(dictionary.entity.employee),
            }),
          });
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.DISABLED_EMPLOYEE_ERROR.title),
              message: i18n(dictionary.error.DISABLED_EMPLOYEE_ERROR.message),
              onOk: () => onFinishDelete(),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    }

    onFinishDelete();
  };

  const onEnable = (id: number) => {
    function onFinishTurnOn() {
      EmployeeApi.turnOn(id)
        .then(async (res) => {
          await getEmployeeData();
          message.success({
            content: i18n(dictionary.employee.active, {
              context: context.male,
              item: i18n(dictionary.entity.employee),
            }),
          });
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.ACTIVE_EMPLOYEE_ERROR.title),
              message: i18n(dictionary.error.ACTIVE_EMPLOYEE_ERROR.message),
              onOk: () => onFinishTurnOn(),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    }

    onFinishTurnOn();
  };

  async function getEmployeeData(): Promise<boolean> {
    return await EmployeeApi.fetch()
      .then((res: Employee[]) => {
        setEmployees(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYEE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmployeeData();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <Employee
      onCreate={onCreate}
      onSearch={onSearch}
      onEdit={onEdit}
      onDisable={onDisable}
      onEnable={onEnable}
      employees={employees.filter(
        (employee) =>
          employee.name.toLowerCase().includes(search.toLowerCase()) ||
          employee.employer.shortName.toLowerCase().includes(search.toLowerCase()),
      )}
    />
  );
}

export default EmployeeContainer;
