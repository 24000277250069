import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "./request";

export const fetchUser = async (): Promise<User[]> => {
  try {
    const { data } = await request.get(`/users`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<User> => {
  try {
    const { data } = await request.get(`/users/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const createUser = async (data: User): Promise<void> => {
  try {
    await request.post(`/users`, data);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateUser = async (id: number, data: User): Promise<void> => {
  try {
    await request.put(`/users/${id}`, data);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateUnity = async (data: User, id: number): Promise<void> => {
  try {
    await request.put(`/users/${id}`, data);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateRole = async (data: User, id: number): Promise<void> => {
  try {
    await request.put(`/users/${id}`, data);
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchManagerUser = async (): Promise<User[]> => {
  try {
    const { data } = await request.get(`/users`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
