import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Image, Row, Space, Typography } from "antd";
import React from "react";
import { LogoTransparent, SendingDocument } from "src/assets/images";
import { REACT_APP_BASE_PATH } from "src/constants";
import { dictionary, i18n } from "src/utils";
import styled from "styled-components";

const { Text, Title, Link } = Typography;

interface PreviewSendingProps {
  logoCompany?: string;
  principalColor: string;
  secondaryColor: string;
  frase: string;
  description?: string;
  optionPreview: string;
}

const getPreviewProperties = (optionPreview: string) => {
  let sizePrincipalFrase;
  let sizeSecondaryFrase;
  let sizeLayout;
  let sizeLogo;
  let contentBottomMarginLeft;
  let contentBottomMarginTop;
  let contentBottomMarginRight;

  switch (optionPreview) {
    case "small":
      sizeLayout = "20vh";
      sizeLogo = "70%";
      sizePrincipalFrase = 5;
      sizeSecondaryFrase = 5;
      contentBottomMarginLeft = "32px";
      contentBottomMarginTop = "16";
      contentBottomMarginRight = "32px";

      break;
    case "medium":
      sizeLayout = "40vh";
      sizeLogo = "100%";
      sizePrincipalFrase = 12;
      sizeSecondaryFrase = 12;
      contentBottomMarginLeft = "64px";
      contentBottomMarginTop = "32";
      contentBottomMarginRight = "64px";

      break;
    case "larger":
      sizeLayout = "";

      break;
    default:
      break;
  }
  return {
    sizeLayout: sizeLayout,
    sizePrincipalFrase: sizePrincipalFrase,
    sizeSecondaryFrase: sizeSecondaryFrase,
    sizeLogo: sizeLogo,
    contentBottomMarginLeft,
    contentBottomMarginTop,
    contentBottomMarginRight,
  };
};

export default function PreviewSending(props: PreviewSendingProps) {
  const {
    sizePrincipalFrase,
    sizeSecondaryFrase,
    sizeLayout,
    sizeLogo,
    contentBottomMarginLeft,
    contentBottomMarginTop,
    contentBottomMarginRight,
  } = getPreviewProperties(props.optionPreview);

  const rowStyle: React.CSSProperties = {
    height: sizeLayout,
  };

  const stepsStyle: React.CSSProperties = {
    color: "#fff",
    backgroundColor: props.principalColor,
    width: "50%",
    height: sizeLayout,
  };

  const contentTop: React.CSSProperties = {
    height: "14vh",
    padding: "20px 0px",
    marginLeft: "15px",
  };

  const contentBottom: React.CSSProperties = {
    marginLeft: contentBottomMarginLeft,
    marginTop: contentBottomMarginTop,
    marginRight: contentBottomMarginRight,
  };

  const StyledTitle = styled(Title)`
    &.ant-typography {
      color: ${(props) => props.color};
    }
  `;

  return (
    <Row style={rowStyle}>
      <Col
        span={12}
        style={stepsStyle}
      >
        <Row style={contentTop}>
          <Image
            width="auto"
            height={sizeLogo}
            src={SendingDocument}
            alt="SendingDocument"
            preview={false}
          />
        </Row>
        <Row style={contentBottom}>
          <Row>
            <StyledTitle
              color="white"
              style={{ fontSize: sizePrincipalFrase }}
            >
              {props.frase}
            </StyledTitle>
          </Row>
        </Row>
      </Col>
      <Col
        span={12}
        style={{ height: sizeLayout }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "end",
            marginBottom: "10%",
            textAlign: "end",
          }}
        >
          <Image
            style={{ margin: "10px 10px 0 0", height: "15%", width: "15%" }}
            src={props.logoCompany ? `${REACT_APP_BASE_PATH}/download/${props.logoCompany}` : LogoTransparent}
            alt="Logo"
            preview={false}
          />
        </Space>
        <Row>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <StyledTitle
              style={{ fontSize: 40, marginBottom: "8px" }}
              color={props.secondaryColor}
            >
              Oiê!
            </StyledTitle>
          </Space>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Space
            direction="horizontal"
            style={{
              width: "100%",
              justifyContent: "center",
              marginLeft: "16%",
              marginRight: "16%",
              height: "60%",
              marginBottom: 12,
            }}
          >
            <StyledTitle
              color="gray"
              level={5}
              style={{ fontSize: sizeSecondaryFrase }}
            >
              {props.description || i18n(dictionary.components.sendings.formItems.descriptionPlaceholder)}
            </StyledTitle>
          </Space>
        </Row>
        <Row>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "center" }}
          >
            <Button
              icon={<ArrowRightOutlined />}
              type="primary"
              htmlType="submit"
              size="small"
              style={{ background: "#6164C2", fontSize: 9 }}
            >
              Iniciar
            </Button>
          </Space>
        </Row>
      </Col>
    </Row>
  );
}
