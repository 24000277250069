import CryptoJS from "crypto-js";
import { REACT_APP_KEY_ENCRYPT_URL } from "src/constants";
import Keys from "./keys";

const Storage = window.localStorage;

export default class StorageService {
  static clearWholeStorage(): void {
    Storage.clear();
  }

  static getToken(): Token {
    const value = Storage.getItem(Keys.TOKEN);
    return value ? JSON.parse(value) : "";
  }

  static setToken(token: Token): void {
    const item = JSON.stringify(token);
    Storage.setItem(Keys.TOKEN, item);
  }

  static setUserName(name: string): void {
    const item = JSON.stringify(name);
    Storage.setItem(Keys.USER_NAME, item);
  }

  static setUserId(id: number): void {
    const item = JSON.stringify(id);
    Storage.setItem(Keys.USER_ID, item);
  }

  static setUserEmail(email: string): void {
    const item = JSON.stringify(email);
    Storage.setItem(Keys.USER_EMAIL, item);
  }

  static setUserFinishedAdmissionAccess(value: boolean): void {
    const item = JSON.stringify(value);
    Storage.setItem(Keys.FINISHED_ADMISSION_ACCESS, item);
  }

  static setContractText(text: any): void {
    const item = JSON.stringify(text);
    Storage.setItem(Keys.CONTRACT_TEXT, item);
  }

  static getUserName(): string {
    const value = Storage.getItem(Keys.USER_NAME);
    return value ? JSON.parse(value) : "";
  }

  static getUserId(): number {
    const value = Storage.getItem(Keys.USER_ID);
    return value ? JSON.parse(value) : 0;
  }

  static getUserEmail(): string {
    const value = Storage.getItem(Keys.USER_EMAIL);
    return value ? JSON.parse(value) : "";
  }

  static getUserFinishedAdmissionAccess(): boolean {
    const value = Storage.getItem(Keys.FINISHED_ADMISSION_ACCESS);
    return value ? JSON.parse(value) : false;
  }

  static getContractText(): string {
    const value = Storage.getItem(Keys.CONTRACT_TEXT);
    return value
      ? value
      : JSON.stringify([
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]);
  }

  static setEncryptedPassword(password: string) {
    if (password) {
      const encryptedPassword = CryptoJS.AES.encrypt(password, REACT_APP_KEY_ENCRYPT_URL);
      const finalEncryptedPassword = encryptedPassword.toString();
      Storage.setItem(Keys.ENCRYPTED_PASSWORD, finalEncryptedPassword);
    }
  }

  static getEncryptedPassword(): string {
    return Storage.getItem(Keys.ENCRYPTED_PASSWORD) || "";
  }

  static setLatestNew(latestNewId: string) {
    Storage.setItem(Keys.LATEST_NEW, `${this.getUserId()}_${latestNewId}`);
  }

  static getLatestNew(): string {
    return Storage.getItem(Keys.LATEST_NEW) || "";
  }
}
