import React, { ReactNode } from 'react';

export type Props = {
  condition: boolean;
  children: ReactNode | string;
};

const If: React.FC<Props> = ({ condition, children }): any =>
  condition ? children : null;

export default If;
