import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { DatePicker, Input, Row, Space, Switch, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ContainerOutlined } from "src/assets/icons";
import { Card, Form, FormItem, InputGroup, Option, Select, Text } from "src/components";
import { MaskedInput } from "src/modules";
import { dictionary, i18n } from "src/utils";

type Props = {
  genders: Gender[];
  maritalStatuses: MaritalStatus[];
  educationLevels: EducationLevel[];
  countrysOfBirth: Country[];
  countrysOfNationality: Country[];
  races: Race[];
  disabilities: Disability[];
  onSubmit(values: any): void;
  onCancel(): void;
  employee?: Employee;
  loading: boolean;
};

function TabPersonalData({
  genders,
  maritalStatuses,
  educationLevels,
  countrysOfBirth,
  countrysOfNationality,
  races,
  disabilities,
  onSubmit,
  onCancel,
  employee,
  loading,
}: Props): JSX.Element {
  const [form] = useForm();
  const [telNumber, setTelNumber] = useState<string>(
    employee?.additionalData?.telNumber ? employee?.additionalData?.telNumber : "",
  );
  const [isWhatsapp, setIsWhatsapp] = useState<boolean>(false);
  const [hasDisability, setHasDisability] = useState<boolean>(false);
  const [isReabReadap, setIsReabReadap] = useState<boolean>(false);
  const [isInfoCota, setIsinfoCota] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldsValue({
      ...employee,
      ...employee?.additionalData,
      ...employee?.eSocialData,
      birthDate: employee?.birthDate ? dayjs(employee?.birthDate) : undefined,
      disabilities: employee?.disabilities?.map((disabilitie) => disabilitie.id),
    });
    setTelNumber(employee?.additionalData?.telNumber ?? "");
    setIsWhatsapp(employee?.additionalData?.isWhatsapp ?? false);
    setHasDisability(employee?.additionalData?.hasDisability ?? false);
    setIsReabReadap(employee?.eSocialData?.reabReadap ?? false);
    setIsinfoCota(employee?.eSocialData?.infoCota ?? false);
  }, []);

  return (
    <Form
      form={form}
      onSubmit={(values) => onSubmit({ ...values, tab: "1" })}
      onCancel={() => onCancel()}
      submitText={i18n(dictionary.action.update_save)}
      loading={loading}
    >
      <Card
        icon={<ContainerOutlined />}
        title={i18n(dictionary.label.personal_data)}
      >
        <FormItem
          label={i18n(dictionary.label.name)}
          name="name"
          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }, { max: 53 }]}
          hasFeedback
          validateFirst
        >
          <Input />
        </FormItem>

        <FormItem
          label={i18n(dictionary.label.gender)}
          name="gender"
          rules={[{ required: false, message: i18n(dictionary.message.requiredField) }, { max: 1 }]}
          hasFeedback
          validateFirst
        >
          <Select>
            {genders.map((gender) => (
              <Option
                key={gender.code}
                title={gender.name}
                value={gender.code}
              >
                {gender.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.social_name)}
          name="socialName"
          rules={[{ required: false }, { max: 53 }]}
          hasFeedback
          validateFirst
        >
          <Input />
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.marital_status)}
          name="maritalStatus"
          rules={[{ required: true }, { max: 2 }]}
          hasFeedback
          validateFirst
        >
          <Select>
            {maritalStatuses.map((maritalStatus) => (
              <Option
                key={maritalStatus.code}
                title={maritalStatus.name}
                value={maritalStatus.code}
              >
                {maritalStatus.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.education_level)}
          name="educationLevel"
          rules={[{ required: true }, { max: 2 }]}
          hasFeedback
          validateFirst
        >
          <Select>
            {educationLevels.map((educationLevels) => (
              <Option
                key={educationLevels.code}
                title={educationLevels.name}
                value={educationLevels.code}
              >
                {educationLevels.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.email)}
          name="email"
          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }, { type: "email" }, { max: 200 }]}
          hasFeedback
          validateFirst
        >
          <Input />
        </FormItem>
        <FormItem label={i18n(dictionary.label.contact_phone_number)}>
          <InputGroup>
            <FormItem
              name="telNumber"
              rules={[{ required: false }, { min: 14 }, { max: 15 }]}
              hasFeedback
              validateFirst
            >
              <MaskedInput
                mask="(00) 00000-0000"
                value={telNumber}
                defaultValue={telNumber}
                onChange={({ target }) => {
                  setTelNumber(target.value);
                }}
              />
            </FormItem>

            <FormItem
              name="isWhatsapp"
              rules={[{ required: false }]}
              hasFeedback
              validateFirst
              initialValue={false}
            >
              <InputGroup>
                <Space>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked: boolean) => {
                      form.setFieldsValue({ isWhatsapp: checked });
                      setIsWhatsapp(checked);
                    }}
                    checked={isWhatsapp}
                  />
                  <Text variant="small">{i18n(dictionary.label.this_number_is_whatsapp)}</Text>
                </Space>
              </InputGroup>
            </FormItem>
          </InputGroup>
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.race)}
          name="raceId"
          rules={[{ required: true }]}
          hasFeedback
          validateFirst
        >
          <Select>
            {races.map((race) => (
              <Option
                key={race.id}
                title={race.name}
                value={race.id}
              >
                {race.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label={i18n(dictionary.label.sex)}
          name="sex"
          rules={[{ required: true, message: i18n(dictionary.message.requiredField) }, { max: 2 }]}
          hasFeedback
          validateFirst
        >
          <Select>
            {genders.map((gender) => (
              <Option
                key={gender.code}
                title={gender.name}
                value={gender.code}
              >
                {gender.name}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          <InputGroup>
            <FormItem
              label={i18n(dictionary.label.birth_date)}
              name="birthDate"
              rules={[{ required: true }]}
              hasFeedback
              validateFirst
            >
              <DatePicker format={[i18n(dictionary.dateFormat)]} />
            </FormItem>
            <FormItem
              label={i18n(dictionary.label.country_of_birth)}
              name="countryBirthId"
              rules={[{ required: true }]}
              hasFeedback
              validateFirst
            >
              <Select showSearch>
                {countrysOfBirth.map((countryOfBirth) => (
                  <Option
                    key={countryOfBirth.id}
                    title={countryOfBirth.name}
                    value={countryOfBirth.id}
                    label={countryOfBirth.name}
                  >
                    {countryOfBirth.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              label={i18n(dictionary.label.country_of_nationality)}
              name="countryNationalityId"
              rules={[{ required: true }]}
              hasFeedback
              validateFirst
            >
              <Select showSearch>
                {countrysOfNationality.map((countryOfNationality) => (
                  <Option
                    key={countryOfNationality.id}
                    title={countryOfNationality.name}
                    value={countryOfNationality.id}
                  >
                    {countryOfNationality.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </InputGroup>
        </FormItem>
        <FormItem>
          <InputGroup>
            <FormItem
              label={i18n(dictionary.label.has_any_disability)}
              name="hasDisability"
              rules={[{ required: false }]}
              initialValue={false}
              hasFeedback
              validateFirst
            >
              <Switch
                checkedChildren={i18n(dictionary.action.yes)}
                unCheckedChildren={i18n(dictionary.action.no)}
                onChange={(checked: boolean) => {
                  form.setFieldsValue({ hasDisability: checked });
                  setHasDisability(checked);
                  console.log(checked);
                }}
                checked={hasDisability}
              />
            </FormItem>
            {hasDisability && (
              <FormItem
                label={i18n(dictionary.label.disability)}
                name="disabilities"
                rules={[
                  {
                    required: hasDisability,
                    message: i18n(dictionary.message.requiredField),
                  },
                ]}
                hasFeedback
                validateFirst
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                >
                  {disabilities.map((disability) => (
                    <Option
                      key={disability.id}
                      title={disability.name}
                      value={disability.id}
                    >
                      {disability.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            )}
          </InputGroup>
        </FormItem>
        {hasDisability && (
          <>
            <FormItem
              label={
                <Row>
                  {i18n(dictionary.label.rehabilitated_or_readapted)}
                  <Tooltip title={i18n(dictionary.message.rehabilitated_or_readapted)}>
                    <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                  </Tooltip>
                </Row>
              }
              name="reabReadap"
              hasFeedback
              validateFirst
            >
              <Row>
                <Switch
                  checkedChildren={i18n(dictionary.label.yes)}
                  unCheckedChildren={i18n(dictionary.label.no)}
                  onChange={(checked: boolean) => {
                    form.setFieldsValue({ reabReadap: checked });
                    setIsReabReadap(checked);
                  }}
                  checked={isReabReadap}
                />
              </Row>
            </FormItem>
            <FormItem
              label={i18n(dictionary.label.infoCota)}
              name="infoCota"
              hasFeedback
              validateFirst
            >
              <Row>
                <Switch
                  checkedChildren={i18n(dictionary.label.yes)}
                  unCheckedChildren={i18n(dictionary.label.no)}
                  onChange={(checked: boolean) => {
                    form.setFieldsValue({ infoCota: checked });
                    setIsinfoCota(checked);
                  }}
                  checked={isInfoCota}
                />
              </Row>
            </FormItem>
          </>
        )}
      </Card>
    </Form>
  );
}

export default TabPersonalData;
