import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ChangeHistoryApi, ContractApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { employmentRelationshipCodes } from "src/data";
import { useEditMode } from "src/hooks";
import { Storage } from "src/services";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import ContractCreate from "./ContractCreate";

const initialForm: Contract = {
  description: "",
  text: JSON.stringify([
    {
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ]),
  id: 0,
};

function ContractCreateContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(1);
  const [contractCount, setContractCount] = useState(0);
  const [contract, setContract] = useState<Contract>(initialForm);
  const [contractLogs, setContractLogs] = useState<ChangeHistory[]>([]);
  const [editMode] = useEditMode();
  const [tabs, setTabs] = useState<Array<{ index: number; key: string; title: string }>>([]);
  const [params, setParams] = useSearchParams();
  const { show } = useIntercom();

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getContract();
      await getChangeHistory();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (editMode) {
      getInitData();
      setTabs([
        {
          index: 1,
          key: "editContract",
          title: i18n(dictionary.label.edit_item, {
            item: i18n(dictionary.entity.contract),
          }),
        },
        {
          index: 2,
          key: "history",
          title: i18n(dictionary.label.history),
        },
      ]);
    } else {
      setTabs([
        {
          index: 1,
          key: "newContract",
          title: i18n(dictionary.label.new_item, {
            context: context.male,
            item: i18n(dictionary.entity.contract),
          }),
        },
      ]);
    }
  }, [editMode]);

  function addContract(): void {
    setContract({
      ...contract,
      id: contractCount,
      description: i18n(dictionary.entity.contract, { count: 1 }) + ` ${contractCount}`,
      text: "O texto do novo contrato adicionado tem por objetivo ser um aditivo aos contratos Pré-cadastrados",
    });
    setContractCount(contractCount + 1);
  }

  function removeContract(key: number): void {
    setContract(contract);
  }

  function updateContract<PropertyType extends keyof Contract>(
    key: number,
    property: PropertyType,
    value: Contract[PropertyType],
  ) {
    const contractIndex = contract?.id;
    if (contractIndex && contractIndex > -1) {
      contract[property] = value;
    }
  }

  const navigate = useNavigate();

  const onGoBack = () => navigate(-1);

  const onFinish = (contractForm: Contract): void => {
    contractForm.text = Storage.getContractText();
    if (editMode) onUpdateContract(contractForm);
    else onCreateContract(contractForm);
  };

  const onCreateContract = (contractForm: Contract): void => {
    ContractApi.create(contractForm)
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.entity.contract),
          }),
        });
        Storage.setContractText([
          {
            type: "paragraph",
            children: [{ text: "" }],
          },
        ]);
        return onGoBack();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.CREATE_CONTRACT_ERROR.message),
            onOk: () => onCreateContract(contractForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const onUpdateContract = (contractForm: Contract): void => {
    ContractApi.updateById(contract!.id, contractForm)
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.updated_success, {
            context: context.male,
            item: i18n(dictionary.entity.contract),
          }),
        });
        return onGoBack();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.UPDATE_CONTRACT_ERROR.message),
            onOk: () => onUpdateContract(contractForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  async function getContract(): Promise<boolean> {
    return await ContractApi.fetchById(parseInt(params.get("id") ?? "0"))
      .then((res: Contract) => {
        setContract(res);
        Storage.setContractText(JSON.parse(res.text));
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.GET_CONTRACT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getChangeHistory(): Promise<boolean> {
    return await ChangeHistoryApi.fetchByTable("admission.pp_contracts", params.get("id") ?? "0")
      .then((res: ChangeHistory[]) => {
        setContractLogs(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.title),
            message: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  return (
    <ContractCreate
      onGoBack={onGoBack}
      onFinish={onFinish}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      relationships={employmentRelationshipCodes}
      contract={contract}
      addContract={() => addContract()}
      removeContract={(key: number) => removeContract(key)}
      updateContract={(key: number, property: keyof Contract, value: Contract[keyof Contract]) =>
        updateContract(key, property, value)
      }
      editMode={editMode}
      contractLogs={contractLogs}
      viewMode={(params.get("view") ?? "") === "true"}
    />
  );
}
export default ContractCreateContainer;
