import { Modal, Typography } from "antd";
import { dictionary, i18n } from "src/utils";

const { Text } = Typography;

export default function AccessBlockByAccounting() {
  return Modal.warning({
    title: i18n(dictionary.components.accessBlockByAccounting.title),
    content: (
      <Text>
        {i18n(dictionary.components.accessBlockByAccounting.texts.p1)}
        <Text style={{ color: "#884ea6" }}>{i18n(dictionary.components.accessBlockByAccounting.texts.p2)}</Text>
        {i18n(dictionary.components.accessBlockByAccounting.texts.p3)}
        <Text style={{ fontWeight: 600 }}>{i18n(dictionary.components.accessBlockByAccounting.texts.p4)}</Text>
        {i18n(dictionary.components.accessBlockByAccounting.texts.p5)}
      </Text>
    ),
    closable: true,
    footer: false,
  });
}
