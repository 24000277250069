import { LoadingOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Flex, Spin, Typography } from "antd";
import CryptoJS from "crypto-js";
import { useEffect } from "react";
import { useForm as useHookForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { ExtendedGradientLogo, LogoWhiteSimple } from "src/assets/images";
import { Input } from "src/components";
import GradientBackground from "src/components/GradientBackground";
import AccessBlockByAccounting from "src/components/Modal/AccessBlockByAccounting";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { REACT_APP_KEY_ENCRYPT_URL } from "src/constants";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";
import { Form, LoginContainer, LoginWrapper, RedirectSystemWrapper, SignInButton } from "./styles";
import { loginSchema } from "./validation";
const { Text } = Typography;

type Props = {
  auth: (credentials: Credentials) => void;
};

function Login({ auth }: Props): JSX.Element {
  const { update, show } = useIntercom();

  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useHookForm<Credentials>({
    resolver: yupResolver(loginSchema),
  });
  const navigate = useNavigate();

  const onHandleSubmit = handleSubmit(async (data) => {
    await checkLogin(data);
  });

  async function checkLogin(data: Credentials) {
    try {
      await auth(data);
      update({ name: Storage.getUserName(), email: Storage.getUserEmail(), userId: Storage.getUserId().toString() });
      navigate(Routes.HOME);
    } catch (error) {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.LOGIN_ERROR.title),
          message: i18n(dictionary.error.LOGIN_ERROR.message),
          onOk: () => checkLogin(data),
          onClickLink: () => show(),
        });
      }
      if (error.message === "block_by_accounting") {
        AccessBlockByAccounting();
      }
      console.error(error);
    }
  }

  async function autoLogin() {
    if (params && params.data) {
      if (REACT_APP_KEY_ENCRYPT_URL.length) {
        const decryptedData = CryptoJS.AES.decrypt(params.data, REACT_APP_KEY_ENCRYPT_URL).toString(CryptoJS.enc.Utf8);
        if (decryptedData !== String(Storage.getUserId())) {
          await checkLogin({ email: "", password: "", userId: decodeURIComponent(params.data) });
        }
      }
      navigate("/");
    }
  }

  useEffect(() => {
    autoLogin();
  }, []);

  return params.data ? (
    <RedirectSystemWrapper>
      <img
        src={LogoWhiteSimple}
        alt="Logo"
        width="80px"
        style={{ position: "fixed", marginBottom: 50 }}
      />
      <Spin
        style={{ color: "#FFF" }}
        indicator={<LoadingOutlined style={{ fontSize: 150 }} />}
      />
      <span style={{ color: "#FFF", marginTop: 30, fontSize: 18, textAlign: "center" }}>
        Quase lá, estamos te enviando para o QRPoint: Admissão digital...
      </span>
    </RedirectSystemWrapper>
  ) : (
    <GradientBackground>
      <LoginWrapper>
        <LoginContainer>
          <h1>
            <img
              src={ExtendedGradientLogo}
              alt="Logo"
              width="100px"
            />
          </h1>
          <h2>Acesse usando seu email e senha</h2>
          <Form
            onSubmit={onHandleSubmit}
            autoComplete="on"
          >
            <Input
              name="email"
              register={register("email")}
              placeholder="E-mail"
              error={errors.email}
            />
            <Input
              name="password"
              register={register("password", { required: true })}
              placeholder="Senha"
              error={errors.password}
              type="password"
            />
            <Flex
              justify="flex-end"
              style={{ width: "100%" }}
            >
              <Text
                className="custom-link"
                type="secondary"
                style={{
                  fontSize: "8pt",
                  cursor: "pointer",
                  margin: "3px 3px 0 0",
                }}
                onClick={() => navigate(Routes.FORGOT_PASSWORD, { replace: true })}
              >
                {i18n(dictionary.pages.login.fields.forgotPassword)}
              </Text>
            </Flex>
            <SignInButton type="submit">{i18n(dictionary.pages.login.fields.signIn)}</SignInButton>
          </Form>
        </LoginContainer>
      </LoginWrapper>
    </GradientBackground>
  );
}

export default Login;
