import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "./request";

export default async function fetchRoles(): Promise<RoleTypes[]> {
  try {
    const { data } = await request.get(`/roles`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}
