import { PlusOutlined } from "@ant-design/icons";
import { Select as AntSelect, Button, Input, SelectProps, Typography } from "antd";
import React, { useState } from "react";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";
import { Divider, Space } from "./styles";

const { Text } = Typography;

type Props = SelectProps & {
  insertInputPlaceholder?: string;
  onInsert(value: string): void;
};

export default function Select(props: Props): React.ReactElement {
  const { children, insertInputPlaceholder, onInsert } = props;

  const [insertValue, setInsertValue] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const handleInsert = () => {
    const trimmedValue = insertValue.trim();

    if (trimmedValue !== "") {
      onInsert(trimmedValue);
      setInsertValue("");
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  };

  return (
    <AntSelect
      showSearch={false}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider />
          <Space>
            <Input
              value={insertValue}
              onChange={(event) => setInsertValue(event.target.value)}
              placeholder={capitalizeFirstLetter(insertInputPlaceholder ?? "") || i18n(dictionary.insert_new_item)}
              onKeyDown={(event) => event.stopPropagation()}
              onPressEnter={handleInsert}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={handleInsert}
            >
              {i18n(dictionary.insert, { item: insertInputPlaceholder })}
            </Button>
          </Space>
          <Space>{showWarning && <Text type="danger">{i18n(dictionary.message.nonEmptyValue)}</Text>}</Space>
        </>
      )}
      {...props}
    >
      {children}
    </AntSelect>
  );
}
