import React from "react";
import { FieldError } from "react-hook-form";
import styled, { css } from "styled-components";

import { Column, Text } from "src/components";
import { ColumnProps } from "../Column";

interface InputProps {
  name: string;
  placeholder?: string;
  error?: FieldError;
  type?: string;
  disabled?: boolean;
}

interface InputComponentProps extends InputProps, ColumnProps {
  register: any;
  label?: string;
  textArea?: boolean;
}

interface WrapperInput {
  textArea?: boolean;
  disabled?: boolean;
}

const InputComponent: React.FC<InputComponentProps> = ({
  name,
  register,
  label,
  placeholder,
  error,
  type,
  disabled,
  textArea,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      {label && <Text variant="regular">{label}</Text>}
      {textArea ? (
        <TextArea
          name={name}
          {...register}
          placeholder={placeholder}
          error={error}
          type={type}
          disabled={disabled}
        />
      ) : (
        <Input
          name={name}
          {...register}
          placeholder={placeholder}
          error={error}
          type={type}
          disabled={disabled}
        />
      )}
      {error?.message && (
        <Text
          variant="tiny"
          color="red"
        >
          {error?.message}
        </Text>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Column)`
  width: 100%;
  margin-bottom: 0.7rem;
`;

const WrapperInput = styled.div<WrapperInput>`
  border: 1px solid blue;
  min-height: 4.7rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
  ${({ textArea }) => {
    if (textArea) {
      return css`
        min-height: 4.7rem;
        height: 100%;
      `;
    }
  }}
`;

const Input = styled.input<InputProps>`
  width: 100%;
  min-height: 4.7rem;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  ${({ error }) => {
    if (error?.message) {
      return css`
        border-color: ${({ theme }) => theme.colors.error};
      `;
    }
  }}
`;

const TextArea = styled.textarea<InputProps>`
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  min-height: 16rem;
  resize: vertical;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  ${({ error }) => {
    if (error?.message) {
      return css`
        border-color: ${({ theme }) => theme.colors.error};
      `;
    }
  }}
`;

export default InputComponent;
