import { FormInstance, FormProps } from "antd";
import { useWatch } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { Form as AntForm } from "src/modules";
import { dictionary, i18n } from "src/utils";
import { CancelButton, SubmitButton as SubmitButtonStylized } from "./styles";

interface SubmitButtonProps {
  form: FormInstance;
  loading?: boolean;
  setSubmittableExternal?: (value: boolean) => void;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({
  form,
  children,
  loading,
  setSubmittableExternal,
}) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        if (setSubmittableExternal) {
          setSubmittableExternal(true);
        }
        setSubmittable(true);
      })
      .catch((err) => {
        if (setSubmittableExternal) {
          setSubmittableExternal(false);
        }
        setSubmittable(false);
      });
  }, [form, values]);

  return (
    <SubmitButtonStylized
      htmlType="submit"
      disabled={!submittable}
      loading={loading}
    >
      {children}
    </SubmitButtonStylized>
  );
};

type Props = FormProps & {
  onSubmit: (values: any) => void;
  onSubmitFailed?: () => void;
  onCancel: () => void;
  submitText?: string;
  cancelText?: string;
  form: FormInstance<any>;
  children: React.ReactNode;
  loading?: boolean;
  setSubmittable?: (value: boolean) => void;
};

export default function Form({
  onSubmit,
  onSubmitFailed,
  onCancel,
  submitText,
  cancelText,
  children,
  form,
  loading,
  setSubmittable,
  onValuesChange,
  onFieldsChange,
}: Props): JSX.Element {
  return (
    <AntForm
      layout="vertical"
      initialValues={{ remember: true }}
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
      form={form}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      {children}
      <SubmitButton
        form={form}
        loading={loading}
        setSubmittableExternal={setSubmittable}
      >
        {submitText || i18n(dictionary.action.send)}
      </SubmitButton>
      <CancelButton onClick={onCancel}>{cancelText || i18n(dictionary.action.cancel)}</CancelButton>
    </AntForm>
  );
}
