export default {
  TOKEN: "TOKEN",
  USER_NAME: "USER_NAME",
  USER_ID: "USER_ID",
  USER_EMAIL: "USER_EMAIL",
  FINISHED_ADMISSION_ACCESS: "FINISHED_ADMISSION_ACCESS",
  CONTRACT_TEXT: "CONTRACT_TEXT",
  ENCRYPTED_PASSWORD: "2pfGqs5dL0G1eN6N6KpH",
  LATEST_NEW: "LATEST_NEW",
};
