import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

type Props = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

function useEditMode(): Props {
  const [params] = useSearchParams();
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (params.get('id')) setEditMode(true);
    else setEditMode(false);

    return () => {
      setEditMode(false);
    };
  }, [params]);

  return [editMode, setEditMode];
}

export default useEditMode;
