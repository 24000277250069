import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { FinanceApi } from "src/api";
import { Finance as FinanceType } from "src/api/auth/finance.api";
import { Card } from "src/components";
import FinanceAccessDenied from "src/components/Finance/FinanceAccessDenied";
import { Storage } from "src/services";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";

export default function Finance() {
  const [superlogicaToken, setSuperlogicaToken] = useState("");
  const [loadingSuperlogicaToken, setLoadingSuperlogicaToken] = useState(false);

  const [financeData, setFinanceData] = useState<FinanceType | null>(null);
  const [loadingFinanceData, setLoadingFinanceData] = useState(false);

  useEffect(() => {
    getFinanceData();
    getSuperlogicaUserToken();
  }, []);

  async function getSuperlogicaUserToken() {
    setLoadingSuperlogicaToken(true);
    const token = await FinanceApi.getSuperlogicaUserToken(Storage.getUserEmail());
    if (token) {
      setSuperlogicaToken(token);
    }
    setLoadingSuperlogicaToken(false);
  }

  async function getFinanceData() {
    setLoadingFinanceData(true);
    const data = await FinanceApi.getFinanceData();
    if (data) {
      setFinanceData(data);
      setLoadingFinanceData(false);
    } else {
      await createAccount();
    }
  }

  async function createAccount() {
    const created = await FinanceApi.createSuperlogicaUserAccount(
      Storage.getUserName(),
      Storage.getUserEmail(),
      Storage.getEncryptedPassword(),
    );
    if (created) {
      getFinanceData();
      getSuperlogicaUserToken();
    }
  }

  return (
    <Card
      title={capitalizeFirstLetter(i18n(dictionary.label.finance))}
      children={
        loadingSuperlogicaToken || loadingFinanceData ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: 180,
            }}
          >
            <Spin indicator={<LoadingOutlined style={{ fontSize: "30px" }} />} />
          </div>
        ) : (!!financeData?.id && !financeData?.superlogicaId) ||
          financeData?.isClientGroup ||
          financeData?.customer?.accountingId ? (
          <FinanceAccessDenied />
        ) : financeData && !!financeData.customer ? (
          <SuperlogicaIFrame
            src={`https://${process.env.REACT_APP_SUPERLOGICA}.superlogica.net/clients/areadocliente?token=${superlogicaToken}`}
          />
        ) : (
          <SuperlogicaIFrame
            src={`https://${process.env.REACT_APP_SUPERLOGICA}.superlogica.net/clients/areadocliente/publico/grades?id=${process.env.REACT_APP_SUPERLOGICA_GRID_PLANS_ID}&token=${superlogicaToken}`}
          />
        )
      }
    />
  );
}

function SuperlogicaIFrame({ src }: { src: string }) {
  const [loading, setLoading] = useState(true);

  return (
    <div style={{ width: "100%" }}>
      <Spin
        style={{ width: "100%" }}
        indicator={<LoadingOutlined />}
        spinning={loading}
      >
        <iframe
          onLoad={() => setLoading(false)}
          src={src}
          style={{ border: "none" }}
          width="100%"
          height="550vh"
        />
      </Spin>
    </div>
  );
}
