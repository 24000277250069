import { Modal } from "antd";
import { isEmpty } from "lodash";
import React, { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthApi } from "src/api";
import { RoutingContext } from "src/contexts";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import { dictionary, i18n } from "src/utils";

type Props = {
  component: React.ElementType;
  title?: string;
  subtitle?: string;
};

const PrivateRoute: React.FC<Props> = ({ title, subtitle, component: Component, ...rest }) => {
  // const isValidToken = useRef(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const token = Storage.getToken();
  const { setTitle, setSubtitle } = useContext(RoutingContext);

  if (title) setTitle(title);
  if (subtitle) setSubtitle(subtitle);

  async function validateToken() {
    const result = await AuthApi.validateToken();
    // isValidToken.current = result;
    setIsValidToken(result);
    if (!result)
      Modal.info({
        title: i18n(dictionary.expiredSession.title),
        content: i18n(dictionary.expiredSession.content),
      });
  }

  // const dataFetchedRef = useRef(false);
  // useEffect(() => {
  //   if (dataFetchedRef.current) return;
  //   dataFetchedRef.current = true;
  //   validateToken();
  // }, []);

  if (!isEmpty(token) /*&& isValidToken.current  isValidToken*/) return <Component {...rest} />;
  return (
    <Navigate
      to={{
        pathname: Routes.LOGIN,
      }}
    />
  );
};

export default PrivateRoute;
