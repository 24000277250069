import { FormValidator } from 'src/modules';

FormValidator.setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
});

export const loginSchema = FormValidator.object().shape({
  email: FormValidator.string().email('Insira um e-mail válido').required(),
  password: FormValidator.string().required(),
});

const validateLength = (value?: string): boolean =>
  !!(value && value.length >= 6);

export default FormValidator.object().shape({
  cpf: FormValidator.string(),
  email: FormValidator.string()
    .required('E-mail obrigatório')
    .email('E-mail inválido'),
  password: FormValidator.string()
    .required('Senha obrigatória')
    .test('length', 'Senha deve conter pelo menos 6 dígitos', validateLength),
});
