import { CalendarOutlined, FilePdfOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, InputRef, Modal, Radio, TableColumnType } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { LocalStorage } from "lowdb/browser";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import Notifier from "react-desktop-notification";
import Highlighter from "react-highlight-words";
import { Trans } from "react-i18next";
import { addNotification, loadingNotification, removeNotification } from "src/actions/session.action";
import { IntercomApi } from "src/api";
import { Card, Table } from "src/components";
import { Data, LowSyncWithLodash } from "src/components/NotificationsList";
import { REACT_APP_BASE_PATH } from "src/constants";
import { SocketContext } from "src/contexts";
import { useSessionContext } from "src/contexts/session.context";
import { Storage } from "src/services";
import { dictionary, i18n } from "src/utils";
import { INTERCOM_FIELDS } from "src/utils/enums/intercom.enum";
import { v4 } from "uuid";

const { Column } = Table;

type Props = {
  employees: Employee[];
};

type DataIndex = keyof Employee;

function RegistrationForm({ employees }: Props): JSX.Element {
  const [selectedsState, setSelectedsState] = useState<any[]>([]);
  const [recordsState, setRecordsState] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [withPhotos, setWithPhotos] = useState<boolean>(true);
  const socket = useContext(SocketContext);
  const { sessionState, sessionDispatch } = useSessionContext();
  const searchInput = useRef<InputRef>(null);
  const [filterEmployee, setFilterEmployee] = useState("");
  const [filterEmployer, setFilterEmployer] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [filterRegistration, setFilterRegistration] = useState("");
  const [openFilterEmployee, setOpenFilterEmployee] = useState(false);
  const [openFilterEmployer, setOpenFilterEmployer] = useState(false);
  const [openFilterDate, setOpenFilterDate] = useState(false);
  const [openFilterRegistration, setOpenFilterRegistration] = useState(false);

  function selectAll(selected: boolean) {
    const selecteds = selected ? employees.map((item) => item.id) : [];

    setSelectedsState(selecteds);
    setRecordsState(selected ? employees : []);
  }

  function selectRow(record: any) {
    let selecteds = selectedsState;
    let records = recordsState;
    if (selecteds.indexOf(record.key) >= 0) {
      selecteds.splice(selecteds.indexOf(record.key), 1);
      records.splice(records.indexOf(record), 1);
    } else {
      selecteds.push(record.key);
      records.push(record);
    }
    setSelectedsState(selecteds);
    setRecordsState(records);
  }

  const rowSelection = {
    selectedRowKeys: selectedsState,
    onChange: (selecteds: any, records: any) => {
      setSelectedsState(selecteds);
      setRecordsState(records);
    },
  };

  const adapter = new LocalStorage<Data>("qrpoint-notifications");
  const defaultData: Data = {
    notifications: [],
  };
  const db = new LowSyncWithLodash(adapter, defaultData);
  db.read();

  function generateReport() {
    sessionDispatch(loadingNotification(true));
    setLoading(true);
    const registrationFormReportId = v4();
    socket.emit("generate-registration-form-report", {
      id: registrationFormReportId,
      params: {
        registrationFormIdList: selectedsState,
        registrationFormRecordList: recordsState,
        withPhotos,
        locale: i18n("locale"),
      },
    });

    socket.on(registrationFormReportId, (data: any) => {
      const notificationCollection = db.data.notifications;
      let title = "Relatório de ficha cadastral gerada com sucesso!";
      let content =
        selectedsState.length > 1
          ? "O relatório de ficha cadastral foi gerado com sucesso e estará disponível pelos próximos 20 " +
            "minutos. Clique aqui para fazer o download das fichas cadastrais solicitadas!"
          : "O relatório de ficha cadastral foi gerado com sucesso e estará disponível pelos próximos 20 " +
            "minutos. Clique aqui para fazer o download da ficha cadastral de " +
            recordsState[0].name +
            "!";
      let notifier = "Verifique as suas notificações no sistema ou clique aqui para baixar.";
      let link = REACT_APP_BASE_PATH + "/download/" + data.url;

      if (data.error) {
        title = "Não foi possível gerar o espelho de ponto!";
        content = "Ocorreu um erro ao gerar o espelho de ponto. Por favor, tente novamente.";
        notifier = "Verifique as suas notificações no sistema.";
        link = "";

        if (data.errorMessage) {
          content = data.errorMessage;
        }

        Notifier.focus(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      } else {
        Notifier.start(title, notifier, link, window.location.origin + "/res/logo-simples.svg");
      }

      notificationCollection.push({
        userId,
        id: registrationFormReportId,
        expires: moment().add(20, "minutes").valueOf(),
        title,
        content,
        link,
      });
      db.data.notifications = notificationCollection;
      db.write();

      IntercomApi.update(INTERCOM_FIELDS.AD_CREATED_REGISTRATION_FORM);

      sessionDispatch(
        addNotification({
          userId,
          id: registrationFormReportId,
          title,
          content,
          link,
        }),
      );

      sessionDispatch(loadingNotification(false));
      socket.removeListener(registrationFormReportId);
      setTimeout(
        () => {
          sessionDispatch(removeNotification(registrationFormReportId));
        },
        1000 * 60 * 19,
      ); //20 minutos para deletar a notificação

      setLoading(false);
    });
  }

  function GenerateReportAction(): JSX.Element {
    return (
      <Button
        type="primary"
        icon={<FilePdfOutlined />}
        onClick={() => {
          if (selectedsState.length <= 0 || recordsState.length <= 0) {
            Modal.info({ title: "Selecione ao menos um registro para gerar o relatório." });
          } else {
            generateReport();
          }
        }}
        loading={loading}
      >
        Gerar relatório(s)
      </Button>
    );
  }

  const getUserId = async () => setUserId(Storage.getUserId());

  useEffect(() => {
    getUserId();
  }, []);

  function handleSearch(selectedKeys: string[], confirm: FilterDropdownProps["confirm"], dataIndex: DataIndex) {
    confirm();
    switch (dataIndex) {
      case "name":
        setFilterEmployee(selectedKeys[0]);
        setOpenFilterEmployee(false);
        break;
      case "employer":
        setFilterEmployer(selectedKeys[0]);
        setOpenFilterEmployer(false);
        break;
      case "admissionDate":
        setFilterDate(selectedKeys[0]);
        setOpenFilterDate(false);
        break;
      case "registration":
        setFilterRegistration(selectedKeys[0]);
        setOpenFilterRegistration(false);
        break;
    }
  }

  function handleReset(clearFilters: () => void, confirm: FilterDropdownProps["confirm"], dataIndex: DataIndex) {
    clearFilters();
    confirm();
    switch (dataIndex) {
      case "name":
        setFilterEmployee("");
        setOpenFilterEmployee(false);
        break;
      case "employer":
        setFilterEmployer("");
        setOpenFilterEmployer(false);
        break;
      case "admissionDate":
        setFilterDate("");
        setOpenFilterDate(false);
        break;
      case "registration":
        setFilterRegistration("");
        setOpenFilterRegistration(false);
        break;
    }
  }

  function setOpenFilter(dataIndex: DataIndex) {
    switch (dataIndex) {
      case "name":
        setOpenFilterEmployee(true);
        break;
      case "employer":
        setOpenFilterEmployer(true);
        break;
      case "admissionDate":
        setOpenFilterDate(true);
        break;
      case "registration":
        setOpenFilterRegistration(true);
        break;
    }
  }

  function getOpenFilter(dataIndex: DataIndex) {
    switch (dataIndex) {
      case "name":
        return openFilterEmployee;
      case "employer":
        return openFilterEmployer;
      case "admissionDate":
        return openFilterDate;
      case "registration":
        return openFilterRegistration;
    }
  }

  function getFilterText(dataIndex: DataIndex) {
    switch (dataIndex) {
      case "name":
        return filterEmployee;
      case "employer":
        return filterEmployer;
      case "admissionDate":
        return filterDate;
      case "registration":
        return filterRegistration;
    }
  }

  function renderColumnFilter(dataIndex: DataIndex, value: any) {
    switch (dataIndex) {
      case "name":
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ff5e96", padding: 0 }}
            searchWords={[getFilterText(dataIndex) ?? ""]}
            autoEscape
            textToHighlight={value ? value.toString() : ""}
          />
        );
      case "employer":
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ff5e96", padding: 0 }}
            searchWords={[getFilterText(dataIndex) ?? ""]}
            autoEscape
            textToHighlight={value.shortName ? value.shortName.toString() : ""}
          />
        );
      case "admissionDate":
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ff5e96", padding: 0 }}
            searchWords={[getFilterText(dataIndex) ?? ""]}
            autoEscape
            textToHighlight={value ? moment(value).format(i18n(dictionary.dateFormat)) : "Admissão não concluída"}
          />
        );
      case "registration":
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ff5e96", padding: 0 }}
            searchWords={[getFilterText(dataIndex) ?? ""]}
            autoEscape
            textToHighlight={value ? value.toString() : "Não preenchida"}
          />
        );
    }
  }

  function getColumnCalendar(dataIndex: DataIndex): TableColumnType<Employee> {
    return {
      dataIndex,
      filterMultiple: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          className="custom-filter-dropdown"
          style={{
            padding: 8,
            borderRadius: 4,
            boxShadow: "0 2px 8px rgba(0, 0, 0, .15)",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker
            value={selectedKeys[0] ? dayjs(selectedKeys[0], "DD/MM/YYYY") : ""}
            allowClear
            format={["DD/MM/YYYY"]}
            style={{ width: 188, marginBottom: 8, display: "block" }}
            onChange={(value) => {
              setSelectedKeys(value ? [dayjs(value).format("DD/MM/YYYY")] : []);
            }}
            autoFocus
          />
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <Trans i18nKey={dictionary.label.search} />
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            <Trans i18nKey={dictionary.action.clear} />
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{ color: filtered ? "#884ea6" : undefined }}
          onClick={() => setOpenFilter(dataIndex)}
        />
      ),
      onFilter: (value, record) => {
        return dayjs(record[dataIndex])
          .format("DD/MM/YYYY")
          .toString()
          .includes(value as string);
      },
      filterDropdownOpen: getOpenFilter(dataIndex),
      render: (date: Date) => {
        return renderColumnFilter(dataIndex, date);
      },
    };
  }

  function getColumnSearchProps(dataIndex: DataIndex): TableColumnType<Employee> {
    return {
      dataIndex,
      filterMultiple: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          className="custom-filter-dropdown"
          style={{
            padding: 8,
            borderRadius: 4,
            boxShadow: "0 2px 8px rgba(0, 0, 0, .15)",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={i18n(dictionary.label.search)}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys as string[], confirm, dataIndex);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <Trans i18nKey={dictionary.label.search} />
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            <Trans i18nKey={dictionary.action.clear} />
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? "#884ea6" : undefined }}
          onClick={() => setOpenFilter(dataIndex)}
        />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput?.current?.select());
        }
      },
      onFilter: (value, record) => {
        if (dataIndex === "employer") {
          return record[dataIndex].shortName
            ? record[dataIndex].shortName
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
            : false;
        } else {
          return record[dataIndex]
            ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
            : false;
        }
      },
      filterDropdownOpen: getOpenFilter(dataIndex),
      render: (value) => {
        return renderColumnFilter(dataIndex, value);
      },
    };
  }

  return (
    <Card
      title="Listagem de fichas cadastrais"
      actions={
        <div>
          <Radio.Group
            style={{ marginRight: "15px" }}
            value={withPhotos}
            optionType="button"
            options={[
              { label: "Com arquivos de foto", value: true },
              { label: "Sem arquivos de foto", value: false },
            ]}
            onChange={({ target }) => setWithPhotos(target.value)}
          />
          <GenerateReportAction />
        </div>
      }
    >
      <Table
        scroll={{ x: true }}
        dataSource={employees}
        rowSelection={rowSelection}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowKey="id"
      >
        <Column
          title="Nome do colaborador"
          sorter={(a: Employee, b: Employee) => a.name.localeCompare(b.name)}
          {...getColumnSearchProps("name")}
        />
        <Column
          title="Empregador"
          sorter={(a: Employee, b: Employee) => a.employer.shortName.localeCompare(b.employer.shortName)}
          {...getColumnSearchProps("employer")}
        />
        <Column
          title={i18n(dictionary.label.admission_date)}
          sorter={(a: Employee, b: Employee) => dayjs(a.admissionDate).diff(dayjs(b.admissionDate))}
          {...getColumnCalendar("admissionDate")}
        />
        <Column
          title="Matrícula"
          sorter={(a: Employee, b: Employee) => a.registration?.localeCompare(b.registration || "") || 0}
          {...getColumnSearchProps("registration")}
        />
      </Table>
    </Card>
  );
}

export default RegistrationForm;
