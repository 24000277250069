import { useReducer } from "react";
import { NotificationActionKind, SessionAction, SessionActionKind, SessionState } from "src/actions/session.action";

const initialSession = {
  auth: false,
  key: "",
  userName: "",
  userNameTopBar: "",
  email: "",
  notificationBadge: 0,
  notificationList: [],
  loadingNotifications: false,
  userId: 0,
  planId: 0,
  functionalities: {},
  userProfile: {
    type: "user",
    config: "{}",
  },
  employeeCount: 0,
  companyName: "",
  cnpj: "",
  phone: "",
  type: 1,
  freeTrial: false,
  freeTrialDaysLeft: 0,
  blockedAccess: false,
  inActivation: false,
  token: "",
  newFeaturesInteracted: [],
};

const sessionReducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case SessionActionKind.SET_SESSION:
      return {
        ...state,
        ...action.payload,
      };
    case NotificationActionKind.ADD_NOTIFICATION:
      if (action.notification) {
        return {
          ...state,
          notificationList: [action.notification, ...state.notificationList],
          notificationBadge: state.notificationList.length + 1,
        };
      } else return state;
    case NotificationActionKind.REMOVE_NOTIFICATION:
      const notificationListAfterRemove = state.notificationList.filter(
        (notification) => notification.id !== action.notificationId,
      );
      return {
        ...state,
        notificationList: state.notificationList.filter((notification) => notification.id !== action.notificationId),
        notificationBadge: notificationListAfterRemove.length,
      };
    case NotificationActionKind.LOADING_NOTIFICATION:
      return {
        ...state,
        loadingNotifications: !!action.notificationLoading,
      };
    default:
      return state;
  }
};

export function useSessionReducer() {
  return useReducer(sessionReducer, initialSession);
}
