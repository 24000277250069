import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  width: 100%;

  > table {
    width: 100%;
  }
`;
