import { FileDoneOutlined, FileTextOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { Card, Table as ContractsTable, Form, FormItem, If, TableLogs, Tabs } from "src/components";
import RichTextEditor from "src/components/RichTextEditor/RichTextEditor";
import { Storage } from "src/services";
import { CONTRACT_TEXT_FIELD, capitalizeFirstLetter, dictionary, i18n } from "src/utils";

const { Option } = Select;
const { Column } = ContractsTable;

type Props = {
  onGoBack(): void;
  onFinish(values: any): void;
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  relationships: EmploymentRelationshipCode[];
  contract: Contract;
  addContract(): void;
  removeContract(key: number): void;
  updateContract<PropertyType extends keyof Contract>(
    key: number,
    property: PropertyType,
    value: Contract[PropertyType],
  ): void;
  editMode: boolean;
  contractLogs: ChangeHistory[];
  viewMode: boolean;
};

function ContractEdit({
  onGoBack,
  onFinish,
  tabs,
  currentTab,
  setCurrentTab,
  relationships,
  contract,
  addContract,
  removeContract,
  updateContract,
  editMode,
  contractLogs,
  viewMode,
}: Props): JSX.Element {
  const [form] = useForm();
  const [text, setText] = useState<string>();

  useEffect(() => {
    if (contract.id !== 0) {
      form.setFieldsValue(contract);
      Storage.setContractText(JSON.parse(contract.text));
      setText(contract.text);
    } else {
      Storage.setContractText([
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ]);
    }
  }, [contract]);

  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <Form
          form={form}
          onSubmit={() => onFinish(form.getFieldsValue())}
          onCancel={onGoBack}
          submitText={
            editMode
              ? i18n(dictionary.action.update_save)
              : i18n(dictionary.action.create_item, {
                  item: i18n(dictionary.entity.contract),
                })
          }
        >
          <Card
            title={i18n(dictionary.label.itemSettings, {
              item: i18n(dictionary.entity.contract),
            })}
            icon={<FileDoneOutlined />}
          >
            <FormItem
              label={capitalizeFirstLetter(i18n(dictionary.label.description))}
              name="description"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input />
            </FormItem>
            {/* <FormItem
              label={i18n(dictionary.label.itemType, { item: i18n(dictionary.entity.contract) })}
              name="contractType"
              rules={[{ required: false }]}
            >
              <Select>
                {contracts.map((contract) => (
                  <Option
                    key={contract.id}
                    value={contract.id}
                  >
                    {contract.name}
                  </Option>
                ))}
              </Select>
            </FormItem> */}
          </Card>
          <Card
            title={capitalizeFirstLetter(i18n(dictionary.entity.contract))}
            icon={<FileTextOutlined />}
          >
            <FormItem
              fullWidth
              rules={[{ required: false }]}
              name="text"
            >
              {text && (
                <RichTextEditor
                  content={JSON.parse(text)}
                  fieldList={fieldList}
                  style={{
                    height: 290,
                  }}
                  readOnly={viewMode}
                />
              )}
            </FormItem>
          </Card>
        </Form>
      </If>
      <If condition={currentTab === 2}>
        <TableLogs logs={contractLogs} />
      </If>
    </>
  );
}

const fieldList: CONTRACT_TEXT_FIELD[] = [
  CONTRACT_TEXT_FIELD.EMPLOYER_NAME,
  CONTRACT_TEXT_FIELD.EMPLOYER_IDENTIFICATION,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_STREET,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_NUMBER,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_DISTRICT,
  CONTRACT_TEXT_FIELD.EMPLOYER_ADDRESS_ZIP_CODE,
  CONTRACT_TEXT_FIELD.EMPLOYEE_NAME,
  CONTRACT_TEXT_FIELD.EMPLOYEE_NATIONALITY,
  CONTRACT_TEXT_FIELD.EMPLOYEE_MARITAL_STATUS,
  CONTRACT_TEXT_FIELD.EMPLOYEE_OCCUPATION,
  CONTRACT_TEXT_FIELD.EMPLOYEE_IDENTIFICATION_1,
  CONTRACT_TEXT_FIELD.EMPLOYEE_IDENTIFICATION_2,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADDRESS_STREET,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_DAY,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_FULL_MONTH,
  CONTRACT_TEXT_FIELD.EMPLOYEE_ADMISSION_YEAR,
  CONTRACT_TEXT_FIELD.EMPLOYER_CITY,
  CONTRACT_TEXT_FIELD.CONTRACT_DATE,
];

export default ContractEdit;
