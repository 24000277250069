import { LoadingOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Form, Input, Spin, Typography, notification } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AuthApi } from "src/api";
import GradientBackground from "src/components/GradientBackground";
import CustomLink from "src/components/Text/CustomLink";
import { Routes } from "src/routes";
import { light } from "src/styles/themes";
import { dictionary, getDescryptedParamsData, i18n } from "src/utils";
import "./ResetPassword.css";

const { Item } = Form;
const { Title } = Typography;
const { Password } = Input;

export default function ResetPassword() {
  const { t } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  async function handleSubmit() {
    setSending(true);
    notification.destroy();
    let error = "";
    if (password.length < 8) {
      error = "invalid_password";
    } else if (password !== passwordConfirmation) {
      error = "invalid_password_confirmation";
    }
    if (error.length) {
      setError(error);
      notification.error({
        message: i18n(dictionary.ops),
        description: t(`pages.resetPassword.notifications.${error}`),
      });
      setSending(false);
      return;
    }
    setError("");
    await AuthApi.resetPassword(userId, password)
      .then((response) => {
        if (response.success) {
          notification.success({
            message: i18n(dictionary.alright),

            description: i18n(dictionary.pages.resetPassword.notifications.success),
          });
        } else {
          notification.error({
            message: i18n(dictionary.ops),
            description: i18n(dictionary.pages.resetPassword.notifications.error),
          });
        }
      })
      .catch((_) => {
        notification.error({
          message: i18n(dictionary.ops),
          description: i18n(dictionary.pages.resetPassword.notifications.error),
        });
      });
    setSuccess(true);
    setSending(false);
  }

  function getParamsData() {
    setLoading(true);
    const data = getDescryptedParamsData(params.data || "");
    if (data) {
      if (dayjs() >= dayjs(data.expiresOn)) {
        navigate(Routes.FORGOT_PASSWORD, { replace: true });
      }
      setUserId(data.userId);
      setName(data.name);
      setEmail(data.email);
    }
    setLoading(false);
  }

  useEffect(() => {
    getParamsData();
  }, []);

  return (
    <GradientBackground>
      <Card className="reset-password-card">
        <Form
          style={{ width: 300 }}
          layout="vertical"
        >
          <Title
            style={{
              textAlign: "center",
              marginBottom: 15,
              color: light.colors.brand.primary.light,
            }}
            level={4}
          >
            {t("pages.resetPassword.title")}
          </Title>
          <div style={{ marginBottom: 15 }}>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <Spin indicator={<LoadingOutlined />} />
              </div>
            ) : (
              <div style={{ textAlign: "justify" }}>
                {t("pages.resetPassword.subtitle1")}
                <span style={{ fontWeight: 600 }}>{name}</span>
                {t("pages.resetPassword.subtitle2")}
                <span style={{ fontWeight: 600 }}>{email}</span>.
              </div>
            )}
          </div>
          <Item validateStatus={error === "invalid_password" ? "error" : "validating"}>
            <Password
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              placeholder={t("pages.resetPassword.fields.password")}
              prefix={<LockOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginRight: 4 }} />}
            />
          </Item>
          <Item validateStatus={error === "invalid_password_confirmation" ? "error" : "validating"}>
            <Password
              value={passwordConfirmation}
              onChange={({ target }) => setPasswordConfirmation(target.value)}
              placeholder={t("pages.resetPassword.fields.passwordConfirmation")}
              prefix={<LockOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginRight: 4 }} />}
            />
          </Item>
          <Item style={{ textAlign: "center" }}>
            <Button
              disabled={!password.length || !passwordConfirmation.length}
              type="primary"
              htmlType="submit"
              loading={sending}
              onClick={handleSubmit}
            >
              {t(`pages.resetPassword.fields.${sending ? "loadingButton" : "button"}`)}
            </Button>
          </Item>
          {success && (
            <Flex justify="center">
              <CustomLink
                text={t("pages.forgotPassword.fields.backToLoginPage")}
                path={Routes.LOGIN}
              />
            </Flex>
          )}
        </Form>
      </Card>
    </GradientBackground>
  );
}
