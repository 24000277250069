import { action, makeObservable, observable } from 'mobx';
import { persist } from 'mobx-persist';

export default class AuthStore {
  @persist('object')
  @observable
  selectedMenu: string[] = ['1'];

  constructor() {
    makeObservable(this);
  }

  @action
  setSelectedMenu = (key: string, sub?: string): void => {
    if (sub) {
      this.selectedMenu = [sub, key];
    } else {
      this.selectedMenu = [key];
    }
  };
}
