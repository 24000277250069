import { LoadingOutlined, PlusOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Row, Tooltip, Typography, Upload, UploadFile, UploadProps, message } from "antd";
import { RcFile } from "antd/es/upload";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { EmployerApi } from "src/api";
import { ContainerOutlined, EnvironmentOutlined } from "src/assets/icons";
import { Card, Form, FormItem, If, InputGroup, SelectInsert, Tabs } from "src/components";
import CustomCnpjInput from "src/components/Input/CustomCnpjInput";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { REACT_APP_BASE_PATH } from "src/constants";
import { Input, Select } from "src/modules";
import { ExpiredSessionError, capitalizeFirstLetter, dictionary, i18n } from "src/utils";

const { Option } = Select;
const { Text, Link } = Typography;

type Props = {
  onGoBack(): void;
  onFinish(values: any): void;
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  departments: Department[];
  insertDepartment(value: string): void;
  benefits: Benefit[];
  insertBenefit(value: string): void;
  countryStates: CountryState[];
  editMode: boolean;
  employer: Employer;
};

function EmployerForm({
  onGoBack,
  onFinish,
  tabs,
  currentTab,
  setCurrentTab,
  departments,
  insertDepartment,
  benefits,
  insertBenefit,
  countryStates,
  editMode,
  employer,
}: Props): JSX.Element {
  const [form] = useForm();
  const [zipCode, setZipCode] = useState<string>();
  const [taxId, setTaxId] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>();
  const [validImage, setValidImage] = useState<boolean>();
  const [fileURL, setFileUrl] = useState<string>();
  const [fileModified, setFileModified] = useState<boolean>();
  const [uploaded, setUploaded] = useState<boolean>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [file, setFile] = useState("");
  const [urlUploadedFile, setUrlUploadedFile] = useState<string>("");
  const { show } = useIntercom();
  const [certificate, setCertificate] = useState<ArrayBuffer | string>();

  useEffect(() => {
    form.setFieldsValue({
      ...employer,
      benefits: employer?.benefits?.map((benefit) => benefit.id),
      departments: employer?.departments?.map((department) => department.id),
      address: employer.address,
    });
    setZipCode(employer?.address?.zipCode ?? "");
    setTaxId(employer?.taxId);
    if (employer.digitalCertificateName) {
      setFileList([
        {
          uid: "rc-upload-1710017424473-3",
          name: employer.digitalCertificateName,
          type: "application/x-pkcs12",
          percent: 100,
          status: "done",
          response: {
            success: true,
          },
        },
      ]);
    }
  }, [employer]);

  function employerImage() {
    return (
      <div>
        <img
          alt="imagem empregador"
          style={{ width: 100, maxHeight: 100 }}
          src={fileURL}
          onError={(e) => {
            e.currentTarget.style.visibility = "hidden";
            setValidImage(false);
          }}
        />
      </div>
    );
  }

  function uploadButton() {
    return (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <p>{i18n(dictionary.label.employer_logo_image_placeholder)}</p>
      </div>
    );
  }

  function getBase64(img: any, callback: (result: any) => void) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleUpload(info: any) {
    if (info.event) {
      getBase64(info.file.originFileObj, (fileURL) => {
        const fileBuffer = fileURL.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*\;(base64),)/g, "");
        setFile(fileBuffer);
        EmployerApi.uploadFile(fileBuffer, employer.id)
          .then((res: any) => {
            setUrlUploadedFile(REACT_APP_BASE_PATH + "/download/" + res.url);
          })
          .catch((error) => {
            if (!(error instanceof ExpiredSessionError)) {
              GoodErrorMessage({
                title: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.title),
                message: i18n(dictionary.error.ATTACH_DOCUMENT_ERROR.message),
                onClickLink: () => show(),
                onOk: () => handleUpload(info),
              });
            }
            console.error(error);
          });
        setFileModified(true);
        setUploading(false);
        setValidImage(true);
        setUploaded(true);
      });
    }
  }

  const bytesToMb = (bytes: number) => bytes / 1000000;
  const fileIsTooLarge = (file: any) => bytesToMb(file.size) > 1;

  const propsUploadFile: UploadProps = {
    name: "digitalCertificateFile",
    maxCount: 1,
    listType: "picture",
    beforeUpload: (file: RcFile) => {
      const isDigitalCertificate = file.type.includes("pkcs12");

      if (!isDigitalCertificate) {
        message.error("O certificado deve ser do tipo p12");
        return false;
      }

      if (fileIsTooLarge(file)) {
        message.error(i18n(dictionary.error.ATTACH_DOCUMENT_ERROR_SIZE.message));
        return false;
      }

      const fileName = file.name;
      form.setFieldValue("digitalCertificateName", fileName);

      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const fileContent = e.target?.result; // PKCS #12 file content
      //   // Extract certificate and private key here (using OpenSSL, for example)
      //   // Store them in your application (e.g., state or variable)
      //   if (fileContent) setCertificate(fileContent);
      // };
      // reader.readAsArrayBuffer(file); // Read file content

      return true;
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      setFileList(info.fileList);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.event) {
        getBase64(info.file.originFileObj, (fileURL) => {
          const fileBuffer = fileURL.replace(/(data:application\/(x-pkcs12)*(pkcs12)*(pfx)*\;(base64),)/g, "");
          // EmployerApi.uploadCertificate(certificate, employer.id)
          EmployerApi.uploadCertificate(fileBuffer, employer.id)
            .then((res: any) => {
              message.success(i18n(dictionary.success.IMPORT_CERTIFICATE_SUCCESS.message, { file: info.file.name }));
            })
            .catch((error) => {
              if (!(error instanceof ExpiredSessionError)) {
                message.error(i18n(dictionary.error.DIGITAL_CERTIFICATE_ERROR.message, { file: info.file.name }));
              }
              console.error(error);
            });
        });
      }
    },
    fileList: fileList,
    onRemove(file) {
      form.setFieldValue("digitalCertificateName", "");
    },
  };

  function handleSubmit(values: any) {
    debugger;
    values.taxId = taxId.replace(/[./-]/g, "");
    onFinish({ ...values });
  }

  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <Form
          form={form}
          onSubmit={(values) => handleSubmit(values)}
          onCancel={onGoBack}
          submitText={
            editMode
              ? i18n(dictionary.action.update_save)
              : i18n(dictionary.action.create_item, {
                  item: i18n(dictionary.entity.employer),
                })
          }
        >
          <Card
            icon={<ContainerOutlined />}
            title={i18n(dictionary.label.employer_data)}
          >
            {/* <FormItem
              fullWidth
              name="logo"
              rules={[{ required: false }]}
            >
              <LogoUpload
                listType="picture-card"
                multiple={false}
                showUploadList={false}
                accept={"image/x-png,image/jpeg"}
                onChange={handleUpload}
              >
                {validImage ? employerImage() : uploadButton()}
              </LogoUpload>
            </FormItem> */}

            <FormItem
              label={i18n(dictionary.label.company_name)}
              name="fullName"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.cnpj)}
              name="taxId"
              rules={[{ required: true, message: i18n(dictionary.message.cnp_rule), min: 18, max: 18 }]}
            >
              <CustomCnpjInput
                cnpj={taxId}
                form={form}
                setCnpj={setTaxId}
                placeholder="00.000.000/0000-00"
              />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.trading_name)}
              name="shortName"
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.owner_name)}
              name="manager"
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.owner_cpf)}
              name="managerTaxId"
              rules={[{ required: false }]}
            >
              {/* <MaskedInput mask="000.000.000-00" /> */}
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.departments)}
              name="departments"
              rules={[{ required: false }]}
            >
              <SelectInsert
                mode="multiple"
                onInsert={insertDepartment}
              >
                {departments.map((department) => (
                  <Option
                    key={department.id}
                    title={department.description}
                    value={department.id}
                  >
                    {department.description}
                  </Option>
                ))}
              </SelectInsert>
            </FormItem>

            <FormItem
              label={capitalizeFirstLetter(i18n(dictionary.label.benefit))}
              name="benefits"
              rules={[{ required: false }]}
            >
              <SelectInsert
                mode="multiple"
                onInsert={(value) => insertBenefit(value)}
              >
                {benefits.map((benefit) => (
                  <Option
                    key={benefit.id}
                    title={benefit.name}
                    value={benefit.id}
                  >
                    {benefit.name}
                  </Option>
                ))}
              </SelectInsert>
            </FormItem>
          </Card>

          <Card
            title={i18n(dictionary.label.address)}
            icon={<EnvironmentOutlined />}
          >
            <FormItem
              label={i18n(dictionary.label.address_zip_code)}
              name={["address", "zipCode"]}
              rules={[{ required: false }]}
            >
              {/* <MaskedInput
                mask="00000-000"
                value={zipCode}
                defaultValue={zipCode}
                onChange={({ target }) => {
                  setZipCode(target.value);
                }}
              /> */}
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.address_street)}
              name={["address", "publicPlace"]}
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem>
              <InputGroup>
                <FormItem
                  label={i18n(dictionary.label.address_number)}
                  name={["address", "addressNumber"]}
                  rules={[{ required: false }]}
                >
                  {/* <InputNumber
                    min={0}
                    controls={false}
                  /> */}
                  <Input />
                </FormItem>

                <FormItem
                  label={i18n(dictionary.label.address_complement)}
                  name={["address", "complement"]}
                  rules={[{ required: false }]}
                >
                  <Input />
                </FormItem>
              </InputGroup>
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.address_district)}
              name={["address", "zone"]}
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.address_city)}
              name={["address", "city"]}
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.address_state)}
              name={["address", "state"]}
              rules={[{ required: false }]}
            >
              <Select>
                {countryStates.map((countryState) => (
                  <Option
                    key={countryState.abbreviation}
                    title={countryState.name}
                    value={countryState.abbreviation}
                  >
                    {countryState.name}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Card>
          <Card
            title={i18n(dictionary.label.e_social_integration)}
            icon={<EnvironmentOutlined />}
          >
            <FormItem
              name="digitalCertificateName"
              style={{ display: "none" }}
            />
            <FormItem
              label={
                <Row>
                  {i18n(dictionary.label.digital_certificate_a1)}
                  <Tooltip title={i18n(dictionary.message.digital_certificate_a1)}>
                    <QuestionCircleOutlined style={{ marginLeft: 10 }} />
                  </Tooltip>
                </Row>
              }
              name={"digitalCertificateFile"}
              rules={[{ required: false }]}
            >
              {fileList.length > 0 ? (
                <Upload {...propsUploadFile} />
              ) : (
                <Upload {...propsUploadFile}>
                  <Button icon={<UploadOutlined />}>Upload do certificado</Button>
                </Upload>
              )}
            </FormItem>
          </Card>
        </Form>
      </If>
    </>
  );
}

export default EmployerForm;
