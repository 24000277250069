import { Button, Form } from 'antd';
import styled from 'styled-components';

export const SubmitButton = styled(Button).attrs({
  type: 'primary',
})``;

export const CancelButton = styled(Button)`
  margin-left: 16px;
`;

export const FormItem = styled(Form.Item)<{ fullWidth?: boolean | undefined }>`
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? 'unset' : '480px')};
  min-width: 220px;
  margin-right: 32px;

  > * {
    width: 100%;
  }
  .ant-picker,
  .ant-input-number-group-wrapper,
  .ant-input-number {
    width: 100%;
  }
`;

export const InputGroup = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;

  > * {
    margin-bottom: 0;
    margin-right: 12px;
  }
  > *:last-child {
    margin-right: 0;
  }
  > div {
    min-width: unset;
  }
`;
