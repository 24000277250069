import React, { useContext } from "react";

import { RoutingContext } from "src/contexts";
import DownloadContract from "./DownloadContract";

const DownloadContractContainer: React.FC = () => {
  const { setTitle, setSubtitle } = useContext(RoutingContext);
  setTitle("Sistema de admissões");
  setSubtitle("Dashboard");

  return <DownloadContract />;
};

export default DownloadContractContainer;
