import { message } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';

export const getBase64 = (
  img: RcFile,
  callback: (url: string) => void,
): void => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file: RcFile): boolean => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

export const formatToBase64 = (value: string): string => {
  if (!value) return '';

  return `data:image/png;base64,${value}`;
};
