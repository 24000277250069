import { useEffect, useState } from "react";

import { Modal, message } from "antd";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import UserForm from "./UserForm";

function UserFormContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const tabs = [
    {
      index: 1,
      title: editMode ? "Editar usuário" : "Novo usuário",
    },
  ];

  useEffect(() => {
    if (params.get("id")) setEditMode(true);
    else setEditMode(false);

    return () => {
      setEditMode(false);
    };
  }, [params]);

  const onGoBack = () => navigate(-1);

  const onSubmit = () => {
    if (editMode) return onUpdateUser();
    return onCreateUser();
  };

  const onCreateUser = () => {
    const onFinishCreateUser = () => {
      message.success({
        content: "Usuário criado com sucesso.",
      });
      return onGoBack();
    };

    Modal.warning({
      title: "Atenção",
      content: `Ao criar um usuário, o e-mail cadastrado receberá uma mensagem contendo o link de acesso ao sistema. O Login será feito através da senha previamente cadastrada, após isso o usuário poderá modificar sua senha dentro do sistema.\n
      Toda e qualquer alteração feita dentro do sistema por esse acesso irá constar no histórico da funcionalidade alterada.\n
      Usuários de acessos não tem permissão para criar novos acessos.`,
      width: 540,
      onOk: () => onFinishCreateUser(),
      okCancel: true,
    });
  };

  const onUpdateUser = () => {
    message.success({
      content: "Usuário alterado com sucesso.",
    });
    return onGoBack();
  };

  return (
    <UserForm
      onGoBack={onGoBack}
      onFinish={onSubmit}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      editMode={editMode}
    />
  );
}
export default UserFormContainer;
