import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import {
  BenefitApi,
  ChangeHistoryApi,
  DepartmentApi,
  DependentApi,
  EmployeeApi,
  EmployeeNoteApi,
  EmployerApi,
  EmploymentRelationshipApi,
  UserApi,
} from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import {
  activityNature,
  admissionIndicator,
  admissionType,
  countryOfBirth,
  countryOfNationality,
  countryStates,
  disabilities,
  educationLevel,
  genders,
  jobOccupations,
  journeyRegime,
  // journeyType,
  laborRegime,
  legalHypothesisTemporaryWorker,
  maritalStatuses,
  monthProfessionalCategory,
  partTimeContract,
  paymentUnitRemuneration,
  pensionRegime,
  races,
  registrationType,
  typeEmploymentContract,
  typeEvent,
  typeOfWorkingDay,
  workerCategory,
} from "src/data";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import EmployeeEdit from "./EmployeeEdit";

function EmployeeEditContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(1);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [notes, setNotes] = useState<EmployeeNote[]>([]);
  const [dependents, setDependents] = useState<Dependent[]>([]);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showLogsModal, setShowLogsModal] = useState(false);
  const [managerUsers, setManagerUsers] = useState<User[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [employers, setEmployers] = useState<Employer[]>([]);
  const [employee, setEmployee] = useState<Employee>();
  const [employeeLogs, setEmployeeLogs] = useState<ChangeHistory[]>([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useSearchParams();

  const navigate = useNavigate();

  const tabs: TabsData[] = [
    {
      index: 1,
      title: i18n(dictionary.label.personal_data),
    },
    {
      index: 2,
      title: i18n(dictionary.label.admission),
    },
    {
      index: 3,
      title: i18n(dictionary.label.document, { count: 2 }),
    },
    {
      index: 4,
      title: i18n(dictionary.label.notes),
    },
    {
      index: 5,
      title: i18n(dictionary.label.history),
    },
  ];

  const insertNote = (note: EmployeeNote) => {
    const newNoteRecord: EmployeeNote = {
      ...note,
      id: 0,
    };
    setNotes([...notes, newNoteRecord]);
    setShowNotesModal(false);
  };

  const deleteNote = (id: number) => {
    const updatedNotes = notes.filter((note) => note.id !== id);
    setNotes(updatedNotes);
  };

  const updateNote = (updatedNote: EmployeeNote) => {
    const noteIndex = notes.findIndex((note) => note.id === updatedNote.id);
    if (noteIndex > -1) {
      notes.splice(noteIndex, 1, { ...updatedNote });
      setNotes([...notes]);
    } else {
      throw new Error("Não foi possível atualizar a anotação.");
    }
  };

  const insertBenefit = (benefit: string, employerId: string) => {
    BenefitApi.create({ id: 0, name: benefit, employerId })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.benefit),
          }),
        });
        setBenefits([...benefits, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.CREATE_BENEFIT_ERROR.message),
            onOk: () => insertBenefit(benefit, employerId),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const insertDepartment = (department: string, employerId: string) => {
    DepartmentApi.create({ id: 0, description: department, employerId })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.department),
          }),
        });
        setDepartments([...departments, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.message),
            onOk: () => insertDepartment(department, employerId),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const insertDependent = (dependent: Dependent) => {
    DependentApi.create(dependent)
      .then(async (res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.dependent),
          }),
        });
        await getEmployeeDependent();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_E_SOCIAL_DEPENDENT_ERROR.title),
            message: i18n(dictionary.error.CREATE_E_SOCIAL_DEPENDENT_ERROR.message),
            onOk: () => insertDependent(dependent),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const onGoBack = (): void => {
    navigate(-1);
  };

  const onUpdateEmployee = (values: any): void => {
    setLoading(true);
    EmployeeApi.updateById(employee!.id, values)
      .then(async (res) => {
        await getEmployee();
        setLoading(false);
        message.success({
          content: i18n(dictionary.prompt.updated_success, {
            context: context.male,
            item: i18n(dictionary.entity.employee),
          }),
        });
      })
      .catch((error) => {
        setLoading(false);
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.UPDATE_EMPLOYEE_ERROR.message),
            onOk: () => onUpdateEmployee(values),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const onUpdateDependent = (values: any): void => {
    debugger;
    DependentApi.updateById(values.id, values)
      .then(async (res) => {
        message.success({
          content: i18n(dictionary.prompt.updated_success, {
            context: context.male,
            item: i18n(dictionary.label.dependent),
          }),
        });
        await getEmployeeDependent();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_E_SOCIAL_DEPENDENT_ERROR.title),
            message: i18n(dictionary.error.UPDATE_E_SOCIAL_DEPENDENT_ERROR.message),
            onOk: () => onUpdateDependent(values),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const { show } = useIntercom();
  const [employmentRelationships, setEmploymentRelationships] = useState<EmploymentRelationship[]>([]);

  async function getEmploymentRelationships(): Promise<boolean> {
    return await EmploymentRelationshipApi.fetch()
      .then((res) => {
        setEmploymentRelationships(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYMENT_RELATIONSHIP_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getDepartments(): Promise<boolean> {
    return await DepartmentApi.fetch()
      .then((res: Department[]) => {
        setDepartments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.GET_DEPARTMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getManagerUsers(): Promise<boolean> {
    return await UserApi.fetchUser()
      .then((res: User[]) => {
        setManagerUsers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_MANAGER_USER_ERROR.title),
            message: i18n(dictionary.error.GET_MANAGER_USER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployee(): Promise<boolean> {
    return await EmployeeApi.fetchById(parseInt(params.get("id") ?? "0"))
      .then((res: Employee) => {
        setEmployee(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYEE_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYEE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployers(): Promise<boolean> {
    return await EmployerApi.fetch()
      .then((res: Employer[]) => {
        setEmployers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getBenefits(): Promise<boolean> {
    return await BenefitApi.fetch()
      .then((res: Benefit[]) => {
        setBenefits(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.GET_BENEFIT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getChangeHistory(): Promise<boolean> {
    return await ChangeHistoryApi.fetchByTable("fun_funcionario", params.get("id") ?? "0")
      .then((res: ChangeHistory[]) => {
        setEmployeeLogs(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.title),
            message: i18n(dictionary.error.GET_CHANGE_HISTORY_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployeeNotes(): Promise<boolean> {
    return await EmployeeNoteApi.fetchByEmployeeId(parseInt(params.get("id") ?? "0"))
      .then((res: EmployeeNote[]) => {
        setNotes(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYEE_NOTE_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYEE_NOTE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployeeDependent(): Promise<boolean> {
    return await DependentApi.fetchByEmployeeId(parseInt(params.get("id") ?? "0"))
      .then((res: any) => {
        setDependents(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_E_SOCIAL_DEPENDENT_ERROR.title),
            message: i18n(dictionary.error.GET_E_SOCIAL_DEPENDENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmploymentRelationships();
      await getDepartments();
      await getBenefits();
      await getManagerUsers();
      await getEmployers();
      await getEmployee();
      await getChangeHistory();
      await getEmployeeNotes();
      // await getEmployeeDependent();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <>
      {employee && (
        <EmployeeEdit
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          genders={genders}
          maritalStatuses={maritalStatuses}
          educationLevels={educationLevel}
          countrysOfBirth={countryOfBirth}
          countrysOfNationality={countryOfNationality}
          races={races}
          disabilities={disabilities}
          workerCategories={workerCategory}
          laborRegimes={laborRegime}
          pensionRegimes={pensionRegime}
          paymentUnitsRemuneration={paymentUnitRemuneration}
          typesEvent={typeEvent}
          admissionTypes={admissionType}
          admissionIndicators={admissionIndicator}
          journeisRegime={journeyRegime}
          activityNatures={activityNature}
          monthProfessionalCategories={monthProfessionalCategory}
          typesEmploymentContract={typeEmploymentContract}
          typesOfWorkingDay={typeOfWorkingDay}
          registrationTypes={registrationType}
          partTimeContracts={partTimeContract}
          legalHypothesisTemporaryWorker={legalHypothesisTemporaryWorker}
          employmentRelationships={employmentRelationships}
          jobOccupationRegisters={jobOccupations}
          employers={employers}
          departments={departments}
          insertBenefit={insertBenefit}
          benefits={benefits}
          managers={managerUsers}
          countryStates={countryStates}
          userNotes={notes}
          showNotesModal={showNotesModal}
          setShowNotesModal={setShowNotesModal}
          insertNote={insertNote}
          deleteNote={deleteNote}
          updateNote={updateNote}
          employeeLogs={employeeLogs}
          showLogsModal={showLogsModal}
          setShowLogsModal={setShowLogsModal}
          onGoBack={onGoBack}
          onUpdateEmployee={onUpdateEmployee}
          employee={employee}
          setEmployee={setEmployee}
          insertDepartment={insertDepartment}
          dependents={dependents}
          insertDependent={insertDependent}
          updateDependent={onUpdateDependent}
          loading={loading}
          // journeyTypes={journeyType}
        />
      )}
    </>
  );
}

export default EmployeeEditContainer;
