import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const downloadReceipt = async (employeeId: number) => {
  try {
    const { data } = await request.get(`/integrations/download-esocial-receipt/${employeeId}`, {
      responseType: "arraybuffer",
    });
    const blob = new Blob([data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "ReciboAdmissaoESocial.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
