import { action, makeObservable, observable } from "mobx";
import { persist } from "mobx-persist";
import fetchRoles from "src/api/roles.api";

export default class RolesStore {
  @persist("object")
  @observable
  roles: RoleTypes[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  fetchRoles = async (): Promise<void> => {
    const roles = await fetchRoles();
    this.roles = roles;
  };
}
