import { action, computed, makeObservable, observable } from "mobx";
import { persist } from "mobx-persist";
import { AuthApi } from "src/api";
import { Storage } from "src/services";

const emptyUserData = {
  id: 0,
  name: "",
  email: "",
  rememberMeToken: false,
  finishedAdmissionAccess: false,
  configurations: {
    webNotifications: false,
    appNotifications: false,
    emailNotifications: false,
  },
};

export default class AuthStore {
  @persist("object")
  @observable
  userData: User = emptyUserData;

  constructor() {
    makeObservable(this);
  }

  @computed
  get getUserName(): string {
    return this.userData.name;
  }

  @action
  login = async ({ email, password, userId }: Credentials): Promise<void> => {
    const credentials = { email, password, userId };
    const token = await AuthApi.login(credentials);
    Storage.setToken(token);

    const user = await AuthApi.fetchUser(credentials);
    this.userData = user;
    Storage.setUserName(user.name);
    Storage.setUserId(user.id);
    Storage.setUserEmail(email || user.email);
    Storage.setUserFinishedAdmissionAccess(user.finishedAdmissionAccess);
    Storage.setEncryptedPassword(password);
  };

  @action
  logout = (): void => {
    this.userData = emptyUserData;
    Storage.clearWholeStorage();
  };
}
