import { createContext } from 'react';

const DEFAULT_VALUE: RoutingContextProps = {
  title: 'Sistema de admissões',
  setTitle: () => null,
  subtitle: 'Dashboard',
  setSubtitle: () => null,
};

export const RoutingContext = createContext(DEFAULT_VALUE);
export const RoutingContextProvider = RoutingContext.Provider;
