import { styled } from "src/modules";

export const AnimatedContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  position: relative;
`;

export const Wrapper = styled.div`
  max-width: 116.2rem;
  padding: 0 1.6rem;
  width: 100%;
  height: 6.4rem;
  display: flex;
  justify-content: space-between;
`;

export const AnimatedLeftNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > img {
    cursor: pointer;
    width: 100%;
    max-width: 11.2rem;
    height: auto;
    margin: 0 2.4rem 0.4rem -0.4rem;
  }

  button {
    font-size: 1.6rem;
    font-weight: 500;
    background-color: transparent;

    svg {
      color: ${({ theme }) => theme.colors.gray};
    }

    p {
      margin-left: 0.7rem;
      color: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const AnimatedRightNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  @media (max-width: 470px) {
    > button {
      display: none;
    }
  }

  @media (max-width: 400px) {
    margin-left: -12px;
  }
`;
