import { Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { UserApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";
import AccessUser from "./User";

function AccessUserContainer(): JSX.Element {
  const [users, setUsers] = useState<User[]>([]);
  const navigate = useNavigate();

  const onCreateUser = () => navigate("create");

  const onUpdateUser = (id: number) => navigate(`edit?id=${id}`);

  const onDeleteUser = (id: number) => {
    const onFinishDeleteUser = (id: number) => {
      const updatedUserList = users.filter((user) => user.id !== id);
      setUsers(updatedUserList);
      message.success({
        content: "Usuário excluído com sucesso.",
      });
    };

    return Modal.warning({
      title: "Deseja deletar este usuário?",
      content: "Esta ação não poderá ser desfeita.",
      okCancel: true,
      cancelText: "Não",
      okText: "Sim",
      onOk: () => onFinishDeleteUser(id),
      okType: "danger",
    });
  };

  const { show } = useIntercom();

  async function getManagerUsers(): Promise<boolean> {
    return await UserApi.fetchUser()
      .then((res: User[]) => {
        setUsers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_MANAGER_USER_ERROR.title),
            message: i18n(dictionary.error.GET_MANAGER_USER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getManagerUsers();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <AccessUser
      users={users}
      onCreateUser={onCreateUser}
      onDeleteUser={onDeleteUser}
      onUpdateUser={onUpdateUser}
    />
  );
}
export default AccessUserContainer;
