import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<SendingPersonalization[]> => {
  try {
    const { data } = await request.get("/sending-personalization");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<SendingPersonalization> => {
  try {
    const { data } = await request.get(`/sending-personalization/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const uploadLogo = async (fileBuffer: Buffer): Promise<{ url: string }> => {
  try {
    const { data } = await request.post(`/sending-personalization/attachment`, { fileBuffer });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (sendConfiguration: SendingPersonalization): Promise<SendingPersonalization> => {
  try {
    const { data } = await request.post("/sending-personalization", sendConfiguration);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: SendingPersonalization) => {
  try {
    const { data } = await request.put(`/sending-personalization/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const chosed = async (email: string) => {
  try {
    const { data } = await request.get(`sending-personalization/chosed/${email}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/sending-personalization/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
