import { BgColorsOutlined, CheckCircleOutlined, EditOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, ColorPicker, Form, Input, Modal, Row, Skeleton, Typography, message } from "antd";
import Upload, { RcFile, UploadChangeParam, UploadFile, UploadProps } from "antd/es/upload";
import { useEffect, useRef, useState } from "react";
import { UserSendingConfigurations } from "src/api";

import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import RichTextEditorSlate from "src/components/RichTextEditorSlate";
import { REACT_APP_BASE_PATH } from "src/constants";
import { ExpiredSessionError, dictionary, getBase64, i18n } from "src/utils";
import { EMAIL_BODY_TEXT_FIELD } from "src/utils/enums/email-body.enum";
import PreviewSending from "./PreviewSending";
import "./SendingConfiguration.css";

interface SendingConfigurationProps {}

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("Você só pode fazer upload de arquivos JPG/PNG");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("A imagem deve ter menos de 2 MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function SendingConfiguration(props: SendingConfigurationProps) {
  const [loadingPersonalization, setLoadingPersonalization] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRenderKey, setForceRenderKey] = useState(0);
  const [initialValueBodyEmail, setInitialValueBodyEmail] = useState(
    JSON.stringify([
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ]),
  );

  const [personalizationCards, setPersonalizationCards] = useState<JSX.Element[]>([]);

  // Form Modal
  const [frase, setFrase] = useState<string>(i18n(dictionary.components.sendings.formItems.frasePlaceHolder));
  const [title, setTitle] = useState<string>(i18n(dictionary.components.sendings.titleModalRegister));
  const [emailBody, setEmailBody] = useState<string>();
  const [imageUrl, setImageUrl] = useState<string>();
  const [principalColor, setPrincipalColor] = useState<string>("#884EA6");
  const [secondaryColor, setSecondaryColor] = useState<string>("#66C790");

  const mountSendPage = (sendPage: SendingPersonalization): SendingPersonalization => {
    sendPage.emailBody = emailBody;
    sendPage.principalColor = principalColor;
    sendPage.secondaryColor = secondaryColor;
    sendPage.logo = imageUrl;

    return sendPage;
  };

  const onFinish = async (pageForm: SendingPersonalization): Promise<void> => {
    pageForm = mountSendPage(pageForm);

    if (pageForm.id! > 0) {
      onUpdatePersonalization(pageForm.id!, pageForm);
    } else {
      onCreatePersonalization(pageForm);
    }
    cleanFields();
  };

  const cleanFields = (): void => {
    setInitialValueBodyEmail(
      JSON.stringify([
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ]),
    );
    setImageUrl("");
    setTitle(i18n(dictionary.components.sendings.titleModalRegister));
    setPrincipalColor("#884EA6");
    setSecondaryColor("#66C790");
    setFrase(i18n(dictionary.components.sendings.formItems.frasePlaceHolder));
    form.resetFields();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOkModal = () => {
    setIsModalOpen(false);
    form.submit();
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    cleanFields();
  };

  const handleEdit = (personalization: SendingPersonalization) => {
    mountForm(personalization);
    setIsModalOpen(true);
  };

  const onCreatePersonalization = async (personalization: SendingPersonalization): Promise<void> => {
    try {
      let res = await UserSendingConfigurations.create(personalization);
      setPersonalizationCards([
        ...personalizationCards,
        <Col>
          <Card
            key={res.id}
            hoverable
            onClick={() => {
              res.chosed = true;
              onUpdatePersonalization(res.id!, res);
            }}
            style={{ width: 300, height: 200, position: "relative", backgroundColor: "transparent" }}
          >
            <PreviewSending
              logoCompany={res.logo}
              principalColor={res.principalColor!}
              secondaryColor={res.secondaryColor!}
              frase={res.frase!}
              optionPreview="small"
            ></PreviewSending>
            {res.chosed && (
              <CheckCircleOutlined
                style={{
                  position: "absolute",
                  bottom: -10,
                  right: 0,
                  fontSize: 40,
                  backgroundColor: "#42f569",
                  color: "white",
                  borderRadius: 30,
                }}
              />
            )}
          </Card>
          <Row>
            <Card
              hoverable
              onClick={() => handleEdit(res)}
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EditOutlined style={{ fontSize: 15 }} />
            </Card>
            <Typography style={{ fontWeight: "bold", margin: 30, textAlign: "center" }}>{res.title}</Typography>
          </Row>
        </Col>,
      ]);
    } catch (error) {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.CREATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title),
          message: i18n(dictionary.error.CREATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message),
          onOk: () => onFinish(personalization),
          onClickLink: () => show(),
        });
      }
    }
  };

  const onUpdatePersonalization = async (id: number, personalization: SendingPersonalization) => {
    try {
      await UserSendingConfigurations.updateById(id, personalization);
    } catch (error) {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.UPDATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title),
          message: i18n(dictionary.error.UPDATE_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message),
          onOk: () => onFinish(personalization),
          onClickLink: () => show(),
        });
      }
    }
  };

  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    setLoadingLogo(true);
    if (info.event) {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        sendUpload(url);
        setLoadingLogo(false);
      });
    }
  };

  const mountForm = (personalization: SendingPersonalization): void => {
    setTitle(i18n(dictionary.components.sendings.titleModalEdit));
    setInitialValueBodyEmail(personalization.emailBody!);
    setImageUrl(personalization.logo!);
    setPrincipalColor(personalization.principalColor!);
    setSecondaryColor(personalization.secondaryColor!);
    setFrase(personalization.frase!);
    form.setFieldsValue({ ...personalization });
  };

  function sendUpload(url: any) {
    const fileBuffer = url.replace(/(data:image\/(jpeg)*(gif)*(png)*(gif)*\;(base64),)/g, "");
    UserSendingConfigurations.uploadLogo(fileBuffer)
      .then((res: any) => {
        setImageUrl(res.url);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.ATTACH_IMAGE_ERROR.title),
            message: i18n(dictionary.error.ATTACH_IMAGE_ERROR.message),
            onClickLink: () => show(),
            onOk: () => sendUpload(url),
          });
        }
        console.error(error);
      });
  }

  const uploadButton = (
    <div>
      {loadingLogo ? <LoadingOutlined /> : <PlusOutlined style={{ fontWeight: "bold" }} />}
      <div style={{ marginTop: 8 }}>{i18n(dictionary.components.sendings.formItems.logo)}</div>
    </div>
  );

  const [form] = Form.useForm();

  async function getPersonalizations(): Promise<boolean | undefined> {
    try {
      if (loadingPersonalization) {
        return;
      }

      setLoadingPersonalization(true);
      const response = await UserSendingConfigurations.fetch();
      let personalizations = getPersonalizationCards(response);
      setPersonalizationCards(personalizations);
      setLoadingPersonalization(false);

      return true;
    } catch (error) {
      if (!(error instanceof ExpiredSessionError)) {
        GoodErrorMessage({
          title: i18n(dictionary.error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.title),
          message: i18n(dictionary.error.GET_EMPLOYEE_SENDING_CONFIGURATION_ERROR.message),
          onClickLink: () => show(),
          onOk: () => {
            dataFetchedRef.current = false;
            getInitData();
          },
        });
      }
      throw error;
    }
  }

  const getPersonalizationCards = (personalizations: SendingPersonalization[]) => {
    let cards: JSX.Element[] = [];

    personalizations.forEach((p) => {
      cards.push(
        <Col>
          <Card
            key={p.id}
            hoverable
            onClick={() => {
              p.chosed = true;
              onUpdatePersonalization(p.id!, p);
            }}
            style={{ width: 300, height: 200, marginRight: 30, position: "relative", backgroundColor: "transparent" }}
          >
            <PreviewSending
              logoCompany={p.logo}
              principalColor={p.principalColor!}
              secondaryColor={p.secondaryColor!}
              frase={p.frase!}
              optionPreview="small"
            ></PreviewSending>
            {p.chosed && (
              <CheckCircleOutlined
                style={{
                  position: "absolute",
                  bottom: -10,
                  right: 0,
                  fontSize: 40,
                  backgroundColor: "#42f569",
                  color: "white",
                  borderRadius: 30,
                }}
              />
            )}
          </Card>
          <Row>
            <Card
              hoverable
              onClick={() => handleEdit(p)}
              style={{
                backgroundColor: "transparent",
                borderColor: "transparent",
                width: 80,
                height: 80,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EditOutlined style={{ fontSize: 15 }} />
            </Card>
            <Typography style={{ fontWeight: "bold", margin: 30, textAlign: "center" }}>{p.title}</Typography>
          </Row>
        </Col>,
      );
    });

    return cards;
  };

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getPersonalizations();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  useEffect(() => {
    // Força renderização do componente RichTextEditorSlate
    setForceRenderKey((prevKey) => prevKey + 1);
  }, [initialValueBodyEmail]);

  return (
    <>
      <Row
        className="profile-form-row"
        gutter={32}
      >
        <Col
          className="profile-form-col"
          span={12}
        >
          <Typography style={{ fontWeight: "bold" }}>{i18n(dictionary.components.sendings.title)}</Typography>
          <Row style={{ marginTop: "30px", marginBottom: "64px" }}>
            {loadingPersonalization ? <Skeleton></Skeleton> : personalizationCards}
            <Button
              type="dashed"
              style={{ width: 300, height: 200, backgroundColor: "transparent" }}
              onClick={showModal}
            >
              <Col>
                <>+</>
                <>Criar</>
              </Col>
            </Button>
          </Row>
        </Col>
      </Row>
      <Modal
        className="sending-modal"
        title={title}
        closeIcon={false}
        open={isModalOpen}
        onOk={handleOkModal}
        onCancel={handleCancelModal}
        footer={
          <div style={{ textAlign: "left" }}>
            <Button
              key="submit"
              type="primary"
              onClick={handleOkModal}
            >
              Salvar
            </Button>
            <Button
              key="back"
              onClick={handleCancelModal}
            >
              Cancelar
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item<SendingPersonalization>
            name="id"
            hidden
          ></Form.Item>
          <Form.Item<SendingPersonalization>
            name="title"
            required
            label={i18n(dictionary.components.sendings.formItems.title)}
            style={{ fontWeight: "bolder" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={i18n(dictionary.components.sendings.formItems.titlePlaceHolder)}
              maxLength={150}
            />
          </Form.Item>
          <Form.Item<SendingPersonalization>
            name="emailSubject"
            label={i18n(dictionary.components.sendings.formItems.emailSubject)}
            style={{ fontWeight: "bolder" }}
          >
            <Input placeholder={i18n(dictionary.components.sendings.formItems.emailSubjectPlaceHolder)} />
          </Form.Item>
          <Form.Item
            name="emailBody"
            label={
              <span style={{ fontWeight: "bolder" }}>{i18n(dictionary.components.sendings.formItems.emailBody)}</span>
            }
          >
            <RichTextEditorSlate
              key={forceRenderKey}
              content={JSON.parse(initialValueBodyEmail)}
              fieldList={fieldList}
              onChange={(value) => {
                setEmailBody(JSON.stringify(value));
              }}
              placeHolder={i18n(dictionary.components.sendings.formItems.emailBodyPlaceHolder)}
            />
          </Form.Item>
          <Form.Item<SendingPersonalization>
            name="frase"
            label={i18n(dictionary.components.sendings.formItems.fraseTitle)}
            style={{ fontWeight: "bolder" }}
          >
            <Input
              placeholder={i18n(dictionary.components.sendings.formItems.frasePlaceHolder)}
              maxLength={150}
              onChange={(event) => {
                setFrase(event.target.value);
              }}
            />
          </Form.Item>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "30%" }}>
              <Form.Item
                name="logo"
                className="sending-components"
                label={
                  <span style={{ fontWeight: "bolder" }}>{i18n(dictionary.components.sendings.formItems.logo)}</span>
                }
                wrapperCol={{ span: 12 }} // Defina a largura desejada para o wrapper
              >
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={`${REACT_APP_BASE_PATH}/download/${imageUrl}`}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item
                name="principalColor"
                label={i18n(dictionary.components.sendings.formItems.principalColor)}
                style={{ fontWeight: "bolder" }}
              >
                <ColorPicker
                  size="large"
                  value={principalColor}
                  onChange={(_, hex) => {
                    setPrincipalColor(hex);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Card
                    style={{
                      borderRadius: "8px",
                      padding: "16px",
                      width: 60,
                      height: 60,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundColor: principalColor,
                    }}
                  >
                    <BgColorsOutlined style={{ fontSize: 30, color: "white" }} />
                  </Card>
                </ColorPicker>
              </Form.Item>
              <Form.Item
                name="secondaryColor"
                label={i18n(dictionary.components.sendings.formItems.secondaryColor)}
                style={{ fontWeight: "bolder" }}
              >
                <ColorPicker
                  size="large"
                  value={secondaryColor}
                  onChange={(_, hex) => {
                    setSecondaryColor(hex);
                  }}
                >
                  <Card
                    style={{
                      borderRadius: "8px",
                      padding: "16px",
                      width: 60,
                      height: 60,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundColor: secondaryColor,
                    }}
                  >
                    <BgColorsOutlined style={{ fontSize: 30, color: "white" }} />
                  </Card>
                </ColorPicker>
              </Form.Item>
              <Form.Item
                name="chosed"
                hidden
              ></Form.Item>
            </div>
            <div style={{ width: "70%", height: "40vh", padding: "20px" }}>
              <PreviewSending
                logoCompany={imageUrl}
                principalColor={principalColor}
                secondaryColor={secondaryColor}
                frase={frase}
                optionPreview="medium"
              ></PreviewSending>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}

const fieldList: EMAIL_BODY_TEXT_FIELD[] = [EMAIL_BODY_TEXT_FIELD.EMPLOYER_NAME, EMAIL_BODY_TEXT_FIELD.EMPLOYEE_NAME];

function show(): void {
  throw new Error("Function not implemented.");
}
