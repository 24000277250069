import { css, styled } from "src/modules";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2.4rem;
  position: relative;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 400;
    margin-right: 16px;
    color: ${({ theme }) => theme.colors.brand.gray.dark};
  }

  @media (max-width: 420px) {
    span {
      display: none;
    }
  }

  > img {
    width: 32px;
    height: 32px;
  }
`;

export const AnimatedDropdown = styled.nav`
  ${({ theme }) => css`
    position: absolute;
    z-index: 10;
    top: 128%;
    right: 0;
    min-width: 14.6rem;
    border-radius: ${theme.radii.small};
    background: ${theme.colors.white};
    box-shadow: 0 0.6rem 0.6rem rgba(0, 0, 0, 0.12);

    ::before {
      content: "";
      display: block;
      position: absolute;
      top: -18%;
      right: 11%;
      border-color: ${`transparent transparent ${theme.colors.white} transparent `};
      border-style: solid;
      border-width: 0.8rem;
    }
  `}
`;

export const NavButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: 1.2rem 1.6rem;
    justify-content: space-between;
    font-size: ${theme.fontSizes.default};
    background: ${theme.colors.white};

    :hover {
      background: ${theme.colors.lightGray};
    }

    :first-child {
      border-radius: ${theme.radii.smallTop};
    }

    :last-child {
      border-radius: ${theme.radii.smallBottom};
    }
  `}
`;
