import {
  CalendarOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Dropdown, Space, TableColumnType, Tag, message } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import dayjs from "dayjs";
import moment from "moment";
import { MenuProps } from "rc-menu";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { Trans } from "react-i18next";
import { AdmissionApi, IntegrationApi } from "src/api";
import { Card, Table } from "src/components";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";
import { EmployeeTableData } from "./EmployeeTableData.type";
import { EmployeeSearch } from "./styles";
const { Column } = Table;

type Props = {
  onSearch(value: string): void;
  onCreate(): void;
  onEdit(id: number): void;
  onDisable(id: number): void;
  onEnable(id: number): void;
  employees: Employee[];
};

type DataIndex = keyof Employee;

function Employee({ onCreate, onSearch, onEdit, onDisable, onEnable, employees }: Props): JSX.Element {
  // Filtra apenas os funcionários ativos
  const getOnlyActiveEmployees = () =>
    employees
      .filter((employee) => employee.active === 1)
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  const [employeesTable, setEmployeesTable] = useState<Employee[]>([]);
  const [showInactives, setShowInactives] = useState(false);
  const [downloadReceipt, setDownloadReceipt] = useState<{ selectedEmployeeId: number; downloading: boolean }>({
    selectedEmployeeId: -1,
    downloading: false,
  });

  const [downloadContract, setDownloadContract] = useState<{ selectedEmployeeId: number; downloading: boolean }>({
    selectedEmployeeId: -1,
    downloading: false,
  });

  const [filterDate, setFilterDate] = useState("");
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    // Atualiza employeesTable sempre que employees for alterado
    updateEmployeesTable();
  }, [employees]);

  const makeDownloadContract = async (employeeId: number) => {
    message.destroy();
    setDownloadContract({ selectedEmployeeId: employeeId, downloading: true });
    const response = await AdmissionApi.downloadContract(employeeId).catch((_) => false);
    if (!response) {
      message.error(i18n(dictionary.label.error_download_contract));
    }
    setDownloadContract({ selectedEmployeeId: -1, downloading: false });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Checkbox
          checked={showInactives}
          onChange={() => handleActiveInactive()}
        >
          {i18n(dictionary.label.showInactives)}
        </Checkbox>
      ),
    },
  ];

  const updateEmployeesTable = () =>
    setEmployeesTable(
      !showInactives
        ? getOnlyActiveEmployees()
        : employees.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
    );

  // Função para lidar com a alternância entre ativos e inativos
  // Se estiver mostrando inativos, altera para mostrar apenas ativos
  // Se estiver mostrando apenas ativos, altera para mostrar todos
  const handleActiveInactive = () => {
    setShowInactives(!showInactives);
    setEmployeesTable(
      showInactives
        ? getOnlyActiveEmployees()
        : employees.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 11)),
    );
  };

  const getButtonActionProperties = (isActive: boolean) => {
    return {
      text: isActive ? "Inativar" : "Reativar",
      action: isActive ? onDisable : onEnable,
      backgroundColor: isActive ? "#ff4d4f" : "#52c41a",
    };
  };

  const onDownloadReceipt = async (employeeId: number) => {
    message.destroy();
    setDownloadReceipt({ selectedEmployeeId: employeeId, downloading: true });
    const response = await IntegrationApi.downloadReceipt(employeeId).catch((err) => {
      console.error(err);
      return false;
    });
    if (!response) {
      message.error(i18n(dictionary.error.frontend.downloadReceipt));
    }
    setDownloadReceipt({ selectedEmployeeId: -1, downloading: false });
  };

  function handleSearch(selectedKeys: string[], confirm: FilterDropdownProps["confirm"]) {
    confirm();
    setFilterDate(selectedKeys[0]);
    setOpenFilter(false);
  }

  function handleReset(clearFilters: () => void, confirm: FilterDropdownProps["confirm"]) {
    clearFilters();
    confirm();
    setFilterDate("");
    setOpenFilter(false);
  }

  function getColumnCalendar(dataIndex: DataIndex): TableColumnType<Employee> {
    return {
      dataIndex,
      filterMultiple: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          className="custom-filter-dropdown"
          style={{
            padding: 8,
            borderRadius: 4,
            boxShadow: "0 2px 8px rgba(0, 0, 0, .15)",
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker
            value={selectedKeys[0] ? dayjs(selectedKeys[0], "DD/MM/YYYY") : ""}
            allowClear
            format={["DD/MM/YYYY"]}
            style={{ width: 188, marginBottom: 8, display: "block" }}
            onChange={(value) => {
              setSelectedKeys(value ? [dayjs(value).format("DD/MM/YYYY")] : []);
            }}
            autoFocus
          />
          <Button
            type="primary"
            onClick={() => {
              handleSearch(selectedKeys as string[], confirm);
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            <Trans i18nKey={dictionary.label.search} />
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm);
            }}
            size="small"
            style={{ width: 90 }}
          >
            <Trans i18nKey={dictionary.action.clear} />
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{ color: filtered ? "#884ea6" : undefined }}
          onClick={() => setOpenFilter(true)}
        />
      ),
      onFilter: (value, record) => {
        return dayjs(record[dataIndex])
          .format("DD/MM/YYYY")
          .toString()
          .includes(value as string);
      },
      filterDropdownOpen: openFilter,
      render: (date: Date) => {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ff5e96", padding: 0 }}
            searchWords={[filterDate]}
            autoEscape
            textToHighlight={date ? moment(date).format(i18n(dictionary.dateFormat)) : "Admissão não concluída"}
          />
        );
      },
    };
  }

  return (
    <Card
      title={i18n(dictionary.label.list_title, { item: i18n(dictionary.entity.employee, { count: 2 }) })}
      actions={
        <Space className="row-to-col-mobile">
          <EmployeeSearch
            placeholder={i18n(dictionary.label.search_employee_or_employer_placeholder)}
            onSearch={onSearch}
          />
          <Button
            onClick={() => onCreate()}
            type="primary"
            icon={<PlusOutlined />}
          >
            {i18n(dictionary.action.create_item, { item: i18n(dictionary.entity.employee) })}
          </Button>
          <Dropdown
            trigger={["click"]}
            menu={{ items }}
          >
            <Button
              className="employee-input-search"
              shape="circle"
              size="large"
              type="text"
              icon={<SettingOutlined />}
            />
          </Dropdown>
        </Space>
      }
    >
      {}
      <Table
        scroll={{ x: true }}
        dataSource={[...employeesTable]}
      >
        <Column<Employee>
          title={i18n(dictionary.pages.employee.table.columns.name)}
          dataIndex="name"
          // sorter={(a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)}
          // defaultSortOrder={"descend"}
          // sortDirections={["ascend", "descend", "ascend"]}
        />
        <Column
          title={i18n(dictionary.pages.employee.table.columns.admission_date)}
          {...getColumnCalendar("admissionDate")}
        />

        <Column
          title={capitalizeFirstLetter(i18n(dictionary.pages.employee.table.columns.employer))}
          dataIndex="employer"
          render={(employer) => <>{employer.shortName ?? employer.fullName}</>}
        />
        <Column
          title={capitalizeFirstLetter(i18n(dictionary.pages.employee.table.columns.admission_origin.label))}
          dataIndex={"admissionOrigin"}
          render={(admissionOrigin: any) => {
            return (
              <Tag color={admissionOrigin === 1 ? "blue" : "purple"}>
                {i18n(dictionary.pages.employee.table.columns.admission_origin[admissionOrigin === 1 ? 1 : 2])}
              </Tag>
            );
          }}
          filters={[
            {
              text: i18n(dictionary.pages.employee.table.columns.admission_origin[1]),
              value: 1,
            },
            {
              text: i18n(dictionary.pages.employee.table.columns.admission_origin[2]),
              value: 2,
            },
          ]}
          onFilter={(value, record: Employee) => {
            return record.admissionOrigin === value;
          }}
        />
        <Column<EmployeeTableData>
          title={i18n(dictionary.label.action)}
          dataIndex="action"
          fixed="right"
          width={100}
          render={(_, record) => {
            const { text, action, backgroundColor } = getButtonActionProperties(record.active === 1);

            return (
              <Space direction="horizontal">
                <Button
                  className="full-size-mobile"
                  icon={<DownloadOutlined />}
                  type="default"
                  onClick={() => onDownloadReceipt(record.id)}
                  disabled={record.additionalData?.eSocialReceipt ? false : true}
                >
                  {i18n(dictionary.action.seeItem, {
                    item: i18n(dictionary.item.receipt),
                  })}
                </Button>
                <Button
                  loading={downloadContract.downloading && downloadContract.selectedEmployeeId === record.id}
                  disabled={!(record?.additionalData && !!record?.additionalData.contract)}
                  icon={<DownloadOutlined />}
                  onClick={() => makeDownloadContract(record.id)}
                >
                  {i18n(dictionary.label.download_contract)}
                </Button>
                <Button
                  type="default"
                  icon={<EditOutlined />}
                  onClick={() => onEdit(record.id)}
                >
                  {i18n(dictionary.action.edit)}
                </Button>

                <Button
                  type="primary"
                  style={{ backgroundColor: backgroundColor }}
                  onClick={() => action(record.id)}
                >
                  {text}
                  {/* {i18n(dictionary.action.delete)} */}
                </Button>
              </Space>
            );
          }}
        />
      </Table>
    </Card>
  );
}

export default Employee;
