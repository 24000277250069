import { message } from "antd";
import { lowerCase } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { ContractApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { useStores } from "src/hooks";
import useFormCrud from "src/hooks/useFormCrud";
import { Routes } from "src/routes";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import Contract from "./Contract";

function ContractContainer(): JSX.Element {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const navigate = useNavigate();
  const { handleDelete } = useFormCrud();
  const { show } = useIntercom();
  const { navigation } = useStores();

  const onDeleteContract = (id: number): void => {
    const onFinishDeleteContract = (): void => {
      ContractApi.destroy(id)
        .then((res) => {
          const updatedContractList: Contract[] = contracts.filter((contract) => contract.id !== id);
          setContracts(updatedContractList);
          message.success({
            content: i18n(dictionary.prompt.deleted_success, {
              context: context.male,
              item: lowerCase(i18n(dictionary.entity.contract)),
            }),
          });
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.DELETE_CONTRACT_ERROR.title),
              message: i18n(dictionary.error.DELETE_CONTRACT_ERROR.message),
              onOk: () => onFinishDeleteContract(),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    };

    handleDelete(
      i18n(dictionary.prompt.delete_confirm, {
        context: context.male,
        item: i18n(dictionary.entity.contract),
      }),
      i18n(dictionary.prompt.action_cant_be_undone),
      onFinishDeleteContract,
    );
  };

  const onCreateContract = () => navigate(Routes.CONTRACT_CREATE);

  const onEditContract = (id: number): void => navigate(`${Routes.CONTRACT_EDIT}?id=${id}`);
  const onViewContract = (id: number): void => navigate(`${Routes.CONTRACT_EDIT}?id=${id}&view=true`);

  async function getContracts(): Promise<boolean> {
    return await ContractApi.fetch()
      .then((res: Contract[]) => {
        setContracts(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_CONTRACT_ERROR.title),
            message: i18n(dictionary.error.GET_CONTRACT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }

        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getContracts();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <Contract
      contracts={contracts}
      onCreateContract={onCreateContract}
      onDeleteContract={onDeleteContract}
      onEditContract={onEditContract}
      onViewContract={onViewContract}
    />
  );
}

export default ContractContainer;
