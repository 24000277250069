import { Modal } from "antd";

import { dictionary, i18n } from "src/utils";
import { useEditMode } from ".";

type useFormCrudType = {
  editMode(): boolean;
  handleDelete(modalTitle: string, modalContent: string, onFinishDelete: () => void): void;
};

function useFormCrud(): useFormCrudType {
  const [editMode] = useEditMode();

  const handleDelete = (modalTitle: string, modalContent: string, onFinishDelete: () => void) => {
    Modal.warning({
      title: modalTitle,
      content: modalContent,
      okCancel: true,
      onOk: () => onFinishDelete(),
      okType: "danger",
      okText: i18n(dictionary.label.yes),
      cancelText: i18n(dictionary.label.no),
    });
  };

  return {
    editMode: () => editMode,
    handleDelete,
  };
}

export default useFormCrud;
