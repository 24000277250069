import { css, styled } from "src/modules";

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 3.2rem;
  position: relative;
  flex: 1;
`;

export const RedirectSystemWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgb(136, 78, 166);
  background: linear-gradient(270deg, rgba(136, 78, 166, 1) 0%, rgba(97, 100, 194, 1) 50%, rgba(102, 199, 144, 1) 100%);
`;

export const LoginContainer = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 36rem;
    padding: 3.2rem;
    background: ${theme.colors.background};
    border-radius: ${theme.radii.default};
    box-shadow: ${theme.shadows.default};

    h1 img {
      width: 16rem;
      height: auto !important;
    }

    h2 {
      font-size: ${theme.fontSizes.large};
      text-align: center;
      font-weight: 500;
    }
  `}
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2.4rem;
  width: 100%;
`;

export const SignInButton = styled.button`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 1.2rem;
    color: ${theme.colors.white};
    border: 0;
    border-radius: 8px;
    padding: 0.8rem 2.4rem;
    background: ${theme.colors.brand.primary.light};
    transition: 300ms ease-in-out;
    cursor: pointer;

    :disabled {
      background: ${theme.colors.gray};
      cursor: not-allowed;
    }

    :hover:not(:disabled) {
      background: ${theme.colors.brand.primary.dark};
    }
  `}
`;
