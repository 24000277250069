/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const removeHashFromColor = (color: string): string => color.slice(1);

export const truncate = (text: string, size = 20) => {
  if (!text) return;

  return text.substring(0, size);
};

export const capitalizeFirstLetter = (text: string) => {
  const firstLetter = text.charAt(0);
  const slicedText = text.slice(1);

  return `${firstLetter.toLocaleUpperCase()}${slicedText}`;
};
