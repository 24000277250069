import { Modal } from "antd";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { REACT_APP_BASE_PATH, REACT_APP_CLIENT_ID } from "src/constants";
import { Routes } from "src/routes";
import { Storage } from "src/services";
import { ExpiredSessionError, dictionary, i18n } from "src/utils";
import globalRouter from "src/utils/helpers/globalRouter";
// import globalRouter from "src/utils/helpers/globalRouter";

export const request = axios.create({
  baseURL: REACT_APP_BASE_PATH,
  headers: { client_id: REACT_APP_CLIENT_ID },
  timeout: 30000, // 30 seconds timeout
});

/**
 * Middleware, to change whatever we need in the request
 * ex: Put a bearer token in the request, if necessary
 */

interface CustomRequest {
  authorization?: string;
}

const successResponse = (response: any): AxiosResponse => response;

const addAuthHeaders = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig & CustomRequest> => {
  const token: Token | null = Storage.getToken();
  if (!token || isEmpty(token)) {
    return config;
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      token: `${token}`,
      authorization: `Bearer ${token}`,
    },
  };
};

const verifyExpiredToken = async (error: ErrorResponse): Promise<ExpiredSessionError | ErrorResponse> => {
  const expiredSessionError = 401;
  if (error.response.status === expiredSessionError) {
    //Storage.clearWholeStorage();
    //const history = createBrowserHistory();
    //const navigate = useNavigate();
    //const { auth, navigation } = useStores();
    // navigation.setSelectedMenu("1");
    //await auth.logout();
    //history.push(Routes.LOGIN);
    // const message = i18n(dictionary.expired_session);
    // showAlert({ message });
    //Navigate({ to: Routes.LOGIN, replace: true });
    //redirect(Routes.LOGIN);
    //redirectDocument(Routes.LOGIN);
    Modal.destroyAll();
    Modal.info({
      title: i18n(dictionary.expiredSession.title),
      content: i18n(dictionary.expiredSession.content),
      onOk: () => {
        if (globalRouter.navigate) {
          Storage.clearWholeStorage();
          globalRouter.navigate(Routes.LOGIN);
        }
      },
    });
    throw new ExpiredSessionError();
  }
  throw error;
};

request.interceptors.response.use(successResponse, verifyExpiredToken);

request.interceptors.request.use(addAuthHeaders);

export default request;
