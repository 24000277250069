import { DollarCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Tour, TourProps, Typography, theme } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { AuditOutlined, FileSearchOutlined, HomeOutlined } from "src/assets/icons";
import { ExtendGradientLogo, LogoColoridaSimples } from "src/assets/images";
import Header from "src/components/Header";
import { REACT_APP_VERSION } from "src/constants";
import { RoutingContext } from "src/contexts";
import { useStores } from "src/hooks";
import { Menu, MenuProps, observer } from "src/modules";
import { Routes } from "src/routes";
import { capitalizeFirstLetter, dictionary, i18n } from "src/utils";
import { ChildrenContent, Logo, Sidebar, StyledFooter, Wrapper, WrapperContent } from "./styles";

const { Text } = Typography;

export type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  onClick?: () => void,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    className: "admissao-frontend-left-menu-item-" + key,
    onClick,
  } as MenuItem;
}

const { useToken } = theme;

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  // contextos
  const { title, subtitle } = useContext(RoutingContext);
  // armazenamentos
  const { navigation } = useStores();
  // navegação
  const navigate = useNavigate();
  // estados
  const [collapsed, setCollapsed] = useState(false);
  const [isPortraitOrientation, setIsPortraitOrientation] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(true);
  const { token } = useToken();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const [open, setOpen] = useState<boolean>(false);

  const year = new Date().getFullYear();

  const menuItems: MenuItem[] = [
    getItem(i18n(dictionary.homePage), "1", <HomeOutlined />, undefined, () => {
      navigate(Routes.HOME);
    }),
    getItem(<Text ref={ref2}>{i18n(dictionary.label.registration)}</Text>, "sub1", <AuditOutlined />, [
      getItem(capitalizeFirstLetter(i18n(dictionary.entity.contract, { count: 2 })), "3", null, undefined, () => {
        navigate(Routes.CONTRACT);
      }),
      getItem(capitalizeFirstLetter(i18n(dictionary.entity.employment_relationships)), "2", null, undefined, () => {
        navigate(Routes.DOCUMENTATION);
      }),
      getItem(capitalizeFirstLetter(i18n(dictionary.entity.admission_flow)), "9", null, undefined, () => {
        navigate(Routes.ADMISSION_FLOW);
      }),
      getItem(capitalizeFirstLetter(i18n(dictionary.entity.employer, { count: 2 })), "4", null, undefined, () => {
        navigate(Routes.EMPLOYER);
      }),
      getItem(capitalizeFirstLetter(i18n(dictionary.entity.employee, { count: 2 })), "5", null, undefined, () => {
        navigate(Routes.EMPLOYEE);
      }),
      // getItem(capitalizeFirstLetter(i18n(dictionary.entity.user)), "6"),
    ]),
    getItem(i18n(dictionary.label.report, { count: 2 }), "sub2", <FileSearchOutlined />, [
      getItem(i18n(dictionary.label.registrationForm, { count: 2 }), "7", null, undefined, () => {
        navigate(Routes.REPORT_REGISTRATION_FORM);
      }),
    ]),
    getItem(i18n(dictionary.label.finance), "8", <DollarCircleOutlined />, undefined, () => {
      navigate(Routes.FINANCE);
    }),
    // getItem(<Text ref={ref3}>{i18n(dictionary.communication)}</Text>, "sub3", <NotificationOutlined />, []),
    // getItem(<Text ref={ref4}>{i18n(dictionary.integration)}</Text>, "sub4", <AppstoreAddOutlined />, []),
  ];

  const steps: TourProps["steps"] = [
    {
      title: (
        <>
          {" "}
          <InfoCircleOutlined /> Tudo sobre controle
        </>
      ),
      description: "Acompanhe mensagens, lembretes e alertas de forma simples e organizada por ordem cronológica.",
      target: () => ref1.current,
      placement: "bottomRight",
    },
    {
      title: (
        <>
          {" "}
          <InfoCircleOutlined /> Título de exemplo
        </>
      ),
      description: "Conteúdo descritivo será desenvolvido de acordo com as seções descritas ao lado.",
      target: () => ref2.current,
      placement: "leftTop",
    },
    {
      title: (
        <>
          {" "}
          <InfoCircleOutlined /> Título de exemplo
        </>
      ),
      description: "Conteúdo descritivo será desenvolvido de acordo com as seções descritas ao lado.",
      target: () => ref3.current,
      placement: "leftTop",
    },
    {
      title: (
        <>
          {" "}
          <InfoCircleOutlined /> Título de exemplo
        </>
      ),
      description: "Conteúdo descritivo será desenvolvido de acordo com as seções descritas ao lado.",
      target: () => ref4.current,
      placement: "leftTop",
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    const routekey = e.key;

    if (!isBrowser) {
      setCollapsed(false);
    }

    switch (routekey) {
      case "2":
        navigation.setSelectedMenu("2");
        navigate(Routes.DOCUMENTATION);
        break;
      case "3":
        navigation.setSelectedMenu("3");
        navigate(Routes.CONTRACT);
        break;
      case "4":
        navigation.setSelectedMenu("4");
        navigate(Routes.EMPLOYER);
        break;
      case "5":
        navigation.setSelectedMenu("5");
        navigate(Routes.EMPLOYEE);
        break;
      case "6":
        navigation.setSelectedMenu("6");
        navigate(Routes.USER);
        break;
      case "7":
        navigation.setSelectedMenu("7");
        navigate(Routes.REPORT_REGISTRATION_FORM);
        break;
      case "8":
        navigation.setSelectedMenu("8");
        navigate(Routes.FINANCE);
        break;
      case "9":
        navigation.setSelectedMenu("9");
        navigate(Routes.ADMISSION_FLOW);
        break;
      default:
        navigate(Routes.HOME);
        navigation.setSelectedMenu("1");
    }
  };

  function handleClickLogo() {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    // Caso a altura da tela seja maior que a largura
    const isPortraitOrientation = window.innerHeight > window.innerWidth;
    setIsPortraitOrientation(isPortraitOrientation);
    if (isPortraitOrientation) {
      setShowMenu(false);
      setCollapsed(false);
    }
  }, []);

  return (
    <>
      <Wrapper>
        <Sidebar
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ background: token.colorBgBase, minWidth: 800 }}
          width={isBrowser ? 220 : 0}
        >
          <Logo
            className="logo"
            onClick={() => {
              setCollapsed(!collapsed);
              // setShowMenu(!showMenu);
            }}
          >
            {collapsed ? (
              <img
                src={LogoColoridaSimples}
                alt="Logo"
                style={{ width: "58%", height: "70%" }}
              />
            ) : (
              <img
                src={ExtendGradientLogo}
                alt="Logo"
                style={{ width: "44%", height: "70%" }}
              />
            )}
          </Logo>
          <Menu
            onClick={onClick}
            theme="light"
            defaultSelectedKeys={navigation.selectedMenu}
            mode="inline"
            items={menuItems}
            defaultOpenKeys={["sub1", "sub2"]}
          />
        </Sidebar>
        <WrapperContent className="dashboard-layout">
          {/* <MobileView>
            {!collapsed && (
              <Logo
                className="logo"
                onClick={() => {
                  handleClickLogo();
                }}
              >
                <img
                  src={LogoBlack}
                  alt="Logo"
                  style={{ width: "58%", height: "70%" }}
                />
              </Logo>
            )}
          </MobileView> */}
          <Header
            title={title}
            subtitle={subtitle}
            tourRef={ref1}
            menuItems={menuItems}
            hasLogo={!collapsed}
            onClickLogo={() => handleClickLogo()}
          />
          <ChildrenContent>{children}</ChildrenContent>
          <StyledFooter>
            Admissão Digital &copy; {year} - {i18n(dictionary.version)} {REACT_APP_VERSION} -{" "}
            {i18n(dictionary.bugOrFeatReport)}
          </StyledFooter>
        </WrapperContent>
      </Wrapper>
      <Tour
        open={open}
        onClose={() => setOpen(false)}
        steps={steps}
      />
    </>
  );
};

export default observer(DefaultLayout);
