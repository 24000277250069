// Menu pathnames
const REGISTRATION = "registration";
const REPORT = "report";
// Pages pathnames
const DOCUMENTATION = "documentation";
const EMPLOYEE = "employee";
const EMPLOYER = "employer";
const LOGIN = "login";
const FORGOT_PASSWORD = "forgotPassword";
const RESET_PASSWORD = "reset-password/:data";
const AUTO_LOGIN = "login/:data";
const REGISTRATION_FORM = "registration-form";
const USER = "user";
const CONTRACT = "contract";
const SENDING_DOCUMENTS = "sending-documents";
const NEW_SENDING_DOCUMENTS = "new-sending-documents";
const DOWNLOAD_CONTRACT = "download-contract";
const SETTINGS = "settings";
const FINANCE = "finance";
const ADMISSION_FLOW = "admission-flow";
const NEWS = "news";
// CRUD pathnames
const CREATE = "create";
const EDIT = "edit";

export const Routes = {
  DOCUMENTATION: `/${REGISTRATION}/${DOCUMENTATION}`,
  DOCUMENTATION_CREATE: `/${REGISTRATION}/${DOCUMENTATION}/${CREATE}`,
  DOCUMENTATION_EDIT: `/${REGISTRATION}/${DOCUMENTATION}/${EDIT}`,
  EMPLOYEE: `/${REGISTRATION}/${EMPLOYEE}`,
  EMPLOYEE_CREATE: `/${REGISTRATION}/${EMPLOYEE}/${CREATE}`,
  EMPLOYEE_EDIT: `/${REGISTRATION}/${EMPLOYEE}/${EDIT}`,
  EMPLOYER: `/${REGISTRATION}/${EMPLOYER}`,
  EMPLOYER_CREATE: `/${REGISTRATION}/${EMPLOYER}/${CREATE}`,
  EMPLOYER_EDIT: `/${REGISTRATION}/${EMPLOYER}/${EDIT}`,
  HOME: `/`,
  LOGIN: `/${LOGIN}`,
  FORGOT_PASSWORD: `/${FORGOT_PASSWORD}`,
  RESET_PASSWORD: `${RESET_PASSWORD}`,
  AUTO_LOGIN: `/${AUTO_LOGIN}`,
  REGISTRATION: `/${REGISTRATION}`,
  REPORT: `/${REPORT}`,
  REPORT_REGISTRATION_FORM: `/${REPORT}/${REGISTRATION_FORM}`,
  USER: `/${REGISTRATION}/${USER}`,
  USER_CREATE: `/${REGISTRATION}/${USER}/${CREATE}`,
  USER_EDIT: `/${REGISTRATION}/${USER}/${EDIT}`,
  CONTRACT: `/${REGISTRATION}/${CONTRACT}`,
  CONTRACT_CREATE: `/${REGISTRATION}/${CONTRACT}/${CREATE}`,
  CONTRACT_EDIT: `/${REGISTRATION}/${CONTRACT}/${EDIT}`,
  SENDING_DOCUMENTS: `/${SENDING_DOCUMENTS}`,
  NEW_SENDING_DOCUMENTS: `/${NEW_SENDING_DOCUMENTS}/:data`,
  DOWNLOAD_CONTRACT: `${DOWNLOAD_CONTRACT}/:data`,
  SETTINGS: `/${SETTINGS}`,
  FINANCE: `/${FINANCE}`,
  ADMISSION_FLOW: `/${REGISTRATION}/${ADMISSION_FLOW}`,
  ADMISSION_FLOW_CREATE: `/${REGISTRATION}/${ADMISSION_FLOW}/${CREATE}`,
  ADMISSION_FLOW_EDIT: `/${REGISTRATION}/${ADMISSION_FLOW}/${EDIT}`,
  NEWS: `/${NEWS}`,
};
