import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export async function fetch(page: number, pageSize: number) {
  try {
    const { data } = await request.get(`/new?page=${page}&pageSize=${pageSize}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}

export async function feedback(newId: string, feedbackId: string, feedback: number) {
  try {
    const { data } = await request.post(`/new/feedback`, { newId, feedbackId, feedback });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
}

export function view(newId: string) {
  request.post(`/new/view`, { newId }).catch((_) => null);
}
