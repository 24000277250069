import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<EmployeeNote[]> => {
  try {
    const { data } = await request.get("/employee-notes");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: number): Promise<EmployeeNote> => {
  try {
    const { data } = await request.get(`/employee-notes/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchByEmployeeId = async (id: number): Promise<EmployeeNote[]> => {
  try {
    const { data } = await request.get(`/employee-notes/employee/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: number, update: EmployeeNote) => {
  try {
    const { data } = await request.put(`/employee-notes/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: EmployeeNote) => {
  try {
    const { data } = await request.post(`/employee-notes`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: number) => {
  try {
    const { data } = await request.delete(`/employee-notes/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
