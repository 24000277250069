import React, { useContext } from "react";

import { RoutingContext } from "src/contexts";
import SendingDocuments from "./SendingDocuments";

const SendingDocumentsContainer: React.FC = () => {
  const { setTitle, setSubtitle } = useContext(RoutingContext);
  setTitle("Sistema de admissões");
  setSubtitle("Dashboard");

  return <SendingDocuments />;
};
export default SendingDocumentsContainer;
