import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<Employer[]> => {
  try {
    const { data } = await request.get(`/employers`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetchById = async (id: string): Promise<Employer> => {
  try {
    const { data } = await request.get(`/employers/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const updateById = async (id: string, update: Employer) => {
  try {
    const { data } = await request.put(`/employers/${id}`, update);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const create = async (create: Employer) => {
  try {
    const { data } = await request.post(`/employers`, create);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const destroy = async (id: string) => {
  try {
    const { data } = await request.delete(`/employers/${id}`);
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const uploadFile = async (fileBuffer: Buffer, employerId: string): Promise<{ url: string }> => {
  try {
    const { data } = await request.post(`/employers`, { fileBuffer, employerId });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const uploadCertificate = async (fileBuffer: Buffer, employerId: string): Promise<{ url: string }> => {
  try {
    const { data } = await request.post(`/employers/certificate`, { fileBuffer, employerId });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
