import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import { dictionary, i18n } from "src/utils";

export default function TextFilterColumn({ setSelectedKeys, selectedKeys, confirm }: any) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={i18n(dictionary.components.textFilterColumn.search)}
        value={selectedKeys[0]}
        onChange={({ target }) => {
          setSelectedKeys(target.value ? [target.value] : []);
        }}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        {i18n(dictionary.components.textFilterColumn.search)}
      </Button>
      <Button
        onClick={() => {
          setSelectedKeys([]);
          confirm();
        }}
        size="small"
        style={{ width: 90 }}
      >
        {i18n(dictionary.components.textFilterColumn.reset)}
      </Button>
    </div>
  );
}
