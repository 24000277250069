import CryptoJS from "crypto-js";
import { REACT_APP_KEY_ENCRYPT_URL } from "src/constants";

export function getDescryptedParamsData(encryptedUrlParams: string) {
  try {
    if (!encryptedUrlParams.length) return "";
    const bytes = CryptoJS.AES.decrypt(encryptedUrlParams, REACT_APP_KEY_ENCRYPT_URL);
    const originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return originalText;
  } catch (_) {
    return {};
  }
}
