import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const fetch = async (): Promise<EmployeeVacation[]> => {
  try {
    const { data } = await request.get("/employee-vacations");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
