import { Button, Space } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "src/assets/icons";
import { Card, Table } from "src/components";

const { Column } = Table;

type Props = {
  users: User[];
  onCreateUser(): void;
  onDeleteUser(id: number): void;
  onUpdateUser(id: number): void;
};

function User({ users, onCreateUser, onDeleteUser, onUpdateUser }: Props): JSX.Element {
  return (
    <Card
      title="Lista de usuários de acesso"
      icon={false}
      actions={
        <Space direction="horizontal">
          <Button
            type="primary"
            onClick={onCreateUser}
          >
            <PlusOutlined />
            Cadastrar novo usário
          </Button>
        </Space>
      }
    >
      <Table dataSource={users}>
        <Column
          key="name"
          dataIndex="name"
          title="Nome"
          sorter={(a: User, b: User) => a.name.localeCompare(b.name)}
        />
        <Column
          key="email"
          dataIndex="email"
          title="E-mail"
          sorter={(a: User, b: User) => a.email.localeCompare(b.email)}
        />
        <Column
          width={20}
          key="action"
          dataIndex="action"
          title="Ação"
          render={(_, record: User) => (
            <Space direction="horizontal">
              <Button
                icon={<EditOutlined />}
                onClick={() => onUpdateUser(record.id)}
              >
                Editar
              </Button>
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                onClick={() => onDeleteUser(record.id)}
              >
                Deletar
              </Button>
            </Space>
          )}
        />
      </Table>
    </Card>
  );
}

export default User;
