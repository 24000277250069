import { CalculatorOutlined, EyeOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React from "react";
import { Card, Table as EmployeeTable, FormItem, InputGroup } from "src/components";
import { dictionary, i18n } from "src/utils";
import { LogsActionList } from "./styles";

const { Column } = EmployeeTable;

type Props = {
  employeeLogs: ChangeHistory[];
  showLogsModal: boolean;
  setShowLogsModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function TabEmployeeLogs({ employeeLogs, showLogsModal, setShowLogsModal }: Props): JSX.Element {
  const [form] = useForm();

  function handleOpenModal(log: ChangeHistory) {
    form.setFieldsValue({
      logEmployee: log.userName,
      logAuthor: log.userName,
      logActions: [log.action],
      logDateTime: moment(log.createdAt).format(i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat)),
    });
    setShowLogsModal(true);
  }

  function handleCloseModal() {
    setShowLogsModal(false);
    form.resetFields();
  }

  function LogModal(): JSX.Element {
    function renderActionList(): JSX.Element[] {
      const actions = form.getFieldValue("logActions");
      if (actions) {
        return actions.map((action: string) => <li>{action}</li>);
      }
      return [];
    }

    function renderTitle(): string {
      // const dateTime = form.getFieldValue("logDateTime");
      // if (dateTime) {
      //   const formattedDateTime = moment(dateTime).format(
      //     i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat),
      //   );
      //   return `Logs / ${formattedDateTime}`;
      // }

      return i18n(dictionary.label.logs);
    }

    return (
      <Modal
        title={renderTitle()}
        visible={showLogsModal}
        onCancel={() => handleCloseModal()}
        footer={
          <Button
            type="default"
            onClick={() => handleCloseModal()}
          >
            Fechar visualização
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
        >
          <FormItem>
            <InputGroup>
              <FormItem
                label={i18n(dictionary.label.date_time)}
                name="logDateTime"
                rules={[{ required: false }]}
              >
                <Input disabled />
              </FormItem>

              <FormItem
                label={i18n(dictionary.label.author)}
                name="logAuthor"
                rules={[{ required: false }]}
              >
                <Input disabled />
              </FormItem>
            </InputGroup>
          </FormItem>

          <FormItem
            label={i18n(dictionary.label.action)}
            name="logActions"
            rules={[{ required: false }]}
          >
            <LogsActionList>{renderActionList()}</LogsActionList>
          </FormItem>
        </Form>
      </Modal>
    );
  }

  return (
    <>
      <LogModal />
      <Card
        icon={<HistoryOutlined />}
        title="Histórico de alterações"
      >
        <EmployeeTable
          tableLayout="auto"
          dataSource={employeeLogs}
        >
          <Column
            title="Data / Hora"
            dataIndex="dateTime"
            key="dateTime"
            render={(value: Date) =>
              moment(value).format(i18n(dictionary.dateFormat) + " " + i18n(dictionary.timeFormat))
            }
            sorter={(a: ChangeHistory, b: ChangeHistory) => a.createdAt.getTime() - b.createdAt.getTime()}
          />
          <Column
            title="Ação"
            dataIndex="action"
            key="action"
            width="60%"
          />
          <Column
            title="Responsável"
            dataIndex="userName"
            key="userName"
            sorter={(a: ChangeHistory, b: ChangeHistory) => a.userName.localeCompare(b.userName)}
            render={(_, record: ChangeHistory) =>
              !record.userId ? (
                <>
                  {record.userName}&nbsp;
                  <CalculatorOutlined />
                </>
              ) : (
                record.userName
              )
            }
          />
          <Column
            title="Logs"
            dataIndex="actions"
            key="actions"
            render={(_, record: ChangeHistory) => (
              <Button
                size="small"
                type="default"
                icon={<EyeOutlined />}
                onClick={() => handleOpenModal(record)}
              >
                {i18n(dictionary.action.view)}
              </Button>
            )}
          />
        </EmployeeTable>
      </Card>
    </>
  );
}

export default TabEmployeeLogs;
