import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { NewApi } from "src/api";
import StorageService from "src/services/storage/storage.service";
import { dictionary, i18n } from "src/utils";

const { Title, Paragraph } = Typography;

export default function News() {
  const [page, setPage] = useState(1);
  const [news, setNews] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    return () => {
      setPage(1);
      setNews([]);
      setHasMore(true);
    };
  }, []);

  useEffect(() => {
    getNews();
  }, [page]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting) && entries.some((entry) => entry.intersectionRatio < 1)) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    const flag = document.querySelector("#flag");
    if (flag) intersectionObserver.observe(flag);

    return () => intersectionObserver.disconnect();
  }, []);

  function getNews() {
    if (hasMore) {
      NewApi.fetch(page, 10).then((data) => {
        if (!data.length) return setHasMore(false);
        setNews((prevNews: any[]) => [...prevNews, ...data]);
        if (page === 1) StorageService.setLatestNew(data[0].id);
      });
    }
  }

  return (
    <Card>
      <Flex
        vertical
        style={{ width: "100%" }}
      >
        <Title
          level={2}
          style={{ color: "#884EA6", margin: "0 0 10px 0" }}
        >
          {i18n(dictionary.label.news)}
        </Title>
        <div style={{ display: "flex", flexDirection: "column", height: "100vh", overflow: "auto" }}>
          {news.length
            ? news.map(
                (
                  {
                    id,
                    titulo,
                    descricao,
                    link_artigo,
                    feedback_id,
                    feedback,
                    data_hora_publicacao,
                    date_time_view,
                  }: {
                    id: string;
                    titulo: string;
                    descricao: string;
                    link_artigo: string;
                    feedback_id: string;
                    feedback: number;
                    data_hora_publicacao: Date;
                    date_time_view: string | null;
                  },
                  index: number,
                ) => (
                  <NewCard
                    key={index}
                    isFirst={index === 0}
                    id={id}
                    title={titulo}
                    description={descricao}
                    articleLink={link_artigo}
                    feedbackId={feedback_id}
                    feedback={feedback}
                    publicationDate={data_hora_publicacao}
                    viewed={!!date_time_view}
                  />
                ),
              )
            : !hasMore && <WithoutNews />}
          <div
            id="flag"
            style={{ zIndex: 3, color: "white" }}
          >
            {i18n(dictionary.pages.news.loading)}
          </div>
        </div>
      </Flex>
    </Card>
  );
}

type NewCardProps = {
  isFirst: boolean;
  id: string;
  title: string;
  description: string;
  articleLink: string;
  feedbackId: string;
  feedback: number;
  publicationDate: Date;
  viewed: boolean;
};

function NewCard({
  isFirst,
  id,
  title,
  description,
  articleLink,
  feedbackId,
  feedback,
  publicationDate,
  viewed,
}: NewCardProps) {
  const [feedbackState, setFeedbackState] = useState(feedback);
  const [feedbackIdState, setFeedbackIdState] = useState(feedbackId);

  const [visualized, setVisualized] = useState(false);

  async function makeFeedback(type: number) {
    NewApi.feedback(id, feedbackIdState, type).then((response) => {
      if (response) {
        setFeedbackState(type);
        setFeedbackIdState(response.id);
      }
    });
  }

  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !viewed && !visualized) {
          setVisualized(true);

          NewApi.view(id);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, [id, visualized]);

  return (
    <div
      ref={cardRef}
      style={{
        width: "100%",
        marginBottom: "10px",
        cursor: articleLink.length ? "pointer" : "default",
      }}
      onClick={() => {
        if (articleLink.length) window.open(articleLink, "_blank");
      }}
    >
      {isFirst && (
        <label
          style={{
            position: "relative",
            top: "12px",
            zIndex: 2,
            backgroundColor: "#ffd700",
            padding: "5px 10px 5px 10px",
            borderRadius: "8px",
            boxShadow: "3px 3px 5px #0000004d",
            color: "#000000ba",
            left: "10px",
          }}
        >
          {i18n(dictionary.pages.news.latest)}
        </label>
      )}
      <Card
        bordered={false}
        style={{ backgroundColor: "rgb(245, 247, 250)" }}
      >
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Title level={4}>{title}</Title>
          <Paragraph style={{ fontSize: "10pt" }}>{dayjs(publicationDate).format("DD/MM/YYYY").toString()}</Paragraph>
        </div>
        <Paragraph>{description}</Paragraph>
        <div style={{ display: "flex", justifyContent: "center", gap: 5 }}>
          <Flex
            vertical
            justify="center"
            align="center"
          >
            <Button
              size="small"
              style={feedbackState === 1 ? { background: "#00ce07", border: "none" } : {}}
              shape="circle"
              icon={<LikeOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                const feedback = feedbackState === 1 ? 0 : 1;
                makeFeedback(feedback);
              }}
            />
          </Flex>
          <Flex
            vertical
            justify="center"
            align="center"
          >
            <Button
              size="small"
              style={feedbackState === -1 ? { background: "#fa6f6f", border: "none" } : {}}
              shape="circle"
              icon={<DislikeOutlined />}
              onClick={(event) => {
                event.stopPropagation();
                const feedback = feedbackState === -1 ? 0 : -1;
                makeFeedback(feedback);
              }}
            />
          </Flex>
        </div>
      </Card>
    </div>
  );
}

function WithoutNews() {
  return (
    <div
      style={{
        fontSize: "12pt",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "67px 0",
      }}
    >
      <span style={{ fontSize: "50px" }}>😴</span>
      {i18n(dictionary.pages.news.empty)}
    </div>
  );
}
