import { Layout, styled } from "src/modules";

const { Header, Content, Footer, Sider } = Layout;

export const Wrapper = styled(Layout)`
  min-height: 100vh;
`;

export const WrapperContent = styled(Layout)``;

export const Sidebar = styled(Sider).attrs({
  collapsible: true,
})`
  background-color: ${({ theme }) => theme.colors.background};
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 71px;
`;

export const StyledHeader = styled(Header)`
  padding: 0 16px;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.background};

  span {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.brand.primary.main};
  }

  span + span,
  li + li > span {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.brand.secondary.pink};
  }

  aside {
    display: flex;
    flex-direction: row;
  }
`;

export const MailNotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);
  margin-left: 24px;

  @media (max-width: 400px) {
    margin: 0 10px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const NotificationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);
  margin: 0 24px;

  @media (max-width: 400px) {
    margin: 0 10px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: rgba(148, 28, 154, 0.2);
  margin-left: 24px;

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: ${({ theme }) => theme.colors.brand.gray.ultralight};

  svg {
    width: 15px;
    height: 15px;
  }
`;

export const StyledFooter = styled(Footer)`
  text-align: center;
`;

export const ChildrenContent = styled(Content)`
  margin: 24px 32px;

  @media (max-width: 500px) {
    margin: 8px 10px;
  }
`;
