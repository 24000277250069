import { message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { BenefitApi, DepartmentApi, EmployerApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { countryStates } from "src/data";
import { useEditMode } from "src/hooks";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import EmployerForm from "./EmployerForm";

const initialValues: Employer = {
  id: "0",
  fullName: "",
  taxId: "",
  companyCode: "",
  manager: "",
  digitalCertificateName: "",
};

function EmployerFormContainer(): JSX.Element {
  const [currentTab, setCurrentTab] = useState(1);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [editMode] = useEditMode();
  const [params, setParams] = useSearchParams();
  const [employer, setEmployer] = useState<Employer>(initialValues);

  const tabs = [
    {
      index: 1,
      title: i18n(editMode ? dictionary.label.edit_item : dictionary.label.new_item, {
        context: context.male,
        item: i18n(dictionary.entity.employer),
      }),
    },
  ];

  const insertDepartment = (department: string) => {
    DepartmentApi.create({ id: 0, description: department })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.department),
          }),
        });
        setDepartments([...departments, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.CREATE_DEPARTMENT_ERROR.message),
            onOk: () => insertDepartment(department),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const insertBenefit = (benefit: string) => {
    BenefitApi.create({ id: 0, name: benefit })
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.created_success, {
            context: context.male,
            item: i18n(dictionary.label.benefit),
          }),
        });
        setBenefits([...benefits, res]);
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.CREATE_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.CREATE_BENEFIT_ERROR.message),
            onOk: () => insertBenefit(benefit),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  const navigate = useNavigate();
  const onGoBack = () => navigate(-1);

  const onFinish = (employerForm: Employer): void => {
    const onCreateFinish = (employerForm: Employer): void => {
      EmployerApi.create(employerForm)
        .then((res) => {
          message.success({
            content: i18n(dictionary.prompt.created_success, {
              item: i18n(dictionary.entity.employer),
              context: context.male,
            }),
          });
          return onGoBack();
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.CREATE_EMPLOYER_ERROR.title),
              message: i18n(dictionary.error.CREATE_EMPLOYER_ERROR.message),
              onOk: () => onCreateFinish(employerForm),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    };

    const onUpdateEmployer = (employerForm: Employer): void => {
      EmployerApi.updateById(employer!.id, employerForm)
        .then((res) => {
          message.success({
            content: i18n(dictionary.prompt.updated_success, {
              item: i18n(dictionary.entity.employer),
              context: context.male,
            }),
          });
          return onGoBack();
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.UPDATE_EMPLOYER_ERROR.title),
              message: i18n(dictionary.error.UPDATE_EMPLOYER_ERROR.message),
              onOk: () => onUpdateEmployer(employerForm),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    };

    if (editMode) onUpdateEmployer(employerForm);
    else onCreateFinish(employerForm);
  };

  const { show } = useIntercom();

  async function getBenefits(): Promise<boolean> {
    return await BenefitApi.fetch()
      .then((res: Benefit[]) => {
        setBenefits(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_BENEFIT_ERROR.title),
            message: i18n(dictionary.error.GET_BENEFIT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getEmployer(): Promise<boolean> {
    return await EmployerApi.fetchById(params.get("id") || "")
      .then((res: Employer) => {
        setEmployer(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  async function getDepartments(): Promise<boolean> {
    return await DepartmentApi.fetch()
      .then((res: Department[]) => {
        setDepartments(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_DEPARTMENT_ERROR.title),
            message: i18n(dictionary.error.GET_DEPARTMENT_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmployer();
      await getBenefits();
      await getDepartments();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <EmployerForm
      onGoBack={onGoBack}
      onFinish={onFinish}
      tabs={tabs}
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      departments={departments}
      insertDepartment={insertDepartment}
      benefits={benefits}
      insertBenefit={insertBenefit}
      countryStates={countryStates}
      editMode={editMode}
      employer={employer}
    />
  );
}
export default EmployerFormContainer;
