import { Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useIntercom } from "react-use-intercom";
import { EmployerApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import Employer from "./Employer";

function EmployerContainer(): JSX.Element {
  const [employers, setEmployers] = useState<Employer[]>([]);
  const navigate = useNavigate();

  const onCreateEmployer = () => navigate("create");

  const onUpdateEmployer = (id: string) => navigate(`edit?id=${id}`);

  const onDeleteEmployer = (id: string): void => {
    Modal.warning({
      title: i18n(dictionary.prompt.delete_confirm, {
        context: context.male,
        item: i18n(dictionary.entity.employer),
      }),
      content: i18n(dictionary.prompt.action_cant_be_undone),
      okCancel: true,
      onOk: () => onFinishDeleteEmployer(id),
      okType: "danger",
      okText: i18n(dictionary.label.yes),
      cancelText: i18n(dictionary.label.no),
    });

    const onFinishDeleteEmployer = (id: string): void => {
      EmployerApi.destroy(id)
        .then((res) => {
          const updatedEmployerList: Employer[] = employers.filter((employer) => employer.id !== id);
          setEmployers(updatedEmployerList);
          message.success({
            content: i18n(dictionary.prompt.deleted_success, {
              context: context.male,
              item: i18n(dictionary.entity.employer),
            }),
          });
        })
        .catch((error) => {
          if (!(error instanceof ExpiredSessionError)) {
            GoodErrorMessage({
              title: i18n(dictionary.error.DELETE_EMPLOYER_ERROR.title),
              message: i18n(dictionary.error.DELETE_EMPLOYER_ERROR.message),
              onOk: () => onFinishDeleteEmployer(id),
              onClickLink: () => show(),
            });
          }
          return false;
        });
    };
  };

  const { show } = useIntercom();

  async function getEmployerData(): Promise<boolean> {
    return await EmployerApi.fetch()
      .then((res: Employer[]) => {
        setEmployers(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_EMPLOYER_ERROR.title),
            message: i18n(dictionary.error.GET_EMPLOYER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getEmployerData();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  return (
    <Employer
      employers={employers}
      onCreateEmployer={onCreateEmployer}
      onDeleteEmployer={onDeleteEmployer}
      onUpdateEmployer={onUpdateEmployer}
    />
  );
}

export default EmployerContainer;
