import { Flex } from "antd";
import { ReactNode } from "react";
import { light } from "src/styles/themes";

type GradientBackgroundProps = {
  children: ReactNode;
};

export default function GradientBackground({ children }: GradientBackgroundProps) {
  const purple = light.colors.backgroundLinear.purple;
  const blue = light.colors.backgroundLinear.blue;
  const green = light.colors.backgroundLinear.green;

  return (
    <Flex
      style={{
        width: "100vw",
        height: "100vh",
        background: `linear-gradient(90deg, ${green} 0%, ${blue} 50%, ${purple} 100%)`,
      }}
      align="center"
      justify="center"
      vertical
    >
      {children}
    </Flex>
  );
}
