import React, { useContext, useEffect, useRef, useState } from "react";

import { Button, Divider, Form, Row, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { UserApi } from "src/api";
import GoodErrorMessage from "src/components/Modal/GoodErrorMessage";
import { RoutingContext } from "src/contexts";
import { Storage } from "src/services";
import { ExpiredSessionError, context, dictionary, i18n } from "src/utils";
import Profile from "./Profile";
import SendingConfiguration from "./SendingConfiguration";
import Settings from "./Settings";

const initialData: User = {
  id: 0,
  name: "",
  email: "",
  rememberMeToken: false,
  finishedAdmissionAccess: false,
  configurations: {
    id: 0,
    userId: 0,
    webNotifications: false,
    appNotifications: false,
    emailNotifications: false,
  },
};

const SettingsContainer: React.FC = () => {
  const { setTitle, setSubtitle } = useContext(RoutingContext);
  setTitle("Sistema de admissões");
  setSubtitle("Configurações");

  const [user, setUser] = useState<User>(initialData);
  const { show } = useIntercom();

  const navigate = useNavigate();
  const onGoBack = () => navigate(-1);

  const onFinish = (userForm: User): void => {
    onUpdateUser(userForm);
  };

  const onUpdateUser = (userForm: User): void => {
    UserApi.updateUser(user!.id, userForm)
      .then((res) => {
        message.success({
          content: i18n(dictionary.prompt.updated_success, {
            context: context.male,
            item: i18n(dictionary.entity.user),
          }),
        });
        onGoBack();
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.UPDATE_USER_ERROR.title),
            message: i18n(dictionary.error.UPDATE_USER_ERROR.message),
            onOk: () => onUpdateUser(userForm),
            onClickLink: () => show(),
          });
        }
        return false;
      });
  };

  async function getUser(): Promise<boolean> {
    return await UserApi.fetchById(Storage.getUserId())
      .then((res: User) => {
        setUser(res);
        return true;
      })
      .catch((error) => {
        if (!(error instanceof ExpiredSessionError)) {
          GoodErrorMessage({
            title: i18n(dictionary.error.GET_USER_ERROR.title),
            message: i18n(dictionary.error.GET_USER_ERROR.message),
            onClickLink: () => show(),
            onOk: () => {
              dataFetchedRef.current = false;
              getInitData();
            },
          });
        }
        throw error;
      });
  }

  const dataFetchedRef = useRef(false);
  async function getInitData() {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    try {
      await getUser();
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getInitData();
  }, []);

  const [form] = Form.useForm();

  return (
    <Form form={form}>
      <Settings
        user={user}
        onFinish={onFinish}
        form={form}
      />
      <Divider />
      <Profile
        user={user}
        onFinish={onFinish}
        form={form}
      />
      <Divider />
      <SendingConfiguration />
      <Row style={{ marginTop: "26px" }}>
        <Form.Item
          className="profile-button"
          style={{ marginTop: "30px" }}
        >
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            {i18n(dictionary.action.update_save)}
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
export default SettingsContainer;
