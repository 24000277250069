import React from "react";
import { If, Tabs } from "src/components";
import TabAdmissionalData from "./TabAdmissionalData";
import TabDocuments from "./TabDocuments";
import TabEmployeeLogs from "./TabEmployeeLogs";
import TabNotes from "./TabNotes";
import TabPersonalData from "./TabPersonalData";

type Props = {
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  genders: Gender[];
  maritalStatuses: MaritalStatus[];
  educationLevels: EducationLevel[];
  countrysOfBirth: Country[];
  countrysOfNationality: Country[];
  races: Race[];
  disabilities: Disability[];
  workerCategories: WorkerCategory[];
  laborRegimes: LaborRegime[];
  pensionRegimes: PensionRegime[];
  paymentUnitsRemuneration: PaymentUnitRemuneration[];
  typesEvent: TypeEvent[];
  admissionTypes: AdmissionType[];
  admissionIndicators: AdmissionIndicator[];
  journeisRegime: JourneyRegime[];
  activityNatures: ActivityNature[];
  monthProfessionalCategories: MonthProfessionalCategory[];
  typesEmploymentContract: TypeEmploymentContract[];
  registrationTypes: RegistrationType[];
  typesOfWorkingDay: TypeOfWorkingDay[];
  partTimeContracts: PartTimeContract[];
  legalHypothesisTemporaryWorker: LegalHypothesisTemporaryWorker[];
  // journeyTypes: JourneyType[];

  employmentRelationships: EmploymentRelationship[];
  jobOccupationRegisters: JobOccupationRegister[];
  employers: Employer[];
  departments: Department[];
  insertBenefit(value: string, employerId: string): void;
  benefits: Benefit[];
  managers: User[];
  countryStates: CountryState[];
  userNotes: EmployeeNote[];
  showNotesModal: boolean;
  setShowNotesModal: React.Dispatch<React.SetStateAction<boolean>>;
  insertNote(note: EmployeeNote): void;
  deleteNote(noteId: number): void;
  updateNote(note: EmployeeNote): void;
  employeeLogs: ChangeHistory[];
  showLogsModal: boolean;
  setShowLogsModal: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateEmployee(values: any): void;
  onGoBack(): void;
  employee?: Employee;
  setEmployee: React.Dispatch<React.SetStateAction<Employee | undefined>>;
  insertDepartment(value: string, employerId: string): void;
  dependents: Dependent[];
  insertDependent(dependent: Dependent): void;
  updateDependent(dependent: Dependent): void;
  loading: boolean;
};

function EmployeeEdit({
  tabs,
  currentTab,
  setCurrentTab,
  genders,
  maritalStatuses,
  educationLevels,
  countrysOfBirth,
  countrysOfNationality,
  races,
  disabilities,
  workerCategories,
  laborRegimes,
  pensionRegimes,
  paymentUnitsRemuneration,
  typesEvent,
  admissionTypes,
  admissionIndicators,
  journeisRegime,
  activityNatures,
  monthProfessionalCategories,
  typesEmploymentContract,
  typesOfWorkingDay,
  registrationTypes,
  partTimeContracts,
  legalHypothesisTemporaryWorker,
  employmentRelationships,
  jobOccupationRegisters,
  employers,
  departments,
  insertBenefit,
  benefits,
  managers,
  countryStates,
  userNotes,
  showNotesModal,
  setShowNotesModal,
  insertNote,
  deleteNote,
  updateNote,
  employeeLogs,
  showLogsModal,
  setShowLogsModal,
  onUpdateEmployee,
  onGoBack,
  employee,
  setEmployee,
  insertDepartment,
  dependents,
  insertDependent,
  updateDependent,
  // journeyTypes,
  loading,
}: Props): JSX.Element {
  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <TabPersonalData
          genders={genders}
          maritalStatuses={maritalStatuses}
          educationLevels={educationLevels}
          countrysOfBirth={countrysOfBirth}
          countrysOfNationality={countrysOfNationality}
          races={races}
          disabilities={disabilities}
          onCancel={onGoBack}
          onSubmit={onUpdateEmployee}
          employee={employee}
          loading={loading}
        />
      </If>
      <If condition={currentTab === 2}>
        <TabAdmissionalData
          employmentRelationships={employmentRelationships}
          jobOccupationRegisters={jobOccupationRegisters}
          employers={employers}
          departments={departments}
          workerCategories={workerCategories}
          laborRegimes={laborRegimes}
          pensionRegimes={pensionRegimes}
          paymentUnitsRemuneration={paymentUnitsRemuneration}
          typesEvent={typesEvent}
          admissionTypes={admissionTypes}
          admissionIndicators={admissionIndicators}
          journeisRegime={journeisRegime}
          activityNatures={activityNatures}
          monthProfessionalCategories={monthProfessionalCategories}
          typesEmploymentContract={typesEmploymentContract}
          registrationTypes={registrationTypes}
          typesOfWorkingDay={typesOfWorkingDay}
          partTimeContracts={partTimeContracts}
          legalHypothesisTemporaryWorker={legalHypothesisTemporaryWorker}
          insertBenefit={insertBenefit}
          benefits={benefits}
          managers={managers}
          onCancel={onGoBack}
          onSubmit={onUpdateEmployee}
          employee={employee}
          setEmployee={setEmployee}
          insertDepartment={insertDepartment}
          loading={loading}
          // journeyTypes={journeyTypes}
        />
      </If>
      <If condition={currentTab === 3}>
        <TabDocuments
          genders={genders}
          countryStates={countryStates}
          onCancel={onGoBack}
          onSubmit={onUpdateEmployee}
          employee={employee}
          dependents={dependents}
          insertDependent={insertDependent}
          updateDependent={updateDependent}
          loading={loading}
        />
      </If>
      <If condition={currentTab === 4}>
        <TabNotes
          userNotes={userNotes}
          showNotesModal={showNotesModal}
          setShowNotesModal={setShowNotesModal}
          insertNote={insertNote}
          deleteNote={deleteNote}
          updateNote={updateNote}
        />
      </If>
      <If condition={currentTab === 5}>
        <TabEmployeeLogs
          employeeLogs={employeeLogs}
          showLogsModal={showLogsModal}
          setShowLogsModal={setShowLogsModal}
        />
      </If>
    </>
  );
}

export default EmployeeEdit;
