import { ExpiredSessionError, ResponseError } from "src/utils";
import request from "../request";

export const uploadFile = async (
  fileBuffer: Buffer,
  documentCode: string,
  employeeId: number,
): Promise<{ url: string }> => {
  try {
    const { data } = await request.post(`/document-attachments`, { fileBuffer, documentCode, employeeId });
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};

export const fetch = async (): Promise<RelDocument[]> => {
  try {
    const { data } = await request.get("/documents");
    return data;
  } catch (error) {
    if (error instanceof ExpiredSessionError) {
      throw error;
    } else {
      throw new ResponseError(error);
    }
  }
};
