import { Modal, Typography } from "antd";
import { capitalizeFirstLetter } from "src/utils";

const { Text, Link } = Typography;

interface GoodInfoMessageProps {
  title: string;
  message: string;
  verify?: string;
  onClickVerifyLink?: () => void;
}

export default function GoodInfoMessage(props: GoodInfoMessageProps) {
  return Modal.info({
    title: props.title,
    content: (
      <>
        <Text>{props.message}</Text>
        {props.onClickVerifyLink && (
          <Link onClick={() => (props.onClickVerifyLink ? props.onClickVerifyLink() : null)}>
            {" " + capitalizeFirstLetter(props.verify ?? "")}
          </Link>
        )}
      </>
    ),
  });
}
