import { Provider } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';

import store from '../stores';

const routingStore = new RouterStore();

export const stores = {
  ...store,
  routing: routingStore,
};

export default Provider;
