import { Divider as AntDivider, Space as AntSpace } from 'antd';
import styled from 'styled-components';

export const Divider = styled(AntDivider)`
  margin: 8px 0;
`;

export const Space = styled(AntSpace)`
  padding: 0 8px 4px;
  display: flex;
  flex-direction: row;

  > div:first-child {
    flex: 1;
  }
`;
