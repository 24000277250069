import activityNatureJson from "./activityNature.json";
import admissionIndicatorJson from "./admissionIndicator.json";
import admissionTypeJson from "./admissionType.json";
import contractsJson from "./contracts.json";
import countryOfBirthJson from "./countryOfBirth.json";
import countryOfNationalityJson from "./countryOfNationality.json";
import countryStatesJson from "./countryStates.json";
import disabilitiesJson from "./disabilities.json";
import educationLevelJson from "./educationLevel.json";
import employmentRelationshipCodesJson from "./employmentRelationshipCodes.json";
import employmentRelationshipDocumentsJson from "./employmentRelationshipDocuments.json";
import gendersJson from "./genders.json";
import jobOccupationsJson from "./jobOccupations.json";
import journeyRegimeJson from "./journeyRegime.json";
import laborRegimeJson from "./laborRegime.json";
import legalHypothesisTemporaryWorkerJson from "./legalHypothesisTemporaryWorker.json";
import maritalStatusesJson from "./maritalStatuses.json";
import monthProfessionalCategoryJson from "./monthProfessionalCategory.json";
import partTimeContractJson from "./partTimeContract.json";
import paymentUnitRemunerationJson from "./paymentUnitRemuneration.json";
import pensionRegimeJson from "./pensionRegime.json";
import racesJson from "./races.json";
import registrationTypeJson from "./registrationType.json";
import shiftsJson from "./shifts.json";
import typeEmploymentContractJson from "./typeEmploymentContract.json";
import typeEventJson from "./typeEvent.json";
import typeOfWorkingDayJson from "./typeOfWorkingDay.json";
import workerCategoryJson from "./workerCategory.json";

const { data: contracts } = contractsJson;
const { data: countryStates } = countryStatesJson;
const { data: employmentRelationshipDocuments } = employmentRelationshipDocumentsJson;
const { data: disabilities } = disabilitiesJson;
const { data: employmentRelationshipCodes } = employmentRelationshipCodesJson;
const { data: genders } = gendersJson;
const { data: jobOccupations } = jobOccupationsJson;
const { data: maritalStatuses } = maritalStatusesJson;
const { data: races } = racesJson;
const { data: shifts } = shiftsJson;
const { data: educationLevel } = educationLevelJson;
const { data: countryOfBirth } = countryOfBirthJson;
const { data: countryOfNationality } = countryOfNationalityJson;
const { data: workerCategory } = workerCategoryJson;
const { data: laborRegime } = laborRegimeJson;
const { data: pensionRegime } = pensionRegimeJson;
const { data: paymentUnitRemuneration } = paymentUnitRemunerationJson;
const { data: typeEvent } = typeEventJson;
const { data: admissionType } = admissionTypeJson;
const { data: admissionIndicator } = admissionIndicatorJson;
const { data: journeyRegime } = journeyRegimeJson;
const { data: activityNature } = activityNatureJson;
const { data: monthProfessionalCategory } = monthProfessionalCategoryJson;
const { data: typeEmploymentContract } = typeEmploymentContractJson;
const { data: registrationType } = registrationTypeJson;
const { data: typeOfWorkingDay } = typeOfWorkingDayJson;
const { data: partTimeContract } = partTimeContractJson;
const { data: legalHypothesisTemporaryWorker } = legalHypothesisTemporaryWorkerJson;

export {
  activityNature,
  admissionIndicator,
  admissionType,
  contracts,
  countryOfBirth,
  countryOfNationality,
  countryStates,
  disabilities,
  educationLevel,
  employmentRelationshipCodes,
  employmentRelationshipDocuments,
  genders,
  jobOccupations,
  journeyRegime,
  laborRegime,
  legalHypothesisTemporaryWorker,
  maritalStatuses,
  monthProfessionalCategory,
  partTimeContract,
  paymentUnitRemuneration,
  pensionRegime,
  races,
  registrationType,
  shifts,
  typeEmploymentContract,
  typeEvent,
  typeOfWorkingDay,
  workerCategory,
};
