import React, { useState } from "react";

import { CheckOutlined, CloseOutlined, ContainerOutlined, InfoCircleOutlined, MailOutlined } from "@ant-design/icons";
import { InputNumber, Tooltip } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DefaultOptionType } from "antd/lib/select";
import { NumberFormatBase } from "react-number-format";

import { Card, Form, FormItem, If, InputGroup, Option, Select, SelectInsert, Tabs, Text } from "src/components";
import { Input, MaskedInput, Radio, Space, Switch } from "src/modules";
import { capitalizeFirstLetter, dictionary, formatToBRL, i18n } from "src/utils";

type Props = {
  onGoBack(): void;
  onFinish(employeeForm: Employee): void;
  tabs: TabsData[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  employmentRelationships: EmploymentRelationship[];
  jobOccupationRegisters: JobOccupationRegister[];
  employers: Employer[];
  departments: Department[];
  insertDepartment(value: string, employerId: string): void;
  benefits: Benefit[];
  insertBenefit(value: string, employerId: string): void;
  admissionFlows: AdmissionFlow[];
};

function EmployeeCreate({
  onGoBack,
  onFinish,
  tabs,
  currentTab,
  setCurrentTab,
  employmentRelationships,
  jobOccupationRegisters,
  employers,
  departments,
  insertDepartment,
  benefits,
  insertBenefit,
  admissionFlows,
}: Props): JSX.Element {
  const [action, setAction] = useState();
  const [employerId, setEmployerId] = useState("");

  const [admissionFlowId, setAdmissionFlowId] = useState<string | null>(null);

  const [form] = useForm();

  const filteredBenefits = employerId
    ? benefits.filter(
        (benefit) => benefit.employers?.find((employerBenefit) => employerBenefit.employerId == employerId),
      )
    : [];

  const filteredDepartments = employerId
    ? departments.filter(
        (department) => department.employers?.find((employerDepartment) => employerDepartment.employerId == employerId),
      )
    : [];

  return (
    <>
      <Tabs
        data={tabs}
        setCurrentTab={setCurrentTab}
      />
      <If condition={currentTab === 1}>
        <Form
          onSubmit={() => onFinish(form.getFieldsValue())}
          onCancel={onGoBack}
          submitText={i18n(dictionary.action.create_item, {
            item: i18n(dictionary.entity.employee),
          })}
          form={form}
        >
          <Card
            icon={<ContainerOutlined />}
            title={i18n(dictionary.label.register_data)}
          >
            <FormItem
              label={i18n(dictionary.label.full_name)}
              name="name"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={capitalizeFirstLetter(i18n(dictionary.entity.employment_relationship))}
              name="employmentRelationshipId"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Select>
                {employmentRelationships.map((relationship) => (
                  <Option
                    key={relationship.id}
                    title={relationship.description}
                    value={relationship.id}
                  >
                    {relationship.description}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.job_occupation_register)}
              name="cboIdentifier"
              rules={[{ required: false }]}
            >
              <Select
                showSearch
                onSelect={(_: any, option: DefaultOptionType) =>
                  form.setFieldsValue({
                    jobOccupation: String(option.key),
                  })
                }
              >
                {jobOccupationRegisters.map((job) => (
                  <Option
                    key={job.name}
                    title={`${job.code} – ${job.name}`}
                    value={job.code}
                  >
                    {job.code} – {job.name}
                  </Option>
                ))}
              </Select>
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.occupation)}
              name="jobOccupation"
              rules={[{ required: true }]}
            >
              <Input />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.email)}
              name="email"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <Input
                type="email"
                addonBefore={<MailOutlined />}
              />
            </FormItem>

            <FormItem label={i18n(dictionary.label.contact_phone_number)}>
              <InputGroup>
                <FormItem
                  name="telNumber"
                  rules={[{ required: false }]}
                >
                  <MaskedInput mask="(00) 00000-0000" />
                </FormItem>

                <FormItem
                  name="isWhatsapp"
                  rules={[{ required: false }]}
                  initialValue={false}
                >
                  <InputGroup>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      onChange={(checked: boolean) => form.setFieldsValue({ isWhatsapp: checked })}
                    />
                    <Text variant="small">{i18n(dictionary.label.this_number_is_whatsapp)}</Text>
                  </InputGroup>
                </FormItem>
              </InputGroup>
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.salary)}
              name="salary"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
            >
              <NumberFormatBase
                customInput={Input}
                defaultValue="000"
                format={formatToBRL}
                // onValueChange={(values, sourceInfo) => {
                //   form.setFieldValue("salary", (values.floatValue ?? 0) / 100);
                // }}
              />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.registry)}
              name="registration"
              rules={[{ required: false }]}
            >
              <Input />
            </FormItem>

            <FormItem>
              <InputGroup>
                <FormItem
                  label={capitalizeFirstLetter(i18n(dictionary.entity.employer))}
                  name="employerId"
                  rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                >
                  <Select
                    onChange={(value: string) => {
                      setEmployerId(value);
                      form.setFieldValue("benefits", []);
                      form.setFieldValue("departmentId", undefined);
                    }}
                  >
                    {employers.map((employer) => (
                      <Option
                        key={employer.id}
                        title={employer.shortName ?? employer.fullName}
                        value={employer.id}
                      >
                        {employer.shortName ?? employer.fullName}
                      </Option>
                    ))}
                  </Select>
                </FormItem>

                {employerId && (
                  <FormItem
                    label={capitalizeFirstLetter(i18n(dictionary.label.benefit, { count: 2 }))}
                    name="benefits"
                    rules={[{ required: false }]}
                  >
                    <SelectInsert
                      mode="multiple"
                      onInsert={(value) => insertBenefit(value, employerId)}
                      // insertInputPlaceholder={i18n(dictionary.label.benefit)}
                    >
                      {filteredBenefits.map((benefit) => (
                        <Option
                          key={benefit.id}
                          title={benefit.name}
                          value={benefit.id}
                        >
                          {benefit.name}
                        </Option>
                      ))}
                    </SelectInsert>
                  </FormItem>
                )}
              </InputGroup>
            </FormItem>

            {/* <FormItem
              label={i18n(dictionary.label.manager)}
              name="manager"
              rules={[{ required: false }]}
            >
              <Select>
                {employers.map((employer) => (
                  <Option
                    key={employer.id}
                    title={employer.tradingName}
                    value={employer.id}
                  >
                    {employer.tradingName}
                  </Option>
                ))}
              </Select>
            </FormItem> */}

            <FormItem>
              <InputGroup>
                {/* <FormItem
                  label={i18n(dictionary.label.admission_date)}
                  name="admissionDate"
                  rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
                >
                  <DatePicker format={[i18n(dictionary.dateFormat)]} />
                </FormItem> */}

                <FormItem
                  label={i18n(dictionary.label.workload)}
                  name="workload"
                  rules={[{ required: false }]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    addonAfter={
                      <FormItem
                        name="workloadType"
                        noStyle
                      >
                        <Select defaultValue={1}>
                          <Option
                            key="1"
                            value={1}
                          >
                            {i18n(dictionary.label.hours_per_week)}
                          </Option>
                          <Option
                            key="2"
                            value={2}
                          >
                            {i18n(dictionary.label.hours_per_month)}
                          </Option>
                        </Select>
                      </FormItem>
                    }
                  />
                </FormItem>
              </InputGroup>
            </FormItem>

            <FormItem>
              <InputGroup>
                <FormItem
                  label={i18n(dictionary.label.experience_period)}
                  name="workExperiencePeriodInDays"
                  rules={[{ required: false }]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    addonAfter={i18n(dictionary.label.days)}
                  />
                </FormItem>

                {/* <FormItem
                  label={i18n(dictionary.label.experience_period_extension)}
                  name="experiencePeriodExtension"
                  rules={[{ required: false }]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    addonAfter={i18n(dictionary.label.days)}
                  />
                </FormItem> */}
              </InputGroup>
            </FormItem>

            {employerId && (
              <FormItem
                label={i18n(dictionary.label.department)}
                name="departmentId"
                rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
              >
                <SelectInsert onInsert={(value) => insertDepartment(value, employerId)}>
                  {filteredDepartments.map((department) => (
                    <Option
                      key={department.id}
                      title={department.description}
                      value={department.id}
                    >
                      {department.description}
                    </Option>
                  ))}
                </SelectInsert>
              </FormItem>
            )}

            <FormItem
              label={
                <>
                  {i18n(dictionary.label.admission_flow)}
                  &nbsp;
                  <Tooltip title={i18n(dictionary.label.admission_flow_tooltip)}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              name="admissionFlowId"
            >
              <Select
                allowClear
                onClear={() => setAdmissionFlowId(null)}
                onSelect={(option) => {
                  setAdmissionFlowId(option);
                  form.setFieldValue("requestDocsFromHired", false);
                }}
                options={admissionFlows.map((element) => ({
                  value: element.id,
                  label: element.name,
                }))}
              />
            </FormItem>

            <FormItem
              label={i18n(dictionary.label.employee_documents)}
              name="requestDocsFromHired"
              rules={[{ required: true, message: i18n(dictionary.message.requiredField) }]}
              hidden={!!admissionFlowId}
            >
              <Radio.Group onChange={({ target }) => setAction(target.value)}>
                <Space direction="vertical">
                  <Radio value={true}>{i18n(dictionary.label.request_documents_to_employee)}</Radio>
                  <Radio value={false}>{i18n(dictionary.label.add_documents_manually)}</Radio>
                </Space>
              </Radio.Group>
            </FormItem>
            {action === 1 && (
              <FormItem
                name="admissionDeadlineDays"
                label={i18n(dictionary.label.due_date)}
                initialValue={15}
                hidden={!!admissionFlowId}
              >
                <InputNumber
                  min={0}
                  addonAfter={i18n(dictionary.label.days)}
                />
              </FormItem>
            )}
          </Card>
        </Form>
      </If>
    </>
  );
}

export default EmployeeCreate;
