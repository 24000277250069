import { FileTextOutlined } from "@ant-design/icons";
import { FormInstance, Input } from "antd";
import { useEffect } from "react";

type CustomCnpjInput = {
  cnpj: string;
  form: FormInstance<any>;

  setCnpj: (cnpj: string) => void;
  placeholder: string;
  disabled?: boolean | undefined;
  withPreffix?: boolean | undefined;
};

export default function CustomCnpjInput({
  cnpj,
  form,
  setCnpj,
  placeholder,
  disabled = false,
  withPreffix = false,
}: CustomCnpjInput) {
  useEffect(() => {
    if (cnpj) {
      let formated = formatCnpj(cnpj);
      setCnpj(formated);
      form.setFieldsValue({ taxId: formated });
    }
  }, [cnpj]);

  function formatCnpj(value: string) {
    // Remove caracteres não numéricos
    value = value.replace(/\D/g, "");
    // Adiciona o primeiro ponto
    value = value.replace(/^(\d{2})(\d)/, "$1.$2");
    // Adiciona o segundo ponto
    value = value.replace(/^(\d{2}\.\d{3})(\d)/, "$1.$2");
    // Adiciona a barra
    value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
    // Adiciona o hífen
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
    // Limita o tamanho da string a 18 caracteres
    value = value.slice(0, 18);

    return value;
  }

  return (
    <Input
      placeholder={placeholder}
      value={cnpj}
      onChange={({ target }) => {
        setCnpj(target.value);
      }}
      disabled={disabled}
      prefix={withPreffix && <FileTextOutlined style={{ color: "rgba(0, 0, 0, 0.45)", marginRight: 4 }} />}
    />
  );
}
