import React, { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router';
import { Content, Item, Wrapper } from './styles';
import { ArrowLeftOutlined } from 'src/assets/icons';

type Props = {
  data: TabsData[];
  setCurrentTab: Dispatch<SetStateAction<number>>;
};

const Tabs: React.FC<Props> = ({ data, setCurrentTab }) => {
  // navigation
  const navigate = useNavigate();

  const onGoBack = () => navigate(-1);

  const [activeIndex, setActiveIndex] = useState<number>(1);

  const onChangeTab = (index: number) => {
    setActiveIndex(index);
    setCurrentTab(index);
  };

  return (
    <Wrapper>
      <ArrowLeftOutlined onClick={onGoBack} />

      <Content>
        {data.map((item) => (
          <Item
            key={`tab-${item.index}`}
            onClick={() => onChangeTab(item.index)}
            active={item.index === activeIndex}
          >
            <p>{item.title}</p>
          </Item>
        ))}
      </Content>
    </Wrapper>
  );
};

export default Tabs;
