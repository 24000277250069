import { CloseOutlined, NotificationOutlined, SettingOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Flex, Popover, Typography } from "antd";
import React, { memo, useEffect, useState } from "react";
import { MobileView, isBrowser } from "react-device-detect";
import { useNavigate } from "react-router";
import { NewApi } from "src/api";
import { LogoBlack } from "src/assets/images";
import { MenuItem } from "src/pages/_layouts/default";
import {
  Logo,
  NewsWrapper,
  NotificationsWrapper,
  SettingsWrapper,
  StyledHeader,
} from "src/pages/_layouts/default/styles";
import { Routes } from "src/routes";
import StorageService from "src/services/storage/storage.service";
import { dictionary, i18n } from "src/utils";
import { NotificationsList } from "../NotificationsList";
import { AccountDropdown, NavbarSearch } from "../navbar";
import SystemSelector from "../navbar/SystemSelector";
import Gradient from "./Gradient";
import { AnimatedContainer, AnimatedLeftNav, AnimatedRightNav, Wrapper } from "./styles";

const { Text } = Typography;

type Props = {
  hasGradient?: boolean;
  hasLogo?: boolean;
  title?: string;
  subtitle?: string;
  tourRef?: any;
  menuItems?: MenuItem[];
  onClickLogo?: () => void;
};

const notifications: any[] = [];

const Header: React.FC<Props> = ({
  hasGradient = false,
  hasLogo = false,
  title = "",
  subtitle = "",
  tourRef,
  menuItems,
  onClickLogo,
}) => {
  const [isWideScreen, setIsWideScreen] = useState(window.matchMedia("(min-width: 600px)").matches);

  const [openPopover, setOpenPopover] = useState(true);
  const [news, setNews] = useState<any[]>([]);
  const [showNewsDot, setShowNewsDot] = useState(false);

  const navigate = useNavigate();

  const onBack = () => navigate(-1);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setIsWideScreen(windowWidth >= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    NewApi.fetch(1, 4).then((data) => setNews(data));
  }, []);

  useEffect(() => {
    if (news.length) {
      const latestNew = StorageService.getLatestNew();
      if (!latestNew) setShowNewsDot(true);
      const userId = StorageService.getUserId();
      setShowNewsDot(`${userId}_${news[0].id}` !== latestNew);
    }
  }, [news]);

  return (
    <StyledHeader>
      <MobileView>
        {hasLogo && (
          <Logo
            className="logo"
            onClick={() => {
              if (onClickLogo) onClickLogo();
            }}
          >
            <img
              src={LogoBlack}
              alt="Logo"
              style={{ width: "100%", height: "58%" }}
            />
          </Logo>
        )}
      </MobileView>
      <SystemSelector />
      <aside>
        <AnimatedContainer>
          <Wrapper>
            <AnimatedLeftNav>
              {/* {isBrowser && ( */}
              <>
                {isBrowser && <NavbarSearch menuItems={menuItems} />}
                {/* <MailNotificationsWrapper ref={tourRef}>
                    <MailOutlined />
                  </MailNotificationsWrapper> */}
                <Popover
                  title={
                    <Flex
                      justify="space-between"
                      align="center"
                    >
                      <span>{i18n(dictionary.label.news)}!</span>
                      <Button
                        type="text"
                        size="small"
                        icon={<CloseOutlined />}
                        onClick={() => setOpenPopover(false)}
                      />
                    </Flex>
                  }
                  open={openPopover && showNewsDot}
                  content={
                    <div style={{ maxWidth: 250 }}>
                      <Divider style={{ margin: "8px 0" }} />
                      <Flex vertical>
                        {news.map((element) => (
                          <Text>⚬ {element.titulo}</Text>
                        ))}
                      </Flex>
                    </div>
                  }
                >
                  <Badge
                    dot={showNewsDot}
                    offset={[-3, 3]}
                  >
                    <NewsWrapper
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowNewsDot(false);
                        navigate(Routes.NEWS);
                      }}
                    >
                      <NotificationOutlined />
                    </NewsWrapper>
                  </Badge>
                </Popover>
                <NotificationsWrapper>
                  <NotificationsList notifications={notifications} />
                </NotificationsWrapper>
                <SettingsWrapper>
                  <SettingOutlined onClick={() => navigate(Routes.SETTINGS)} />
                </SettingsWrapper>
              </>
              {/* )} */}
              {/* <MobileView>
                {hasLogo ? (
                  <img
                    src={LogoDefault}
                    alt="Logo"
                    width="100px"
                  />
                ) : (
                  <button
                    type="button"
                    onClick={onBack}
                  >
                    <FiArrowLeft />
                    <p>{i18n(dictionary.return)}</p>
                  </button>
                )}
              </MobileView> */}
            </AnimatedLeftNav>
            <AnimatedRightNav>
              <AccountDropdown />
            </AnimatedRightNav>
          </Wrapper>
          {hasGradient && <Gradient />}
        </AnimatedContainer>
      </aside>
    </StyledHeader>
  );
};

export default memo(Header);
